import { Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { Skeleton } from '@components/base/Skeleton'
import type { ITableTitleProps } from './types.ts'

export const TableTitle: FunctionComponent<ITableTitleProps> = (props) => {
    const { title, children, loading } = props ?? {}
    if (title || children) {
        return (
            <div className='flex flex-col space-y-3 mb-3 items-start justify-center'>
                {loading ? title && <Skeleton width={128} height={32} /> : title && <Text type='header3'>{title}</Text>}
                {loading ? children && <Skeleton width={384} height={18} /> : children}
            </div>
        )
    } else {
        return <></>
    }
}

import { StyleContext, Text } from '@demia/ui-kit'
import type { FunctionComponent, ReactElement, ReactPortal } from 'react'
import { useContext } from 'react'
import { ProfileMenu } from '@components/app/Profile'
import { Footer, Header } from '@components/base'
import classes from '@components/base/Layout/Layout.module.scss'
import { ProjectSidebar } from './ProjectSidebar'

type ReactText = string | number
type ReactChild = ReactElement | ReactText

interface ReactNodeArray extends Array<ReactNode> {}
type ReactFragment = {} | ReactNodeArray
type ReactNode = ReactChild | ReactFragment | ReactPortal | boolean | null | undefined

interface ILayoutProps {
    children: React.ReactNode
    title?: string
    TitleComponent?: FunctionComponent
}

export const ProjectLayout: FunctionComponent<ILayoutProps> = (props) => {
    const { children, title, TitleComponent } = props
    const theme = useContext(StyleContext).data.theme
    const isDark = theme === 'dark'

    return (
        <main className={classes['main']}>
            <aside className={`${classes['sidebar-container']} ${isDark ? classes['sidebar-container-dark'] : ''}`}>
                <ProjectSidebar />
            </aside>
            <div className={`${classes['main__content']} ${isDark ? classes['main__content-dark'] : ''}`}>
                <Header MenuComponent={ProfileMenu} />
                <div className={`${classes['main__content-children']}`}>
                    {(title || TitleComponent) && (
                        <div className='flex flex-row items-center justify-between mb-8'>
                            {title && !TitleComponent && <Text type='header2'>{title}</Text>}
                            {TitleComponent && <TitleComponent />}
                        </div>
                    )}
                    {children}
                </div>
                <Footer />
                <div className={`${classes['gradient1']} ${isDark ? classes['gradient1-dark'] : ''}`}></div>
                <div className={`${classes['gradient2']} ${isDark ? classes['gradient2-dark'] : ''}`}></div>
            </div>
        </main>
    )
}

import { ErrorHandler, MS_PER_SECOND, sleep, isObjectEmpty } from '@demia/core'
import type { IContext } from '@demia/platform'
import type { FunctionComponent, ReactNode } from 'react'
import { useContext, useEffect, useState } from 'react'
import { StorageManager } from '@constants'
import type { IProjectDetails } from '@lib/project'
import { ProjectApiService } from '@lib/project'
import type { IUser, IUserContextData, IUserMetadata, IUserSettings } from '@lib/user'
import { logoutUser, UserApiService, UserContext } from '@lib/user'
import { AuthContext } from './contexts'

const INITIAL_OVERVIEW_POLL_TIMEOUT_MS = 5 * MS_PER_SECOND
const OVERVIEW_POLL_INTERVAL_MS = 30 * MS_PER_SECOND

interface IUserProviderProps {
    children: ReactNode
}

export const UserProvider: FunctionComponent<IUserProviderProps> = (props) => {
    const { children } = props
    const { hasSession, auth } = useContext(AuthContext)

    const persistedUserStore = StorageManager.load('userData')
    const persistedUserData = persistedUserStore?.[auth?.['id'] ?? '']
    const persistedUserSites: IProjectDetails[] = Object.values(persistedUserData?.metadata?.sites ?? {}).map(
        ({ sdk_site }) => sdk_site
    ) as IProjectDetails[]
    const [userData, setUserData] = useState<IUserContextData>({
        user: auth as IUser,
        metadata: persistedUserData?.metadata as IUserMetadata,
        settings: persistedUserData?.settings as IUserSettings,
        sites: persistedUserSites ?? [],
        hasIdentity: true,
    })
    const userContext: IContext<IUserContextData> = {
        data: userData,
        setData: onUserDataUpdate,
    }

    function onUserDataUpdate(payload: Partial<IUserContextData>): void {
        setUserData({
            ...userData,
            ...payload,
        })
    }

    async function loadMetadata(): Promise<void> {
        const [data, error] = await UserApiService.getMetadata()
        if (error) {
            ErrorHandler.handleApiError(error, { notifyUser: false })
        } else {
            const userId = auth?.['id']
            if (userId) {
                StorageManager.save('userData', {
                    ...persistedUserStore,
                    [userId]: {
                        ...(userData ?? {}),
                        metadata: {
                            ...data,
                            notifications: [],
                        } as IUserMetadata,
                    },
                })
            }
        }
    }

    let pollingInterval: number | null = null
    async function loadOverview(): Promise<void> {
        while (hasSession) {
            const [data, error] = await ProjectApiService.getProjects()
            if (error) {
                ErrorHandler.handleApiError(error, { notifyUser: false })
                await sleep(INITIAL_OVERVIEW_POLL_TIMEOUT_MS)
            } else {
                if (data && 'sites' in data) {
                    userContext.data.sites = data.sites
                    setUserData({
                        ...userData,
                        sites: data.sites,
                    })
                    break
                } else {
                    await sleep(INITIAL_OVERVIEW_POLL_TIMEOUT_MS)
                }
            }
        }
    }

    async function updateOverview(): Promise<void> {
        const [data, error] = await ProjectApiService.getProjects()
        if (error) {
            if (error.message.includes('No identity stored in user')) {
                userContext.data.hasIdentity = false
                setUserData({
                    ...userData,
                    hasIdentity: false,
                })
            } else {
                if (pollingInterval) {
                    clearInterval(pollingInterval)
                }
                await logoutUser()
                window.location.assign(window?.location?.origin ?? '')
            }
        } else {
            if (data && 'sites' in data) {
                if (userContext.data.sites !== data.sites) {
                    userContext.data.sites = data.sites
                    userContext.data.hasIdentity = true
                    const _userData = {
                        ...userData,
                        sites: data.sites,
                        hasIdentity: true,
                    }
                    setUserData(_userData)
                }
            } else {
                if (isObjectEmpty(userData.user) && pollingInterval) {
                    clearInterval(pollingInterval)
                }

                await logoutUser()
                window.location.assign(window?.location?.origin ?? '')
            }
        }
    }

    useEffect(() => {
        if (hasSession && !pollingInterval) {
            void loadMetadata()
            loadOverview().then(() => {
                if (userData.user) {
                    pollingInterval = setInterval(() => {
                        void updateOverview()
                    }, OVERVIEW_POLL_INTERVAL_MS) as unknown as number
                }
            })
        } else {
            if (pollingInterval) {
                clearInterval(pollingInterval)
            }
        }

        return () => {
            pollingInterval && clearInterval(pollingInterval)
        }
    }, [hasSession])

    return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
}

import { Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { ProgressBar } from '@components/base'
import { Link } from '@components/base/Link'
import type { ITableRowProps } from '@components/base/Table'
import { TableRow } from '@components/base/Table'
import type { ISensorSource } from '@lib/sensor'
import { SiteDataSourceMenu } from './SiteDataSourceMenu.tsx'

export const SiteDataSourcesTableRow: FunctionComponent<ITableRowProps<ISensorSource>> = (props) => {
    const { url, source, inputType, dataConfidence, dataType, dataEntry, recordCount, timestamp } = props?.data ?? {}
    const cells = [
        <Link url={url ?? ''}>{source}</Link>,
        <Text type='body1'>{inputType}</Text>,
        <ProgressBar progress={dataConfidence ?? 0} />,
        <Text type='body1'>{dataType}</Text>,
        <Text type='body1'>{dataEntry}</Text>,
        <Text type='body1'>{recordCount}</Text>,
        <Text type='body1'>{timestamp}</Text>,
    ]

    return (
        <TableRow id={props.id} MenuTemplate={() => SiteDataSourceMenu({ dataSource: props?.data as ISensorSource })}>
            {cells.map((cell) => cell)}
        </TableRow>
    )
}

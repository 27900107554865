import type { SortDirection } from '@demia/core'
import { sortPrimitives } from '@demia/core'
import type { FunctionComponent } from 'react'
import type { ITableField } from '@components/base/Table'
import { Table } from '@components/base/Table'
import type { IIdentityMethod } from '@lib/identity'
import { IdentityTableRow } from './IdentityTableRow.tsx'

interface IIdentityTableProps {
    loading: boolean
    methods: IIdentityMethod[]
    hiddenColumns?: string[]
}

const IDENTITY_TABLE_FIELDS: ITableField[] = [
    {
        text: 'ID',
        key: 'id',
        sortingFunction: (a: IIdentityMethod, b: IIdentityMethod, direction: SortDirection) =>
            sortPrimitives(a.id, b.id, direction),
        sortByDefault: true,
    },
    {
        text: 'Fragment',
        key: 'fragment',
        sortingFunction: (a: IIdentityMethod, b: IIdentityMethod, direction: SortDirection) =>
            sortPrimitives(a.fragment, b.fragment, direction),
        sortByDefault: true,
    },
    {
        text: 'Type',
        key: 'type',
        sortingFunction: (a: IIdentityMethod, b: IIdentityMethod, direction: SortDirection) =>
            sortPrimitives(a.type_, b.type_, direction),
    },
    {
        text: 'Controller',
        key: 'controller',
        sortingFunction: (a: IIdentityMethod, b: IIdentityMethod, direction: SortDirection) =>
            sortPrimitives(a.controller, b.controller, direction),
    },
]

export const IdentityTable: FunctionComponent<IIdentityTableProps> = (props) => {
    const { loading, methods, hiddenColumns = [] } = props
    const fields = IDENTITY_TABLE_FIELDS.filter((f) => !hiddenColumns.includes(f.key))
    return (
        <Table
            fields={fields}
            data={methods ?? []}
            RowTemplate={(p) => IdentityTableRow({ ...p, id: methods.length.toString() })}
            loading={loading}
            rowTemplateProps={{ hiddenColumns }}
        />
    )
}

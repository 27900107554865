import type { IconName, Style } from '@demia/ui-kit'
import { Icon, StyleContext } from '@demia/ui-kit'
import type { FunctionComponent, MouseEvent, ReactNode, RefObject } from 'react'
import { useContext } from 'react'
import classes from './Button.module.scss'

interface ButtonProps {
    children: ReactNode
    onClick: (e?: MouseEvent<HTMLButtonElement>) => void
    buttonRef?: RefObject<HTMLButtonElement>
    variant?: ButtonVariant
    type?: ButtonType
    size?: ButtonSize
    width?: ButtonWidth
    disabled?: boolean
    cursorDisabled?: boolean
    busy?: boolean
    busyText?: string
    icon?: IconName
    reverse?: boolean
}

const buttonVariants = ['primary', 'outline', 'text', 'alt'] as const
type ButtonVariant = (typeof buttonVariants)[number]

const buttonTypes = ['button', 'submit', 'reset'] as const
type ButtonType = (typeof buttonTypes)[number]

const buttonSizes = ['large', 'medium', 'small'] as const
type ButtonSize = (typeof buttonSizes)[number]

const buttonWidths = ['fit', 'full', '1/2', '3/4'] as const
type ButtonWidth = (typeof buttonWidths)[number]

const PADDING_SIZE_MAP: Record<ButtonSize, [number, number]> = {
    large: [16, 12],
    medium: [12, 8],
    small: [12, 8],
}

const FONT_SIZE_MAP: Record<ButtonSize, number> = {
    large: 18,
    medium: 16,
    small: 14,
}

const LINE_HEIGHT_SIZE_MAP: Record<ButtonSize, number> = {
    large: 24,
    medium: 20,
    small: 18,
}

const WIDTH_MAP: Record<ButtonWidth, string> = {
    fit: 'fit-content',
    full: '100%',
    '1/2': '50%',
    '3/4': '75%',
}

const BACKGROUND_COLOR_MAP: Record<ButtonVariant, string> = {
    primary:
        'bg-[#ff00ab] dark:bg-[#0dce95] hover:bg-[#cc008a] dark:hover:bg-[#11a983] active:bg-[#990069] dark:active:bg-[#0a6953] disabled:bg-[#ffbfe6] dark:disabled:bg-[#bfffe2]',
    outline:
        'bg-surface-0 dark:bg-surface-0-dark hover:bg-[#f4f6f8] dark:hover:bg-[#221d2d] active:bg-[#f4f6f8] dark:active:bg-[#221d2d] disabled:bg-[#ffffff]/50 dark:disabled:bg-[#191321]/50',
    text: 'bg-[#ffffff]/0 hover:bg-[#ffbfe6] dark:hover:bg-[#bfffe2] active:bg-[#ff99d9] dark:active:bg-[#99ffcc] disabled:bg-[#ffffff]/0',
    alt: 'bg-[#0dce95] dark:bg-[#ff00ab] hover:bg-[#11a983] dark:hover:bg-[#cc008a] active:bg-[#0a6953] dark:active:bg-[#990069] disabled:bg-[#bfffe2]/50 dark:disabled:bg-[#ffbfe6]/50',
}

const TEXT_COLOR_MAP: Record<ButtonVariant, string> = {
    primary: 'text-[#ffffff] hover:text-[#ffffff] active:text-[#ffffff] disabled:text-[#ffffff]',
    outline:
        'text-[#151221] dark:text-text-invert hover:text-[#151221] dark:hover:text-text-invert active:text-[#151221] dark:active:text-text-invert disabled:text-[#151221]/50 dark:disabled:text-text-invert/50',
    text: 'text-[#151221] dark:text-[#ffffff] hover:text-[#151221] dark:hover:text-[#151221] active:text-[#151221] dark:active:text-[#151221] disabled:text-[#ff00ab]/30 dark:disabled:text-[#0dce95]/30',
    alt: 'text-[#151221] dark:text-[#ffffff] hover:text-[#151221] dark:hover:text-[#ffffff] active:text-[#151221] dark:active:text-[#ffffff] disabled:text-[#ffffff] dark:disabled:text-[#151221]',
}

const ICON_COLOR_MAP: Record<ButtonVariant, string> = {
    primary: 'text-invert',
    outline: 'text-primary',
    text: 'text-primary',
    alt: 'text-primary',
}

const BORDER_COLOR_MAP: Record<ButtonVariant, string> = {
    primary:
        'border-[#ff00ab] dark:border-[#0dce95] hover:border-[#cc008a] dark:hover:border-[#11a983] active:border-[#990069] dark:active:border-[#0a6953] disabled:border-[#ffbfe6]',
    outline:
        'border-[var(--stroke)] dark:border-[var(--stroke-dark)] hover:border-[var(--stroke)] dark:hover:border-[var(--stroke-dark)] active:border-[#f4f6f8] dark:active:border-[#221d2d] disabled:border-[var(--stroke)]/50 dark:disabled:border-[var(--stroke-dark)]/50',
    text: 'border-[#ffffff]/0 hover:border-[#ffbfe6] dark:hover:border-[#bfffe2] active:border-[#ff99d9] dark:active:border-[#99ffcc] disabled:border-[#ffffff]/0',
    alt: 'border-[#0dce95] dark:border-[#ff00ab] hover:border-[#11a983] dark:hover:border-[#cc008a] active:border-[#0a6953] dark:active:border-[#990069] disabled:border-[#bfffe2]/50 dark:disabled:border-[#ffbfe6]/50',
}

/**
 * Allows users to execute some arbitrary functionality, e.g. adding or saving a document.
 *
 * @param props
 */
export const Button: FunctionComponent<ButtonProps> = (props) => {
    const {
        children,
        onClick,
        buttonRef,
        variant = 'primary',
        type,
        size = 'large',
        width,
        disabled,
        cursorDisabled,
        busy = false,
        busyText = '',
        icon,
        reverse,
    } = props
    const padding = PADDING_SIZE_MAP[size]
    const style: Style = {
        padding: `${padding[1]}px ${padding[0]}px`,
        fontFamily: 'var(--font)',
        fontSize: `${FONT_SIZE_MAP[size]}px`,
        fontWeight: '600',
        lineHeight: `${LINE_HEIGHT_SIZE_MAP[size]}px`,
        width: WIDTH_MAP[width ?? 'fit'],
    }
    const isDark = useContext(StyleContext).data.theme === 'dark'

    const svgClass = `svg-${variant}`

    return (
        <button
            type={type ?? 'button'}
            ref={buttonRef}
            className={`
                ${BACKGROUND_COLOR_MAP[variant]}
                ${TEXT_COLOR_MAP[variant]}
                ${BORDER_COLOR_MAP[variant]}
                ${classes[svgClass]}
                border-[1px] border-solid
                ${disabled || cursorDisabled ? 'cursor-default pointer-events-none' : ''}
                transition-colors duration-300
                flex flex-row ${reverse ? 'flex-row-reverse' : ''} items-center justify-center gap-2
                outline-none
            `}
            style={{ ...style }}
            onClick={onClick}
            disabled={disabled || busy}
        >
            {icon && <Icon name={icon} color={`${ICON_COLOR_MAP[variant]}${isDark ? '-dark' : ''}`}></Icon>}
            {busy ? (busyText ?? '') : (children ?? '')}
        </button>
    )
}

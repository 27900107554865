import { Icon, type IconName, StyleContext, Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useContext } from 'react'

export interface IActionMenuItemProps {
    title: string
    onClick: () => void
    variant?: MenuItemVariant
    icon?: IconName
}

const menuItemVariants = ['primary', 'danger'] as const
export type MenuItemVariant = (typeof menuItemVariants)[number]

const MENU_ITEM_VARIANT_BACKGROUND_COLOR_MAP: Record<MenuItemVariant, string> = {
    primary:
        'bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] hover:bg-[#d9e1e8]/[.3] dark:hover:bg-[#d9e1e8]/[.05]',
    danger: 'bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] hover:bg-[#ff3232]/[.1] dark:hover:bg-[#ff3232]/[.1]',
}

const MENU_ITEM_VARIANT_TEXT_COLOR_MAP: Record<MenuItemVariant, string> = {
    primary: 'text-primary',
    danger: 'text-danger',
}

export const ActionMenuItem: FunctionComponent<IActionMenuItemProps> = (props) => {
    const { title, onClick, variant = 'primary', icon } = props
    const isDark = useContext(StyleContext).data.theme === 'dark'

    return (
        <div
            onClick={onClick}
            className={`flex flex-row space-x-3 items-center w-full pl-4 pr-6 py-2 cursor-pointer whitespace-nowrap transition-colors duration-300 ${MENU_ITEM_VARIANT_BACKGROUND_COLOR_MAP[variant]}`}
        >
            {icon && (
                <Icon
                    name={icon}
                    color={`${MENU_ITEM_VARIANT_TEXT_COLOR_MAP[variant]}${isDark && variant === 'primary' ? '-dark' : ''}`}
                />
            )}
            <Text type='body1' justification='center' color={MENU_ITEM_VARIANT_TEXT_COLOR_MAP[variant]}>
                {title}
            </Text>
        </div>
    )
}

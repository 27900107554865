import { useAuth0 } from '@auth0/auth0-react'
import { ErrorHandler, HttpClient } from '@demia/core'
import { useEffect } from 'react'
import { AppProvider } from '@components/app'
import { Loading, NotificationProvider, PopupProvider } from '@components/base'
import { config } from '@constants'
import { RouterProvider } from '@pages/Router'

/**
 * NOTE: This import statement executes the required
 * initialization for the Sentry SDK. This module also
 * exports the reporter which is used by the error handler.
 */
import { sentryReporter } from './sentry'

export const App = () => {
    const { isLoading, error } = useAuth0()

    useEffect(() => {
        ErrorHandler.attachReporter(sentryReporter)
        HttpClient.setBaseUrl(config.API_BASE_URL)
    }, [])

    if (isLoading) {
        return <Loading />
    }

    if (error) {
        ErrorHandler.handleApplicationError(error, { notifyUser: false })
    }

    return (
        <AppProvider>
            <NotificationProvider>
                <PopupProvider>
                    <RouterProvider />
                </PopupProvider>
            </NotificationProvider>
        </AppProvider>
    )
}

import type { Result, IHttpClient, IListFilesContext } from '@demia/core'
import { HttpClient, strFromPascalToCamelCase } from '@demia/core'
import type { IDataSourceEquipment, IUpdateEquipmentDto } from '@lib/sensor'
import type {
    IListAssetsContext,
    INewProjectRequest,
    IProjectApiCache,
    IProjectApiService,
    IProjectDetails,
    IProjectNotification,
    IProjectSites,
    IProjectSlugAccordion,
    IUpdateSiteDto,
} from './project.types'

class ProjectApiServiceImpl implements IProjectApiService {
    private static instance: ProjectApiServiceImpl
    private readonly http: IHttpClient

    private constructor(http: IHttpClient) {
        this.http = http
    }

    static getInstance(http: IHttpClient): IProjectApiService {
        if (!ProjectApiServiceImpl.instance) {
            ProjectApiServiceImpl.instance = new ProjectApiServiceImpl(http)
        }
        return ProjectApiServiceImpl.instance
    }

    getProject(siteId: string): Promise<Result<IProjectDetails>> {
        return this.http.get<IProjectDetails>(`/api/context/${siteId}`)
    }

    async getProjects(): Promise<Result<IProjectSites>> {
        const [data, error] = await this.http.get<IProjectSites>('/api/context/overview')
        if (error) {
            return [null, error]
        } else {
            /**
             * CAUTION: The calculation IDs for every analytics profile (if present) MUST be converted
             * into the correct casing convention (in this case camelCase) so they match the value sets
             * provided each project's details.
             */
            if ('sites' in data) {
                data.sites = data.sites.map((site: IProjectDetails) => {
                    if (site.profiles && site.profiles.length > 0) {
                        site.profiles = site.profiles.map((profile) => {
                            profile.calculations = profile.calculations.map((calculation) => {
                                calculation.id = strFromPascalToCamelCase(calculation.id)
                                return calculation
                            })
                            return profile
                        })
                        return site
                    } else {
                        return site
                    }
                })
            }

            return [data, null]
        }
    }

    getNotifications(): Promise<Result<{ notifications: IProjectNotification[] }>> {
        return this.http.get<{ notifications: IProjectNotification[] }>('/api/context/notifications')
    }

    addSite(project: INewProjectRequest): Promise<Result<void>> {
        return this.http.post<void>('/api/context/attach', project)
    }

    updateSite(siteId: string, sitePayload: IUpdateSiteDto): Promise<Result<unknown>> {
        return this.http.put<unknown>(`/api/context/${siteId}`, sitePayload)
    }

    removeSite(siteId: string): Promise<Result<void>> {
        return this.http.delete<void>(`/api/context/${siteId}`)
    }

    addSensor(siteId: string, sensor: IDataSourceEquipment): Promise<Result<void>> {
        return this.http.post<void>(`/api/context/${siteId}/sensor`, sensor)
    }

    updateSensor(siteId: string, sensorId: string, payload: IUpdateEquipmentDto): Promise<Result<void>> {
        return this.http.put<void>(`/api/context/${siteId}/sensors/${sensorId}`, payload)
    }

    uploadFile(siteId: string, files: File[], isInheritableAsset: boolean = false): Promise<Result<IListFilesContext>> {
        const formData = new FormData()
        ;[...files].forEach((file) => {
            formData.append('files', file)
        })
        const params = new URLSearchParams({
            upload_type: isInheritableAsset ? 'asset' : 'document',
        })

        return this.http.post<IListFilesContext>(`/api/context/${siteId}/upload`, formData, params)
    }

    downloadFile(siteId: string, filename: string): Promise<Result<Blob>> {
        return this.http.get<Blob>(`/api/context/${siteId}/files/${filename}`)
    }

    deleteFile(siteId: string, filename: string): Promise<Result<void>> {
        return this.http.delete<void>(`/api/context/${siteId}/files/${filename}`)
    }

    listFiles(siteId: string): Promise<Result<IListFilesContext>> {
        return this.http.get<IListFilesContext>(`/api/context/${siteId}/files`)
    }

    listAssets(siteId: string): Promise<Result<IListAssetsContext>> {
        return this.http.get<IListAssetsContext>(`/api/context/${siteId}/assets`)
    }

    getFilesMetadata(siteId: string): Promise<Result<IListFilesContext>> {
        return this.http.get<IListFilesContext>(`/api/context/${siteId}/files/metadata`)
    }
}

export const ProjectApiService = ProjectApiServiceImpl.getInstance(HttpClient)

class ProjectApiCacheImpl implements IProjectApiCache {
    private static instance: ProjectApiCacheImpl

    private readonly projectStorageKey: string = 'projects'

    static getInstance(): IProjectApiCache {
        if (!ProjectApiCacheImpl.instance) {
            ProjectApiCacheImpl.instance = new ProjectApiCacheImpl()
        }
        return ProjectApiCacheImpl.instance
    }

    private set(name: string, value: object) {
        if (typeof Storage !== 'undefined') {
            sessionStorage.setItem(name, JSON.stringify(value))
        }
    }

    setProjects(projects: IProjectSlugAccordion[]): void {
        this.set(this.projectStorageKey, projects)
    }

    getProjects(): IProjectSlugAccordion[] {
        if (typeof Storage !== 'undefined') {
            return JSON.parse(sessionStorage.getItem(this.projectStorageKey) || '[]') as IProjectSlugAccordion[]
        } else {
            return []
        }
    }

    clearProjects(): void {
        if (typeof Storage !== 'undefined') {
            sessionStorage.removeItem(this.projectStorageKey)
        }
    }
}

export const ProjectApiCache = ProjectApiCacheImpl.getInstance()

import { isObjectEmpty } from '@demia/core'
import type { IAuthUser, IStripeSubscription } from '@demia/platform'
import { Copyable, Text } from '@demia/ui-kit'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import type { FunctionComponent } from 'react'
import { useMemo } from 'react'
import { PropagateLoader } from 'react-spinners'
import { CheckoutForm, SubscriptionInfo } from '@components/app'
import { Pane, Skeleton } from '@components/base'
import { config } from '@constants'
import type { IProjectSites } from '@lib/project'
import classes from '@pages/Profile/ProfilePage.module.scss'

interface ISubscriptionTabProps {
    auth?: IAuthUser
    sites?: IProjectSites
    customerId?: string
    subscriptionMap?: Record<string, IStripeSubscription>
}

export const SubscriptionTab: FunctionComponent<ISubscriptionTabProps> = (props) => {
    const { auth, sites, customerId, subscriptionMap } = props

    const loadStripePromise = useMemo(() => loadStripe(config.STRIPE_API_KEY), [])
    const hasNoSubscriptions = useMemo(() => isObjectEmpty(subscriptionMap), [subscriptionMap])

    return (
        <Pane>
            <div className='flex flex-col space-y-6'>
                <div className='flex flex-col space-y-2'>
                    <Text type='header3'>Customer Information</Text>
                    <div className='flex flex-col space-y-4'>
                        <div>
                            <Text type='body1' color='text-secondary'>
                                Customer ID
                            </Text>
                            {auth?.stripeCustomerId ? <Copyable value={auth?.stripeCustomerId ?? ''} /> : <Skeleton />}
                        </div>
                        <div>
                            <Text type='body1' color='text-secondary'>
                                Customer Subscription
                            </Text>
                            {auth?.subscription ? <Copyable value={auth?.subscription ?? ''} /> : <Skeleton />}
                        </div>
                    </div>
                </div>
                <div className='flex flex-col space-y-2'>
                    <Text type='header3'>Subscription Details</Text>
                    <div className='flex flex-col space-y-4'>
                        <div>
                            {sites && customerId && subscriptionMap ? (
                                hasNoSubscriptions ? (
                                    <div>
                                        <Text type='body1' color='text-secondary'>
                                            There are no subscriptions
                                        </Text>
                                    </div>
                                ) : (
                                    sites.sites.map((site, siteIndex) => {
                                        const element =
                                            Object.keys(subscriptionMap).length !== 0 &&
                                            subscriptionMap[site.projectId] ? (
                                                <SubscriptionInfo
                                                    customerId={customerId}
                                                    site={site}
                                                    subscription={subscriptionMap[site.projectId]}
                                                />
                                            ) : (
                                                <Elements stripe={loadStripePromise}>
                                                    <CheckoutForm customerId={customerId} site={site} />
                                                </Elements>
                                            )
                                        const containerClass = customerId ? classes.projectInfo : classes.loadingBar
                                        return (
                                            <div className={containerClass} key={siteIndex}>
                                                {element}
                                            </div>
                                        )
                                    })
                                )
                            ) : (
                                <div className='relative px-8 pt-4 pb-8'>
                                    <div className='absolute left-[96px] top-1/2'>
                                        <PropagateLoader color='#36d7b7' loading={true} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Pane>
    )
}

import type { Theme } from '@demia/ui-kit'
import { toggleHtmlRootDarkMode, StyleContext } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SearchInput } from '@components/base/Inputs'
import { IconButton } from '../Button'
import classes from './Header.module.scss'

interface IHeaderProps {
    MenuComponent?: FunctionComponent
}

export const Header: FunctionComponent<IHeaderProps> = (props) => {
    const { MenuComponent } = props
    const { data, setData } = useContext(StyleContext)
    const navigate = useNavigate()

    const { slug } = useParams()

    function onThemeClick(theme: Theme): void {
        toggleHtmlRootDarkMode()
        setData({
            ...data,
            theme: theme,
        })
    }

    function onNotificationsClick(): void {
        const url = `/projects/${slug}/activity`
        navigate(url)
    }

    return (
        <header
            className={`${classes.header} ${data.theme === 'dark' && classes.headerDark} flex flex-row items-center justify-end px-8 py-4 border-b-[1px] border-[var(--stroke)] dark:border-[var(--stroke-dark)]`}
        >
            <div className='flex flex-row space-x-4 items-center justify-between'>
                {data.theme === 'light' && <IconButton icon='sun' onClick={() => onThemeClick('dark')} />}
                {data.theme === 'dark' && <IconButton icon='moon' onClick={() => onThemeClick('light')} />}
                {Boolean(slug) && <IconButton icon='bell' onClick={onNotificationsClick} />}
                <SearchInput onChange={() => null}></SearchInput>
            </div>
            {MenuComponent && (
                <div className='ml-6'>
                    <MenuComponent />
                </div>
            )}
        </header>
    )
}

import { dateToReadable } from '@demia/core'
import { BrandedIcon, EMPTY_FIELD_TEXT, type IconName, StyleContext, Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { flowmeter, statusConnected, statusDisconnected } from '@assets'
import type { EquipmentPath } from '@components/app'
import {
    ProjectLayout,
    ProjectSensorDataTable,
    ProjectDataSourceImage,
    PUBLIC_PROJECT_ASSET_PATH,
    sortNestedReadings,
    DEFAULT_EQUIPMENT_PATH,
} from '@components/app'
import { BarRangeChart, Pane, Skeleton, Tabs } from '@components/base'
import type { IChartData } from '@components/base/Charts/types.ts'
import type { ISensorContextData, ISensorData } from '@lib/sensor'
import { getSensorDataFromDashboardContext, transformReadings } from '@lib/sensor'
import { ProjectDataSourceTitle } from '../ProjectDataSourceTitle'
import classes from './Flowmeter.module.scss'

const flowDataEmpty = {
    image: flowmeter,
    active: true,
    infoData: [
        {
            title: 'Sensor ID',
            value: '',
        },
        {
            title: 'Accuracy',
            value: '',
        },
        {
            title: 'Manufacturer',
            value: '',
        },
        {
            title: 'Installed on',
            value: '',
        },
        {
            title: 'Model',
            value: '',
        },
        {
            title: 'Last Calibration',
            value: '',
        },
        {
            title: 'Serial Number',
            value: '',
        },
        {
            title: 'Next Calibration',
            value: '',
        },
    ],
}

const flowInfo = {
    source: 'Flowmeter 1',
    lastUpdate: '27 Oct 2023 - 6:45 PM',
    unit: '0.05 Nm3',
}

const flowmeterDataEmpty: Array<{
    icon: IconName
    text: string
    value: number
    valueUnit: string
}> = [
    {
        icon: 'cargo',
        text: 'Current load',
        value: 0,
        valueUnit: '',
    },
    {
        icon: 'clock-clockwise',
        text: 'Daily average load',
        value: 0,
        valueUnit: '',
    },
    {
        icon: 'chart-bar',
        text: 'Data confidence',
        value: 0,
        valueUnit: '%',
    },
]

export const FlowmeterDataSource: FunctionComponent<{ sensorContext?: ISensorContextData }> = (props) => {
    const { slug } = useParams()
    const url = `/projects/${slug}/data-sources`
    const isDark = useContext(StyleContext).data.theme === 'dark'

    const { sensorContext } = props
    const backupUri: EquipmentPath = `${DEFAULT_EQUIPMENT_PATH}${sensorContext?.id}`
    const imageUri = `${PUBLIC_PROJECT_ASSET_PATH}${sensorContext?.assetUrl || backupUri}`

    const location = useLocation()
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [location])

    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

    const [flowmeterData, setFlowmeterData] = useState<
        Array<{
            icon: IconName
            text: string
            value: number
            valueUnit: string
        }>
    >(
        flowmeterDataEmpty.map((a) => {
            return { ...a }
        })
    )

    const [flowData, setFlowData] = useState(
        flowDataEmpty.infoData.map((a) => {
            return { ...a }
        })
    )

    const [sensorDataList, setSensorDataList] = useState<ISensorData[]>([])
    const [graphData, setGraphData] = useState<Array<{ equipment: string; data: IChartData[] }>>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (sensorContext) {
            const sorted = transformReadings(sensorContext.readings)
            const newGraphData = sortNestedReadings(sorted, sensorContext.equipment.name)
            setGraphData(newGraphData)

            const readings = Array.from(Object.values(sensorContext.readings)).map((v) => {
                return getSensorDataFromDashboardContext(v, sensorContext.equipment.units, sensorContext.annotations)
            })

            setSensorDataList(readings)
            setLoading(false)
        }
    }, [sensorContext])

    useEffect(() => {
        if (sensorContext) {
            const numbers = [...flowmeterData]
            numbers[0].value = Math.round(sensorContext.state.realTimeFlow * 100000) / 100000
            // TEMP
            numbers[0].valueUnit = 'm3/hour' /*props.sensor.sensor.equipment.units*/
            numbers[1].value = Math.round(sensorContext.state.currentDayAvg * 100000) / 100000
            // TEMP
            numbers[1].valueUnit = 'm3/hour' /*props.sensor.sensor.equipment.units*/
            numbers[2].value = Math.round(sensorContext.avgcf * 10000) / 10000
            setFlowmeterData(numbers)

            const newData = [...flowData]
            newData[0].value = sensorContext.equipment.id
            newData[1].value = sensorContext.equipment.accuracy + ''
            newData[2].value = sensorContext.equipment.manufacturer
            newData[3].value = sensorContext.equipment.installed + ''
            newData[4].value = sensorContext.equipment.eqType
            // Last calibration
            newData[5].value = 'Never'
            newData[6].value = sensorContext.equipment.serialNo
            // Next calibration
            newData[7].value = 'Soon'
            setFlowData(newData)
        }
    }, [sensorContext])

    const TitleComponent = () => {
        return <ProjectDataSourceTitle title={sensorContext?.equipment?.name ?? ''} url={url} />
    }

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <Tabs tabHeaders={['Overview', 'Logs']}>
                <article className={classes.flowmeterWrapper}>
                    <div className={`${classes.flowmeterInfo} ${isDark && classes.flowmeterInfoDark}`}>
                        <figure className={classes.image}>
                            <ProjectDataSourceImage
                                uri={imageUri}
                                alt='Flow Image'
                                defaultImage={flowDataEmpty.image}
                            />
                        </figure>
                        <div className={`${classes.rightSide} ${isDark && classes.rightSideDark}`}>
                            <img
                                src={flowDataEmpty.active ? statusConnected : statusDisconnected}
                                className={classes.status}
                                alt='Status'
                            />
                            <div className='grid grid-cols-2 gap-x-6 gap-y-4'>
                                {flowData.every((data) => data.value !== undefined)
                                    ? flowData.map((data, index) => (
                                          <div className='' key={index}>
                                              <Text type='body1' color='text-secondary'>
                                                  {data.title}
                                              </Text>
                                              <Text type='body1'>{data.value || EMPTY_FIELD_TEXT}</Text>
                                          </div>
                                      ))
                                    : flowData.map((data, index) => (
                                          <div className='' key={index}>
                                              <Text type='body1' color='text-secondary'>
                                                  {data.title}
                                              </Text>
                                              <Skeleton width={96} />
                                          </div>
                                      ))}
                            </div>
                        </div>
                        <div className='flex flex-row space-x-4'>
                            {flowmeterData.map((data, index) => (
                                <div
                                    className='w-1/3 bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] border-[1px] border-[var(--stroke-light)] dark:border-[var(--stroke-light-dark)] flex flex-col items-center justify-start p-6 space-y-4'
                                    key={index}
                                >
                                    <BrandedIcon name={data.icon} />
                                    <Text type='body1' color='text-secondary'>
                                        {data.text}
                                    </Text>
                                    <div className='h-full flex flex-col justify-around'>
                                        <Text type='header2' justification='center'>
                                            {data.value}{' '}
                                            <span className='text-text-secondary dark:text-text-secondary-dark'>
                                                {data.valueUnit}
                                            </span>
                                        </Text>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={`${classes.bottom} ${isDark && classes.bottomDark}`}>
                            <h4>Biogas Flow</h4>
                            <div className={classes.info}>
                                <p>
                                    Source: <span>{flowInfo.source}</span>
                                </p>
                                <p>
                                    Last update:{' '}
                                    <span>
                                        {sensorContext ? dateToReadable(new Date(sensorContext.lastUpdated)) : 'never'}
                                    </span>
                                </p>
                                <p>
                                    Unit: <span>{flowInfo.unit}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <Pane>
                        {graphData.length === 1 ? (
                            <div className='p-2'>
                                <BarRangeChart
                                    title={{ title: graphData[0].equipment }}
                                    data={graphData[0].data}
                                    loading={!flowData}
                                />
                            </div>
                        ) : (
                            Object.entries(graphData).map((v) => {
                                const [_, nested] = v
                                return (
                                    <div className='p-2'>
                                        <BarRangeChart
                                            title={{ title: nested.equipment }}
                                            data={nested.data}
                                            loading={!flowData}
                                        />
                                    </div>
                                )
                            })
                        )}
                    </Pane>
                </article>
                <ProjectSensorDataTable loading={loading} sensorDataList={sensorDataList} />
            </Tabs>
        </ProjectLayout>
    )
}

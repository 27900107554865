import type { FunctionComponent } from 'react'
import type { ITableFooterProps } from '../types.ts'
import { TableFooterPagination } from './TableFooterPagination.tsx'
import { TableFooterResults } from './TableFooterResults.tsx'

export const TableFooter: FunctionComponent<ITableFooterProps> = (props) => {
    const { results, colSpan, onPreviousPageClick, onNextPageClick, onGoToPageClick } = props
    const { length, limit, page } = results
    return (
        <tfoot className='w-full bg-transparent'>
            <tr className='table-row'>
                <td colSpan={colSpan} className='table-cell p-0 pt-12'>
                    <div className='flex flex-row items-center justify-between'>
                        <TableFooterResults length={length ?? 0} limit={limit ?? 20} page={page ?? 0} />
                        <TableFooterPagination
                            length={length ?? 0}
                            limit={limit ?? 20}
                            currentPage={page ?? 1}
                            onPreviousPageClick={onPreviousPageClick}
                            onNextPageClick={onNextPageClick}
                            onGoToPageClick={onGoToPageClick}
                        />
                    </div>
                </td>
            </tr>
        </tfoot>
    )
}

import { getColorHex, StyleContext } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useContext } from 'react'
import { default as _Skeleton } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

interface ISkeletonProps {
    width?: number | string
    height?: number | string
    count?: number
}

export const Skeleton: FunctionComponent<ISkeletonProps> = (props) => {
    const { width, height, count = 1 } = props
    const isDark = useContext(StyleContext).data.theme === 'dark'
    const baseColorToken = `surface-${isDark ? '0-dark' : '1'}`
    const baseColor = getColorHex(baseColorToken) ?? '#ffffff'
    const highlightColorToken = `surface-${isDark ? '1-dark/50' : '0'}`
    const highlightColor = getColorHex(highlightColorToken) ?? '#ffffff'

    return (
        <_Skeleton width={width} height={height} count={count} baseColor={baseColor} highlightColor={highlightColor} />
    )
}

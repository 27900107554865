import type { FunctionComponent } from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { EditSiteDetailsPopup } from '@components/app/Project/popups/EditSiteDetailsPopup.tsx'
import { ActionMenu, type IActionMenuItemProps, usePopup } from '@components/base'
import type { IProjectDetails, IUpdateSiteDto } from '@lib/project'
import { AppRoute, buildRoute } from '@lib/routing'
import { UserContext } from '@lib/user'
import { RemoveSitePopup } from './popups/RemoveSitePopup'

interface IProjectMenuProps {
    site: IProjectDetails
    navigateOnRemoveSite?: boolean
}

export const ProjectMenu: FunctionComponent<IProjectMenuProps> = (props) => {
    const { site, navigateOnRemoveSite } = props
    const { data, setData } = useContext(UserContext)
    const { openPopup, closePopup } = usePopup()
    const navigate = useNavigate()

    function onSiteUpdated(payload: IUpdateSiteDto): void {
        setData({
            ...data,
            sites: data.sites
                .map((_site) =>
                    _site.projectId === site.projectId
                        ? {
                              ..._site,
                              projectName: payload.name,
                              projectInfo: {
                                  ..._site.projectInfo,
                                  projectDev: payload.developer,
                                  type: payload.type,
                              },
                          }
                        : _site
                )
                .sort((a, b) => (a.projectName > b.projectName ? 1 : -1)),
        })
    }

    function onSiteRemoved(): void {
        setData({
            ...data,
            sites: data.sites.filter((_site) => _site.projectId !== site.projectId),
        })
        if (navigateOnRemoveSite) {
            navigate(buildRoute(AppRoute.Overview))
        }
    }

    const projectDetailsMenuItems: IActionMenuItemProps[] = [
        {
            title: 'Edit site',
            onClick: () =>
                openPopup({
                    content: <EditSiteDetailsPopup site={site} onUpdate={onSiteUpdated} onClose={closePopup} />,
                }),
            icon: 'pencil',
        },
        {
            title: 'Remove site',
            onClick: () =>
                openPopup({
                    content: <RemoveSitePopup site={site} onRemove={onSiteRemoved} onClose={closePopup} />,
                }),
            icon: 'trash',
            variant: 'danger',
        },
    ]

    return <ActionMenu items={projectDetailsMenuItems} />
}

import { StyleContext } from '@demia/ui-kit'
import type { FunctionComponent, ReactNode } from 'react'
import { useContext } from 'react'
import { Logo } from '@components/base/Logo'

interface ISidebarProps {
    children: ReactNode
    onLogoClick: () => void
    MenuComponent?: FunctionComponent
}

export const Sidebar: FunctionComponent<ISidebarProps> = (props) => {
    const { children, onLogoClick, MenuComponent } = props
    const theme = useContext(StyleContext).data.theme
    const isDark = theme === 'dark'

    return (
        <aside
            className={`sticky top-0 left-0 bottom-0 w-auto z-[1] inline-block min-h-[100vh] min-w-[244px] backdrop-blur ${isDark ? 'bg-gradient-sidebar-dark' : 'bg-gradient-sidebar'}`}
        >
            <div className='flex flex-col'>
                <div className='px-8 pt-4 pb-8 cursor-pointer'>
                    <a onClick={onLogoClick}>
                        <Logo />
                    </a>
                </div>
                {MenuComponent && <MenuComponent />}
            </div>
            {children}
        </aside>
    )
}

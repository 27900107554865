import type { IChartData } from '@components/base/Charts/types.ts'
import type { IReadingDashboardContext } from '@lib/sensor'
import { getNestedReadingValue } from '@lib/sensor'

export function sortNestedReadings(
    nestedReadings: { [key: string]: { [subKey: string]: IReadingDashboardContext }[] },
    contextEquipment: string
): Array<{ equipment: string; data: IChartData[] }> {
    return Object.keys(nestedReadings).length === 1 && Object.keys(nestedReadings)[0] === '_'
        ? [
              {
                  equipment: contextEquipment,
                  data: nestedReadings['_']
                      .map((v) => {
                          return Object.values(v).map((data) => {
                              const [valueType, value] = getNestedReadingValue(data.value)

                              return {
                                  name: new Date(data.timestamp),
                                  uv: valueType === 'Float' || valueType === 'Int' ? value : 0,
                                  pv: valueType === 'Float' || valueType === 'Int' ? value : 0,
                                  amt: valueType === 'Float' || valueType === 'Int' ? value : 0,
                              }
                          })
                      })
                      .flat(),
              },
          ]
        : Object.entries(nestedReadings)
              .map((v) => {
                  const [equipment, nested] = v
                  let unit = nested[0][Object.keys(nested[0])[0]].unit
                  unit = unit ? unit.replace('[', '').replace(']', '') : ''
                  const data = Object.values(nested)
                      .map((n) => {
                          return Object.values(n).map((data) => {
                              const [valueType, value] = getNestedReadingValue(data.value)

                              return {
                                  name: new Date(data.timestamp),
                                  uv: valueType === 'Float' || valueType === 'Int' ? value : 0,
                                  pv: valueType === 'Float' || valueType === 'Int' ? value : 0,
                                  amt: valueType === 'Float' || valueType === 'Int' ? value : 0,
                                  unit: data.unit,
                              }
                          })
                      })
                      .flat()

                  return {
                      equipment: equipment === '_' ? contextEquipment : equipment,
                      unit,
                      data,
                  }
              })
              .flat()
}

import type { ChangeEvent, FormEvent, MouseEvent } from 'react'
import { useMemo, useState } from 'react'
import { Button } from '@components/base/Button'
import { DropdownInput, NumberInput, PasswordInput, TextInput } from '@components/base/Inputs'
import type { IFormField } from './types'

interface IFormProps<T> {
    fields: IFormField[]
    onSubmit: (payload: T) => void | Promise<void>
    primaryButtonText?: string
    primaryButtonBusyText?: string
    secondaryButtonText?: string
    onPrimaryButtonClick?: (event?: MouseEvent<HTMLButtonElement>) => void
    onSecondaryButtonClick?: (event?: MouseEvent<HTMLButtonElement>) => void
    willOverflow?: boolean
}

export const Form = <T extends object>(props: IFormProps<T>) => {
    const {
        fields,
        onSubmit,
        primaryButtonText,
        primaryButtonBusyText,
        secondaryButtonText,
        onPrimaryButtonClick = () => {},
        onSecondaryButtonClick = () => {},
        willOverflow = false,
    } = props

    const [values, setValues] = useState<(string | number)[]>(fields.map((field) => field.defaultValue ?? ''))
    const [errors, setErrors] = useState<string[]>(fields.map((_) => ''))

    const [isSubmitting, setIsSubmitting] = useState(false)

    const canSubmit = useMemo<boolean>(
        () => values.every((value, valueIdx) => (fields[valueIdx].required ? Boolean(value) : true)),
        [values]
    )

    function onChange(event: ChangeEvent<HTMLInputElement>, fieldIdx: number): void {
        setValues((prev) => {
            prev[fieldIdx] = event.target.value
            return [...prev]
        })
    }

    function updateDropdownValue(value: string, fieldIdx: number): void {
        setValues((prev) => {
            prev[fieldIdx] = value
            return [...prev]
        })
    }

    async function onSubmitWrapper(event: FormEvent<HTMLFormElement>): Promise<void> {
        setIsSubmitting(true)
        event.preventDefault()
        if (areFieldsValid()) {
            await Promise.resolve(
                onSubmit(Object.fromEntries(fields.map((field, fieldIdx) => [field.property, values[fieldIdx]])) as T)
            )
        }
        setIsSubmitting(false)
    }

    function areFieldsValid(): boolean {
        let hadError = false

        fields.forEach((field, fieldIdx) => {
            const value =
                field.id ??
                (document.getElementById(field.id!) as HTMLInputElement)?.value ??
                (document.getElementById(field.id!) as HTMLElement)?.textContent ??
                ''
            if (value && field.validate) {
                const [_, error] = field.validate(value)
                if (error) {
                    hadError = true
                    setErrors(errors.map((_error, errorIdx) => (fieldIdx === errorIdx ? error.message : _error)))
                }
            } else {
                if (field.required!) {
                    hadError = true
                }
            }
        })

        return !hadError
    }

    return (
        <form onSubmit={onSubmitWrapper} className={`flex flex-col space-y-4 mb-4 pb-1 overflow-auto`}>
            <div className={`overflow-auto space-y-4 mb-4 pb-1 ${willOverflow ? 'pr-2' : ''}`}>
                {fields.map((field, fieldIdx) => {
                    switch (field.type) {
                        case 'number':
                            return (
                                <NumberInput
                                    {...field}
                                    defaultValue={values[fieldIdx]}
                                    error={errors[fieldIdx]}
                                    onChange={(e) => onChange(e, fieldIdx)}
                                    required={field.required}
                                />
                            )
                        case 'password':
                            return (
                                <PasswordInput
                                    {...field}
                                    defaultValue={values[fieldIdx]}
                                    error={errors[fieldIdx]}
                                    onChange={(e) => onChange(e, fieldIdx)}
                                    required={field.required}
                                />
                            )
                        case 'dropdown':
                            return (
                                <DropdownInput
                                    {...field}
                                    defaultValue={values[fieldIdx]}
                                    items={field.dropdownItems ?? []}
                                    updateValue={(value) => updateDropdownValue(value, fieldIdx)}
                                />
                            )
                        case 'text':
                        default:
                            return (
                                <TextInput
                                    {...field}
                                    defaultValue={values[fieldIdx]}
                                    error={errors[fieldIdx]}
                                    onChange={(e) => onChange(e, fieldIdx)}
                                    required={field.required}
                                />
                            )
                    }
                })}
            </div>
            {primaryButtonText && (
                <div className='flex flex-row items-center justify-between space-x-4'>
                    {secondaryButtonText && (
                        <Button
                            variant='text'
                            width='1/2'
                            size='medium'
                            onClick={onSecondaryButtonClick}
                            disabled={isSubmitting}
                        >
                            {secondaryButtonText}
                        </Button>
                    )}
                    <Button
                        type='submit'
                        variant='primary'
                        width={secondaryButtonText ? '1/2' : 'full'}
                        size='medium'
                        onClick={onPrimaryButtonClick}
                        busy={isSubmitting}
                        busyText={primaryButtonBusyText}
                        disabled={!canSubmit}
                    >
                        {primaryButtonText}
                    </Button>
                </div>
            )}
        </form>
    )
}

import type { Result } from '@demia/core'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { Skeleton } from '@components/base'

interface IProjectImagePropsType {
    uri: string
    defaultImage: any
    alt: string
}

export const ProjectDataSourceImage: FunctionComponent<IProjectImagePropsType> = (props) => {
    const { uri, defaultImage, alt } = props
    const [validUri, setValidUri] = useState('')
    const [loading, setLoading] = useState<boolean>(true)

    async function validateUrl(url: string): Promise<Result<string>> {
        const [baseUrl, path] = url.split('.com/')
        const encodedPath = encodeURIComponent(path).replace(/%2F/g, '/')
        const encodedUrl = `${baseUrl}.com/${encodedPath}`

        try {
            /**
             * NOTE: This code is commented out because accessing S3 buckets
             * directly results in CORS issues when using the frontend API.
             */
            // const isOk = (await fetch(encodedUrl, { method: 'HEAD' })).ok
            // if (isOk) {
            //     return [encodedUrl, null]
            // } else {
            //     return [null, new Error('Unable to fetch image')]
            // }
            return [encodedUrl, null]
        } catch (err) {
            return [null, new Error('Unable to fetch image')]
        }
    }

    useEffect(() => {
        validateUrl(uri).then(([validUri, _]) => {
            setLoading(false)
            if (validUri) {
                setValidUri(validUri)
            } else {
                console.error(_)
            }
        })
    }, [])

    return (
        <div className='h-full max-h-[50vh] flex items-center justify-center relative'>
            {loading ? (
                <Skeleton width='100%' height='100%' />
            ) : (
                <img src={validUri || defaultImage} alt={alt} className='max-h-full object-contain' />
            )}
        </div>
    )
}

import type { SortDirection } from '@demia/core'
import { sortPrimitives } from '@demia/core'
import type { FunctionComponent } from 'react'
import type { ITableField } from '@components/base/Table'
import { Table } from '@components/base/Table'
import type { IUser } from '@lib/user'
import { ProjectUsersTableRow } from './ProjectUsersTableRow.tsx'

interface IProjectUsersTableProps {
    loading: boolean
    users: IUser[]
}

const PROJECT_USERS_TABLE_FIELDS: ITableField[] = [
    {
        text: 'Name',
        key: 'name',
        sortingFunction: (a: IUser, b: IUser, direction: SortDirection) => sortPrimitives(a.name, b.name, direction),
        sortByDefault: true,
    },
    {
        text: 'Role',
        key: 'role',
        sortingFunction: (a: IUser, b: IUser, direction: SortDirection) => sortPrimitives(a.role, b.role, direction),
    },
    {
        text: 'Last Login',
        key: 'lastLogin',
        sortingFunction: (a: IUser, b: IUser, direction: SortDirection) =>
            sortPrimitives(a.lastLogin, b.lastLogin, direction),
    },
    {
        text: 'Permissions',
        key: 'permissions',
        sortingFunction: (a: IUser, b: IUser, direction: SortDirection) =>
            sortPrimitives(a.permissions.length, b.permissions.length, direction),
    },
]

export const ProjectUsersTable: FunctionComponent<IProjectUsersTableProps> = (props) => {
    const { loading, users } = props
    return (
        <Table
            fields={PROJECT_USERS_TABLE_FIELDS}
            data={users}
            RowTemplate={(_props) => ProjectUsersTableRow({ ..._props, id: props.users.length.toString() })}
            loading={loading}
        />
    )
}

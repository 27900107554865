import { truncateString } from '@demia/core'
import type { IconName } from '@demia/ui-kit'
import { Icon, Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import type { INavigationMenuItemProps } from '@components/base'
import { NavigationMenu } from '@components/base/Menu'
import { ProjectApiCache } from '@lib/project'
import { AppRoute, buildRoute } from '@lib/routing'
import { UserContext } from '@lib/user'

export const SiteSidebarNavigationMenu: FunctionComponent = () => {
    const { data } = useContext(UserContext)
    const cachedProjects = ProjectApiCache.getProjects()

    const [isLoading, setLoading] = useState<boolean>(cachedProjects.length == 0)
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [activeProjectName, setActiveProjectName] = useState<string>('')
    const [activeProjectId, setActiveProjectId] = useState<string>('')

    const { slug } = useParams()
    const [projects, setProjects] = useState(cachedProjects)

    useEffect(() => {
        const activeProject = projects.find((project) => project.slug === slug)
        if (activeProject) {
            setActiveProjectName(activeProject.projectName)
            setActiveProjectId(activeProject.projectId)
        }
    }, [data, projects])

    useEffect(() => {
        if (data) {
            const mapped =
                data.sites
                    .map((site) => {
                        return {
                            projectName: site.projectName,
                            slug: `${site.projectId}`,
                            projectId: site.projectId,
                        }
                    })
                    .sort((a, b) => (a.projectName > b.projectName ? 1 : -1)) || []
            setLoading(false)
            setProjects(mapped)
            ProjectApiCache.setProjects(mapped)
        }
    }, [data])

    const menuItemGroups: INavigationMenuItemProps[][] = [
        [
            ...projects.map((project) => ({
                url: buildRoute(AppRoute.Project).replace(':slug', project.projectId?.toString()),
                text: project.projectName,
                icon: 'map-pin' as IconName,
                isActive: activeProjectId === project.projectId,
                onClick: () => {
                    setShowMenu(false)
                    setActiveProjectId(project.projectId)
                    setActiveProjectName(project.projectName)
                },
            })),
        ],
        [
            {
                url: buildRoute(AppRoute.Overview),
                text: 'Overview',
                icon: 'list',
                onClick: () => setShowMenu(false),
            },
        ],
    ]

    const AnchorComponent: FunctionComponent = () => {
        return (
            <div className='flex flex-row items-center justify-between px-8 py-4 cursor-pointer'>
                <div className='flex flex-col items-start justify-start'>
                    <Text type='body2' color='text-brand-primary' freezeColor>
                        PROJECTS
                    </Text>
                    <Text type='subtitle2' color='text-primary'>
                        {truncateString(activeProjectName, 20)}
                    </Text>
                </div>
                <div
                    className={`transform transition-all duration-200 ease-in-out rotate-0 ${showMenu && 'rotate-180'}`}
                >
                    <Icon name='caret-up' color='text-secondary' />
                </div>
            </div>
        )
    }

    return (
        <NavigationMenu
            AnchorComponent={AnchorComponent}
            itemGroups={menuItemGroups}
            isLoading={isLoading}
            margins={[16, activeProjectName ? 60 : 44]}
            onOpen={() => setShowMenu(true)}
            onClose={() => setShowMenu(false)}
        />
    )
}

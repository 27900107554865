import type { SortDirection } from '@demia/core'
import { sortPrimitives } from '@demia/core'
import type { FunctionComponent } from 'react'
import type { ITableField } from '@components/base/Table'
import { Table } from '@components/base/Table'
import type { IProjectNotification } from '@lib/project'
import { ProjectActivityTableRow } from './ProjectActivityTableRow.tsx'

interface IProjectActivityTableProps {
    loading: boolean
    activities: IProjectNotification[]
}

const PROJECT_ACTIVITY_TABLE_FIELDS: ITableField[] = [
    {
        text: 'User',
        key: 'user',
    },
    {
        text: 'Message',
        key: 'message',
    },
    {
        text: 'Timestamp',
        key: 'timestamp',
        sortingFunction: (a: IProjectNotification, b: IProjectNotification, direction: SortDirection) =>
            sortPrimitives(a.timestamp, b.timestamp, direction),
        sortByDefault: true,
        defaultSortDirection: 'desc',
    },
]

export const ProjectActivityTable: FunctionComponent<IProjectActivityTableProps> = (props) => {
    const { loading, activities } = props
    return (
        <Table
            fields={PROJECT_ACTIVITY_TABLE_FIELDS}
            data={activities}
            RowTemplate={(p) => ProjectActivityTableRow({ ...p, id: activities.length.toString() })}
            loading={loading}
            disableSorting
        />
    )
}

import type { IErrorNotifier } from '@demia/core'
import { ErrorHandler, generateRandomId } from '@demia/core'
import type { INotification } from '@demia/platform'
import { NotificationSystem } from '@demia/platform'
import type { FunctionComponent, ReactNode } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { NotificationContext } from './contexts'
import { ToastContainer } from './ToastContainer.tsx'

interface INotificationProvider {
    children: ReactNode
}

export const NotificationProvider: FunctionComponent<INotificationProvider> = (props) => {
    const { children } = props

    const [notifications, setNotifications] = useState<INotification[]>([])

    const addNotification = useCallback((notification: Omit<INotification, 'id'>) => {
        const id = generateRandomId()
        setNotifications((prev) => [...prev, { ...notification, id }])
    }, [])

    const removeNotification = useCallback((id: string) => {
        setNotifications((prev) => prev.filter((notification) => notification.id !== id))
    }, [])

    useEffect(() => {
        NotificationSystem.initialize(addNotification)
        ErrorHandler.attachNotifier(NotificationSystem as unknown as IErrorNotifier)
    }, [addNotification])

    return (
        <NotificationContext.Provider value={{ notifications, addNotification, removeNotification }}>
            <ToastContainer notifications={notifications} onRemove={removeNotification} />
            {children}
        </NotificationContext.Provider>
    )
}

import { useAuth0 } from '@auth0/auth0-react'
import { ErrorHandler, validateString } from '@demia/core'
import { NotificationSystem } from '@demia/platform'
import type { FunctionComponent } from 'react'
import type { IDropdownInputItem, IFormField } from '@components/base'
import { Form, Popup } from '@components/base'
import type { IUserProfileData } from '@lib/user'
import { UserApiService } from '@lib/user'

interface IEditProfileDataProps {
    profile?: IUserProfileData
    onUpdate: (payload: IUserProfileData) => void
    onClose: () => void
}

export const EditProfileDataPopup: FunctionComponent<IEditProfileDataProps> = (props) => {
    const { profile = {}, onUpdate, onClose } = props
    const { getAccessTokenSilently } = useAuth0()

    const refreshUser = async () => {
        try {
            await getAccessTokenSilently({ cacheMode: 'off' })
        } catch (error) {
            ErrorHandler.handleApplicationError(`Error refreshing user: ${error}`)
        }
    }

    async function onUpdateProfile(payload: IUserProfileData): Promise<void> {
        try {
            const [_, error] = await UserApiService.updateProfile(payload)
            if (error) {
                ErrorHandler.handleApiError(error)
            } else {
                NotificationSystem.push({
                    type: 'success',
                    message: `Updated Profile`,
                })
                onUpdate(payload)
                await refreshUser()
                onClose()
            }
        } catch (err) {
            ErrorHandler.handleApiError(err)
        }
    }

    const LOCALE_INPUT_ITEMS: IDropdownInputItem[] = [
        {
            text: 'United States',
            active: isActive('United States'),
        },
        {
            text: 'Canada',
            active: isActive('Canada'),
        },
        {
            text: 'France',
            active: isActive('France'),
        },
        {
            text: 'Germany',
            active: isActive('Germany'),
        },
        {
            text: 'Spain',
            active: isActive('Spain'),
        },
        {
            text: 'England',
            active: isActive('England'),
        },
        {
            text: 'Chile',
            active: isActive('Chile'),
        },
        {
            text: 'Argentina',
            active: isActive('Argentina'),
        },
        {
            text: 'Colombia',
            active: isActive('Colombia'),
        },
    ].map((locale) => ({ ...locale, onClick: () => {} }))

    const editProfileDataFormFields: IFormField[] = [
        {
            type: 'text',
            property: 'given_name',
            id: 'givenNameInput',
            label: 'First Name',
            defaultValue: profile.given_name,
            autofocus: true,
            required: true,
            validate: (value) => validateString(value as string, 'First Name'),
        },
        {
            type: 'text',
            property: 'family_name',
            id: 'familyNameInput',
            label: 'Last Name',
            defaultValue: profile.family_name,
            required: true,
            validate: (value) => validateString(value as string, 'Last Name'),
        },
        {
            type: 'text',
            property: 'organization',
            id: 'organizationInput',
            label: 'Organization',
            defaultValue: profile.organization,
            required: false,
            validate: (value) => validateString(value as string, 'Organization'),
        },
        {
            type: 'text',
            property: 'website',
            id: 'websiteInput',
            label: 'Website',
            defaultValue: profile.website,
            required: false,
            validate: (value) => validateString(value as string, 'Website'),
        },
        {
            type: 'dropdown',
            property: 'locale',
            id: 'localeInput',
            label: 'Locale',
            defaultValue: profile.locale,
            required: true,
            dropdownItems: LOCALE_INPUT_ITEMS,
            validate: (value) => validateString(value as string, 'Locale'),
        },
    ]

    function isActive(locale: string): boolean {
        return profile.locale ? profile.locale === locale : false
    }

    return (
        <Popup title='Update profile data' onClose={onClose}>
            <Form<IUserProfileData>
                fields={editProfileDataFormFields}
                onSubmit={onUpdateProfile}
                primaryButtonText='Update'
                primaryButtonBusyText='Updating…'
                secondaryButtonText='Cancel'
                onSecondaryButtonClick={onClose}
            />
        </Popup>
    )
}

import type { FunctionComponent } from 'react'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '@components/app'
import { Loading } from '@components/base'
import { StorageManager } from '@constants'
import { AppRoute, buildRoute } from '@lib/routing'

export const RootPage: FunctionComponent = () => {
    const { hasSession, auth } = useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(() => {
        const routerState = StorageManager.load('routerState')
        if (hasSession) {
            const fallbackRoute = buildRoute(AppRoute.Overview)
            const route = routerState?.location?.pathname ?? fallbackRoute
            navigate(route)
        } else {
            /**
             * NOTE: Because we clear the persisted router state on logout, if we see that
             * it exists despite not having a session then it means that the user refreshed
             * the page.
             */
            const persistedPath = routerState?.location?.pathname ?? ''
            const shouldNavigateToSignIn =
                persistedPath === '' ||
                [buildRoute(AppRoute.Root), buildRoute(AppRoute.SignIn)].includes(persistedPath) ||
                auth === undefined
            if (shouldNavigateToSignIn) {
                navigate(buildRoute(AppRoute.SignIn))
            } else {
                navigate(persistedPath)
            }
        }
    }, [hasSession])

    return <Loading />
}

import type { FunctionComponent } from 'react'
import { DEFAULT_MAX_INPUT_LENGTH } from './constants'
import classes from './Input.module.scss'
import type { IInputProps } from './types'

/**
 * Displays a text-based input component to the page.
 *
 * @param props
 */
export const TextInput: FunctionComponent<IInputProps> = (props) => {
    const {
        id,
        value,
        defaultValue,
        label,
        error,
        maxLength,
        required,
        disabled,
        autofocus,
        readonly = false,
        onChange,
    } = props
    return (
        <div key={`${id}_input_div`} className='flex flex-col space-y-2 transparent'>
            {label && (
                <label className={`text-text-primary dark:text-text-primary-dark ${classes.label}`} htmlFor={id}>
                    {label}
                    <span className='text-text-danger'>{required ? '*' : ''}</span>
                </label>
            )}
            <input
                className={`bg-surface-0 text-text-primary caret-text-brand-primary ${classes.input} ${error ? classes.errorInput : ''}`}
                id={id}
                value={value}
                defaultValue={defaultValue}
                type='text'
                maxLength={maxLength ?? DEFAULT_MAX_INPUT_LENGTH}
                required={required ?? false}
                disabled={disabled ?? false}
                autoFocus={autofocus ?? false}
                readOnly={readonly}
                onChange={onChange}
            />
            {error && <p className={classes.errorText}>{error}</p>}
        </div>
    )
}

import { ErrorHandler, strToCamelCase } from '@demia/core'
import type { IStripeSubscription } from '@demia/platform'
import { STRIPE_PRODUCTS, StripeApiService } from '@demia/platform'
import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup'
import type { FunctionComponent, MouseEvent } from 'react'
import { useEffect, useState } from 'react'
import type { IProjectDetails } from '@lib/project'
import classes from './SubscriberInfo.module.scss'

export const SubscriptionInfo: FunctionComponent<{
    customerId: string
    site: IProjectDetails
    subscription: IStripeSubscription
}> = (customerProps) => {
    const subscription = customerProps.subscription
    const [product, setProduct] = useState<any | null>(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const [anchor, setAnchor] = useState<null | HTMLElement>(null)

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchor(anchor ? null : event.currentTarget)
    }

    const open = Boolean(anchor)
    const id = open ? 'simple-popper' : undefined
    const sendCancelSignal = async (subscriptionId: string) => {
        const [_, error] = await StripeApiService.cancelSubscription(subscriptionId)
        if (error) {
            ErrorHandler.handleApiError(error, { notifyUser: false })
            setError(error.message)
        } else {
            window.location.reload()
        }
    }

    useEffect(() => {
        const fetchSubscriptions = async () => {
            if (subscription.metadata['project_id'] === customerProps.site.projectId) {
                if (subscription.items.data.length > 0) {
                    const _product = subscription.items.data[0].plan?.product
                    const productId =
                        typeof _product === 'string' || typeof _product === 'undefined' ? _product : _product.id
                    const product = STRIPE_PRODUCTS.find((product) => product.productId === productId)
                    if (product) {
                        setProduct(product)
                    } else {
                        ErrorHandler.handleApplicationError('No Stripe product was found')
                    }
                } else {
                    ErrorHandler.handleApplicationError('No Stripe subscriptions were found')
                }
            }
            setLoading(false)
        }

        fetchSubscriptions().catch((err) => setError(err.message))
    }, [customerProps.customerId])

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error: {error}</p>

    return (
        <div className={classes.subscriberInfo}>
            <h3 className={classes.title}>{customerProps.site.projectName}</h3>
            <div>
                {subscription && product && (
                    <div className={classes.subscription} key={subscription.id}>
                        <h2>Subscription Type</h2>
                        <p>{product.name}</p>
                        <h3>Status</h3>
                        <p>{strToCamelCase(subscription.status)}</p>
                        <h3>Subscription Expiration</h3>
                        <p>{new Date(subscription.current_period_end * 1000).toLocaleDateString()}</p>
                        <h3>Subscription Renewal</h3>
                        <p>{subscription.cancel_at_period_end ? 'Inactive' : 'Active'}</p>
                        {!subscription.cancel_at_period_end && (
                            <button onClick={handleClick}> Cancel Subscription Renewal</button>
                        )}
                        <Popup id={id} open={open} anchor={anchor} style={{ zIndex: 50 }} placement={'bottom'}>
                            <div className={classes.cancelMenu}>
                                <h2>Subscription Cancellation</h2>
                                <h3>{customerProps.site.projectName}</h3>
                                <p>Are you sure you want to cancel your subscription?</p>
                                <button onClick={() => sendCancelSignal(subscription.id)}>
                                    Yes, Stop Subscription Renewal
                                </button>
                                <button onClick={handleClick}>No, Keep Subscription</button>
                            </div>
                        </Popup>
                    </div>
                )}
                {!subscription && <p>No subscriptions found for this site.</p>}
                {subscription && !product && <p>No product found for this subscription.</p>}
            </div>
        </div>
    )
}

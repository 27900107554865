import { generateRandomId } from '@demia/core'
import type { FunctionComponent, ReactNode } from 'react'
import { useState } from 'react'
import { PopupContext } from './contexts'
import { PopupOverlay } from './PopupOverlay.tsx'
import type { IPopupState } from './types'

interface IPopupProviderProps {
    children: ReactNode
}

export const PopupProvider: FunctionComponent<IPopupProviderProps> = (props) => {
    const { children } = props

    const [popup, setPopup] = useState<IPopupState | null>(null)

    function openPopup(payload: Omit<IPopupState, 'id'>): void {
        setPopup({ ...payload, dismissible: payload.dismissible ?? true, id: generateRandomId() })
    }

    function closePopup(): void {
        setPopup(null)
    }

    return (
        <PopupContext.Provider value={{ openPopup, closePopup, popup }}>
            {children}
            <PopupOverlay popup={popup as IPopupState} closePopup={closePopup} />
        </PopupContext.Provider>
    )
}

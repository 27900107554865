import type { IconName, Style, TextJustification } from '@demia/ui-kit'
import { Icon, StyleContext } from '@demia/ui-kit'
import type { FunctionComponent, ReactNode } from 'react'
import { useState, useContext } from 'react'
import { Link as _Link } from 'react-router-dom'
import { LinkTarget } from './link'
import classes from './Link.module.scss'

interface LinkProps {
    children: ReactNode
    url: string
    search?: Record<string, string>
    hash?: string
    target?: LinkTarget
    justification?: TextJustification
    icon?: IconName
    reverse?: boolean
    color?: string
    disabled?: boolean
}

/**
 * Navigates the user within different pages of the application or
 * to an external website.
 *
 * @param props
 */
export const Link: FunctionComponent<LinkProps> = (props) => {
    const {
        children,
        url,
        search,
        hash,
        target,
        justification = 'left',
        icon,
        reverse,
        color = 'text-brand-primary',
        disabled = false,
    } = props
    const { isDark } = useContext(StyleContext).data
    const style: Style = {
        textAlign: justification,
        color: `var(--${color}${isDark ? '-dark' : ''})`,
    }

    const [isHovering, setIsHovering] = useState(false)

    function buildPath(): string {
        const searchString = new URLSearchParams(search)
        return `${url}${search ? '?' + searchString.toString() : ''}${hash ? '#' + hash : ''}`
    }

    return (
        <_Link
            to={buildPath()}
            target={`_${target ?? LinkTarget.Self}`}
            className={`flex flex-row ${reverse ? 'flex-row-reverse ' + classes.reverse : ''} items-center gap-2 ${disabled ? classes.disabled : ''} ${classes.link} ${isDark && classes['link-dark']}`}
            style={{ ...style }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            {children}
            {icon && (
                <Icon
                    name={icon}
                    color={`${isHovering ? 'text-brand-primary-hover' : color}${isDark ? '-dark' : ''}`}
                />
            )}
        </_Link>
    )
}

import type { FunctionComponent } from 'react'
import type { IActionMenuItemProps } from '@components/base'
import { ActionMenu, usePopup } from '@components/base'
import type { IUserProfileData } from '@lib/user'
import { EditProfileDataPopup } from './EditProfileDataPopup'
import { ProfilePictureUploadPopup } from './ProfilePictureUploadPopup'

interface IProfileTabMenuProps {
    profileData: IUserProfileData
    onProfileUpdated: (payload: IUserProfileData) => void
}

export const ProfileTabMenu: FunctionComponent<IProfileTabMenuProps> = (props) => {
    const { profileData, onProfileUpdated } = props
    const { openPopup, closePopup } = usePopup()

    const profileTabMenuItems: IActionMenuItemProps[] = [
        {
            title: 'Change picture',
            icon: 'image',
            onClick: () =>
                openPopup({
                    content: <ProfilePictureUploadPopup />,
                }),
        },
        {
            title: 'Edit details',
            icon: 'pencil',
            onClick: () =>
                openPopup({
                    content: (
                        <EditProfileDataPopup profile={profileData} onUpdate={onProfileUpdated} onClose={closePopup} />
                    ),
                }),
        },
    ]

    return <ActionMenu items={profileTabMenuItems} />
}

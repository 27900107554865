import { dateToReadable } from '@demia/core'
import type { IconName } from '@demia/ui-kit'
import { BrandedIcon, Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { grapeHarvest, greenWaste, sheet } from '@assets'
import type { EquipmentPath } from '@components/app'
import {
    DEFAULT_EQUIPMENT_PATH,
    ProjectDataSourceImage,
    ProjectLayout,
    ProjectSensorDataTable,
    PUBLIC_PROJECT_ASSET_PATH,
    sortNestedReadings,
} from '@components/app'
import { BarChart, Pane, Skeleton, Tabs } from '@components/base'
import type { IChartData } from '@components/base/Charts/types.ts'
import type { ISensorContextData, ISensorData, NestedReadingValue } from '@lib/sensor'
import { getSensorDataFromDashboardContext, transformReadings } from '@lib/sensor'
import { ProjectDataSourceTitle } from '../ProjectDataSourceTitle'

const cargoDataEmpty: Array<{
    icon: IconName
    text: string
    value: NestedReadingValue
    valueUnit: string
}> = [
    {
        icon: 'cargo',
        text: 'Current load',
        value: { type: 'Empty' },
        valueUnit: 'Kg',
    },
    {
        icon: 'clock-clockwise',
        text: 'Daily average load',
        value: { type: 'Empty' },
        valueUnit: 'Kg',
    },
    {
        icon: 'chart-bar',
        text: 'Data confidence',
        value: { type: 'Empty' },
        valueUnit: '%',
    },
]

export const CargoDataSource: FunctionComponent<{ sensorContext: ISensorContextData }> = (props) => {
    const { slug } = useParams()
    const url = `/projects/${slug}/data-sources`

    const { sensorContext } = props
    const backupUri: EquipmentPath = `${DEFAULT_EQUIPMENT_PATH}${sensorContext?.id}`
    const imageUri = `${PUBLIC_PROJECT_ASSET_PATH}${sensorContext?.assetUrl || backupUri}`

    const location = useLocation()
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [location])

    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

    const [cargoData, setCargoData] = useState<
        Array<{
            icon: IconName
            text: string
            value: NestedReadingValue
            valueUnit: string
        }>
    >([{ ...cargoDataEmpty[0] }, { ...cargoDataEmpty[1] }, { ...cargoDataEmpty[2] }])

    const [tableList, setTableList] = useState<{ [key: string]: ISensorData[] }>({})
    const [graphData, setGraphData] = useState<Array<{ equipment: string; data: IChartData[] }>>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (sensorContext) {
            const sorted = transformReadings(sensorContext.readings)
            const newGraphData = sortNestedReadings(sorted, sensorContext.equipment.name)
            setGraphData(newGraphData)

            const tableList: { [key: string]: ISensorData[] } = {}
            Object.entries(sorted).forEach((v) => {
                const [key, context] = v
                tableList[key] = Object.values(context)
                    .map((v) => {
                        const readings = Array.from(Object.values(v)).map((v) => {
                            const unit = v.unit ? v.unit : sensorContext.equipment.units
                            return getSensorDataFromDashboardContext(v, unit, sensorContext.annotations)
                        })

                        readings.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())

                        return readings
                    })
                    .flat()
            })

            setTableList(tableList)
            setLoading(false)
        }
    }, [sensorContext])

    useEffect(() => {
        if (sensorContext) {
            const numbers = [...cargoData]
            numbers[0].value = { type: 'Float', value: Math.round(props.sensorContext.state.realTimeFlow) }
            numbers[0].valueUnit = props.sensorContext.equipment.units
            numbers[1].value = { type: 'Float', value: Math.round(props.sensorContext.state.currentDayAvg) }
            numbers[1].valueUnit = props.sensorContext.equipment.units
            numbers[2].value = { type: 'Float', value: Math.round(props.sensorContext.avgcf) }
            setCargoData(numbers)
        }
    }, [sensorContext])

    const TitleComponent = () => {
        return <ProjectDataSourceTitle title={sensorContext?.equipment.name ?? 'Cargo'} url={url} />
    }

    const imageAlt =
        sensorContext?.id === 'Grape Harvest'
            ? 'Grape Harvest Image'
            : sensorContext?.id === 'Digestors 1 + 2'
              ? 'Green Waste Image'
              : 'Sheet Placeholder'
    const defaultImage =
        sensorContext?.id === 'Grape Harvest'
            ? grapeHarvest
            : sensorContext?.id === 'Digestors 1 + 2'
              ? greenWaste
              : sheet

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <Tabs tabHeaders={['Overview', 'Logs']}>
                <article className='flex flex-col space-y-8'>
                    {graphData.length < 2 && (
                        <div className='flex flex-row items-center justify-between space-x-4 p-8 clipped bg-surface-transparent dark:bg-surface-transparent-dark'>
                            <div className='min-h-[300px]'>
                                <ProjectDataSourceImage uri={imageUri} alt={imageAlt} defaultImage={defaultImage} />
                            </div>
                            <div className='w-full flex flex-col space-y-4 justify-between'>
                                <div className='w-full flex flex-row items-center justify-between space-x-4'>
                                    {cargoData.map((data, index) => (
                                        <div
                                            className='w-1/3 h-full bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] border-[1px] border-[var(--stroke-light)] dark:border-[var(--stroke-light-dark)] flex flex-col items-center justify-between p-6 space-y-4'
                                            key={index}
                                        >
                                            <BrandedIcon name={data.icon} />
                                            <Text type='body1' color='text-secondary'>
                                                {data.text}
                                            </Text>
                                            <h5 className='font-32-28-500 text-text-primary dark:text-text-primary-dark'>
                                                {data.value.type == 'Float' || data.value.type == 'Int'
                                                    ? data.value.value
                                                    : 0}{' '}
                                                <span className='font-24-28-400 text-[var(--text-secondary)]'>
                                                    {data.valueUnit}
                                                </span>
                                            </h5>
                                        </div>
                                    ))}
                                </div>
                                <div className='w-full bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] border-[1px] border-[var(--stroke-light)] dark:border-[var(--stroke-light-dark)] p-6'>
                                    <Text type='header3'>Solid Waste Load</Text>
                                    <div className='w-full flex flex-row items-center justify-between mt-6'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <Text type='body1' color='text-secondary'>
                                                Source:
                                            </Text>
                                            <Text type='subtitle1'>
                                                {sensorContext?.equipment.id || <Skeleton width={64} />}
                                            </Text>
                                        </div>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <Text type='body1' color='text-secondary'>
                                                Last updated:
                                            </Text>
                                            <Text type='subtitle1'>
                                                {sensorContext ? (
                                                    dateToReadable(new Date(sensorContext.lastUpdated))
                                                ) : (
                                                    <Skeleton width={64} />
                                                )}
                                            </Text>
                                        </div>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <Text type='body1' color='text-secondary'>
                                                Units:
                                            </Text>
                                            <Text type='subtitle1'>
                                                {sensorContext?.equipment.units || <Skeleton width={64} />}
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <Pane>
                        {Object.entries(graphData).map((v) => {
                            const [_, nested] = v
                            return (
                                <div className='p-2'>
                                    <BarChart
                                        title={{ title: nested.equipment }}
                                        data={nested.data}
                                        loading={!sensorContext}
                                    />
                                </div>
                            )
                        })}
                    </Pane>
                </article>
                {Object.entries(tableList).map((v) => {
                    const [key, value] = v
                    return <ProjectSensorDataTable title={key} loading={loading} sensorDataList={value} />
                })}
            </Tabs>
        </ProjectLayout>
    )
}

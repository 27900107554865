import { strFromPascalToCamelCase, strToCapitalCase } from '@demia/core'
import { Text } from '@demia/ui-kit'
import { useContext, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { ProjectLayout } from '@components/app'
import { DateSelector, Formula, LineAreaChart, Link, Pane, Skeleton, TextInput } from '@components/base'
import type { IChartTitle, IChartTooltipMetadata } from '@components/base/Charts/types.ts'
import type { AnalyticsParameter, IAnalyticsCalculation } from '@lib/analytics'
import { isCalculationParameter, isStaticParameter } from '@lib/analytics'
import type { IProjectStateValueSet } from '@lib/project'
import { getDatedTotal, ProjectContext } from '@lib/project'
import { buildProjectRoute, ProjectRoute } from '@lib/routing'
import classes from './ProjectAnalyticsDetailsPage.module.scss'

export const ProjectAnalyticsDetailsPage = () => {
    const { analyticsSlug, slug } = useParams()
    const url = buildProjectRoute(ProjectRoute.Analytics).replace(':slug', (slug ?? '').toString())
    const location = useLocation()

    const { data, setData } = useContext(ProjectContext)
    const { details, dateSelection } = data
    const { startDate, endDate } = dateSelection

    const [analytics, setAnalytics] = useState<IProjectStateValueSet | null>(null)
    const [parameters, setParameters] = useState<AnalyticsParameter[] | null>(null)
    const [selectedInputs, setSelectedInputs] = useState<Record<string, [Date, number]>>({})
    const [calculation, setCalculation] = useState<IAnalyticsCalculation | null>(null)

    /**
     * NOTE: We store the previous timestamp to avoid redundant computation to find the correct
     * input value from the value set.
     */
    const [previousTimestamp, setPreviousTimestamp] = useState<Date | null>(null)

    useMemo(() => {
        if (details) {
            const [_, valueSet] =
                Object.entries(details.stateData.valueSets).find(([id, _]) => id === analyticsSlug) ?? []
            if (valueSet) {
                setAnalytics(valueSet)
                if (!previousTimestamp) {
                    const firstTimestamp = valueSet.data?.[valueSet.data?.length - 1]?.[0]
                    setPreviousTimestamp(firstTimestamp ? new Date(firstTimestamp) : null)
                }
                setParameters(valueSet.params)
            }

            if (details.profiles) {
                // ToDo: we need to map calculations to profiles in case we have overlapping names
                details.profiles.map((profile) => {
                    const calc = profile.calculations.find((calculation) => calculation.id === analyticsSlug)
                    if (calc) {
                        setCalculation(calc)
                    }
                })
            }
        }
    }, [details, location])

    useMemo(() => {
        const inputs = Object.entries(analytics?.inputs ?? {}).map(([id, values]) => [
            id,
            values.find(([date]) => previousTimestamp?.getTime() === new Date(date).getTime()),
        ])
        setSelectedInputs(Object.fromEntries(inputs))
    }, [previousTimestamp])

    function onDateChange(startDate: Date, endDate: Date): void {
        setData({
            dateSelection: { startDate, endDate },
        })
    }

    const TitleComponent = () => {
        return (
            <div className='w-full flex flex-row items-center justify-between relative'>
                <Link url={url} icon='arrow-left' color='text-primary' reverse>
                    <Text type='header2'>Analytics Parameters</Text>
                </Link>
                <DateSelector startDate={startDate} endDate={endDate} onDateChange={onDateChange} />
            </div>
        )
    }

    function getChartTitle(): IChartTitle | undefined {
        return analytics
            ? {
                  title: analytics.title,
                  total: getDatedTotal(analytics.data, startDate, endDate),
                  label: analytics.label,
              }
            : undefined
    }

    function onNewTooltip(data: IChartTooltipMetadata[]): void {
        const timestamp = data[0]?.payload?.name
        if (!timestamp || previousTimestamp?.getTime() === new Date(timestamp).getTime()) {
            return
        }
        setPreviousTimestamp(new Date(timestamp))
    }

    function calculationSlug(analytic: string): string {
        return '/projects/' + slug + '/analytics/' + strFromPascalToCamelCase(analytic)
    }

    function mapParameter(item: AnalyticsParameter, index: number) {
        return isStaticParameter(item) ? (
            <div className={classes.singleItemInput} key={index}>
                <div className={classes.left}>
                    <Text type='header4'>{item.Static.id}</Text>
                    <Text type='body1'>{item.Static.text}</Text>
                </div>
                <div className={classes.right}>
                    <TextInput
                        id={index.toString()}
                        label={`Value ${item.Static.unit.length > 0 ? '(' + item.Static.unit + ')' : ''}`}
                        value={item.Static.value}
                        readonly
                        disabled
                    />
                </div>
            </div>
        ) : isCalculationParameter(item) ? (
            <div className={classes.singleItemInput} key={index}>
                <div className={classes.left}>
                    <Text type='header4'>{strToCapitalCase(item.Calculation.id)}</Text>
                    <Text type='body1'>{item.Calculation.text}</Text>
                </div>
                <div className={classes.right}>
                    <Link url={calculationSlug(item.Calculation.id)}>{strToCapitalCase(item.Calculation.id)}</Link>
                </div>
            </div>
        ) : (
            <div className={classes.singleItemInput} key={index}>
                <div className={classes.left}>
                    <Text type='header4'>{item.Input.label}</Text>
                    <Text type='body1'>{item.Input.text}</Text>
                </div>
                <div className={classes.right}>
                    <TextInput
                        id={index.toString()}
                        label={`Value ${item.Input.unit.length > 0 ? '(' + item.Input.unit + ')' : ''}`}
                        value={selectedInputs[item.Input.id]?.[1] ?? '---'}
                        readonly
                        disabled
                    />
                </div>
            </div>
        )
    }

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <Pane>
                {analytics ? (
                    <LineAreaChart
                        title={getChartTitle()}
                        data={Object.values(analytics?.data || []).map(([timestamp, val]) => {
                            return { name: timestamp, uv: val, pv: val, amt: val }
                        })}
                        start={startDate}
                        end={endDate}
                        height={245}
                        onNewTooltip={onNewTooltip}
                    />
                ) : (
                    <Skeleton height={245} />
                )}
                {calculation && (
                    <div className='w-full flex flex-row items-center justify-center px-8 pt-12 pb-8'>
                        <Formula formula={`$${calculation.equation}$`} />
                    </div>
                )}
                {parameters && <div>{parameters.map((item, index) => mapParameter(item, index))}</div>}
            </Pane>
        </ProjectLayout>
    )
}

import { useAuth0 } from '@auth0/auth0-react'
import { ErrorHandler } from '@demia/core'
import { AuthApiService } from '@demia/platform'
import type { FunctionComponent, ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { Loading } from '@components/base'
import { StorageManager } from '@constants'
import { AppRoute, buildRoute } from '@lib/routing'
import { logoutUser } from '@lib/user'
import { AuthContext } from './contexts'

interface IAuthProviderProps {
    children: ReactNode
}

export const AuthProvider: FunctionComponent<IAuthProviderProps> = ({ children }) => {
    const { user, isAuthenticated, getIdTokenClaims, logout } = useAuth0()
    const [hasFetchedToken, setHasFetchedToken] = useState(false)
    const [isFetchingToken, setIsFetchingToken] = useState(false)

    const signInUrl = `${window.location.origin}${buildRoute(AppRoute.SignIn)}`

    async function getAuthToken(): Promise<void> {
        try {
            if (user) {
                const authToken = await getIdTokenClaims()
                const canUseToken = authToken && authToken.exp && authToken.exp * 1000 > Date.now()
                if (canUseToken) {
                    const { __raw } = authToken
                    AuthApiService.setBearerToken(__raw)

                    /**
                     * NOTE: When a user is logging in, we expect this call to fail because there is
                     * no session yet. However, there will be a session if the user refreshes the page
                     * while logged in, in which case we do NOT want to re-login.
                     */
                    const [sessionStatus, _] = await AuthApiService.getSession()
                    if (sessionStatus) {
                        setHasFetchedToken(true)
                    } else {
                        //console.log(user.email, __raw)
                        const [_, error] = await AuthApiService.login(user.email!, __raw, __raw)
                        if (error) {
                            ErrorHandler.handleApiError(error)
                            /**
                             * NOTE: If the attempt to refresh the session fails, then log the user out of auth0,
                             * which will automatically redirect them to the sign-in page to redo the whole process.
                             */
                            await logoutUser(async () => logout({ openUrl: false }))
                        } else {
                            setHasFetchedToken(true)
                        }
                    }
                } else {
                    const wasOnSignIn =
                        buildRoute(AppRoute.SignIn) === StorageManager.load('routerState')?.location?.pathname
                    if (!wasOnSignIn) {
                        await logoutUser(async () => logout({ openUrl: false }))
                        window.location.assign(signInUrl)
                    }
                }
            }
        } catch (err) {
            ErrorHandler.handleApplicationError('Unable to find user')
        }
    }

    useEffect(() => {
        const helperFunction = async () => {
            try {
                if (isAuthenticated && !isFetchingToken && !hasFetchedToken) {
                    setIsFetchingToken(true)
                    await getAuthToken()
                }
            } catch (err) {
                ErrorHandler.handleApplicationError('Unable to get authentication token', { notifyUser: false })
                window.location.reload()
            } finally {
                setIsFetchingToken(false)
            }
        }

        void helperFunction()
    }, [isAuthenticated, user])

    if (isAuthenticated && Boolean(user)) {
        return (
            <AuthContext.Provider value={{ auth: user, hasSession: hasFetchedToken }}>
                {isFetchingToken ? <Loading /> : children}
            </AuthContext.Provider>
        )
    } else {
        return (
            <AuthContext.Provider value={{ auth: user, hasSession: hasFetchedToken }}>{children}</AuthContext.Provider>
        )
    }
}

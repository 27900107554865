import { Skeleton } from '@components/base/Skeleton'
import { TableRow } from '../TableRow.tsx'
import type { ITableRowProps } from '../types.ts'
import classes from './LoadingTableRow.module.scss'

export function LoadingTableRow<T>(props: ITableRowProps<T>) {
    const { colSpan, id } = props
    return (
        <TableRow colSpan={colSpan ?? 1} id={id}>
            {[
                <div key={0} className={classes.loadingSkeletonContainer}>
                    <Skeleton height={32} count={3} />
                </div>,
            ]}
        </TableRow>
    )
}

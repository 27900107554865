import { ErrorHandler } from '@demia/core'
import type { IconName, Style } from '@demia/ui-kit'
import { useKeyPress, Icon, StyleContext } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useEffect, useRef, useState, Fragment, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { OutsideAlerter } from '@components/base/Popover'
import classes from './Menu.module.scss'

export interface INavigationMenuProps {
    AnchorComponent: FunctionComponent
    itemGroups: INavigationMenuItemProps[][]
    isLoading?: boolean
    margins?: [number, number]
    onOpen?: () => void
    onClose?: () => void
}

export interface INavigationMenuItemProps {
    url: string
    text: string
    icon: IconName
    isActive?: boolean
    isDisabled?: boolean
    isBusy?: boolean
    busyText?: string
    onClick?: () => void
}

export const NavigationMenu: FunctionComponent<INavigationMenuProps> = (props) => {
    const { AnchorComponent, itemGroups, isLoading = false, margins = [0, 0], onOpen, onClose } = props
    const isDark = useContext(StyleContext).data.theme === 'dark'

    const [isOpen, setIsOpen] = useState(false)
    const menuRef = useRef<HTMLDivElement | null>(null)

    const style: Style = {
        left: `${margins[0]}px`,
        top: `${margins[1]}px`,
    }

    function toggleMenu(): void {
        setIsOpen((prev) => {
            if (prev) {
                onClose && onClose()
            } else {
                onOpen && onOpen()
            }

            return !prev
        })
    }

    function closeMenu(): void {
        onClose && onClose()
        setIsOpen(false)
    }

    function isLastGroup(groupIndex: number): boolean {
        return groupIndex === itemGroups.length - 1
    }

    function isEndMenuItem(groupIndex: number, itemIndex: number): boolean {
        return isLastGroup(groupIndex) && itemIndex === itemGroups[groupIndex]?.length - 1
    }

    function onMenuItemClick(callback: (() => void) | undefined): void {
        try {
            callback && callback()
            setIsOpen(false)
        } catch (err) {
            ErrorHandler.handleApplicationError(err)
        }
    }

    useKeyPress('Escape', closeMenu)

    useEffect(() => {
        function handleClickOutside(event: MouseEvent): void {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                closeMenu()
            }
        }

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [isOpen])

    const [hoverIndex, setHoverIndex] = useState(-1)

    return (
        <OutsideAlerter onClick={closeMenu}>
            <div className='relative z-10'>
                <div onClick={toggleMenu} className='cursor-pointer'>
                    <AnchorComponent />
                </div>

                {isOpen && (
                    <div
                        className={`clipped-right pointer-events-auto ${classes.navigationMenu} ${isOpen && classes.active} ${isDark && classes.navigationMenuDark}`}
                        style={style}
                    >
                        <div className={classes.links}>
                            {(isLoading && !itemGroups) || itemGroups.length === 0 ? (
                                <div className={classes.skeleton}>
                                    <Icon name='map-pin' color='text-secondary' />
                                    Loading...
                                </div>
                            ) : (
                                itemGroups.map((group, groupIndex) => (
                                    <Fragment key={groupIndex}>
                                        {group.map((item, itemIndex) => {
                                            const key = Math.pow(2, groupIndex) * Math.pow(3, itemIndex)
                                            return (
                                                <NavLink
                                                    key={key}
                                                    to={item.url}
                                                    end={isEndMenuItem(groupIndex, itemIndex)}
                                                    className={`${item.isActive ? classes.active : item.isDisabled || item.isBusy ? classes.disabled : ''} text-nowrap`}
                                                    onClick={() => onMenuItemClick(item.onClick)}
                                                    onMouseEnter={() => setHoverIndex(key)}
                                                    onMouseLeave={() => setHoverIndex(-1)}
                                                >
                                                    <Icon
                                                        name={item.icon}
                                                        color={
                                                            item.isActive || hoverIndex === key
                                                                ? 'text-brand-primary'
                                                                : 'text-secondary'
                                                        }
                                                    />
                                                    {item.isBusy ? item.busyText : item.text}
                                                </NavLink>
                                            )
                                        })}
                                        {!isLastGroup(groupIndex) && <div className={classes.spacer}></div>}
                                    </Fragment>
                                ))
                            )}
                        </div>
                    </div>
                )}
            </div>
        </OutsideAlerter>
    )
}

import type { Result } from '@demia/core'
import { ErrorHandler } from '@demia/core'
import { Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useMemo, useState } from 'react'
import { Button, CheckboxInput, Popup, usePopup } from '@components/base'
import type { DataExportFileType, ISensorContextData } from '@lib/sensor'
import {
    exportDataToFile,
    getSensorDataFromDashboardContext,
    mapSensorDataToAnnotations,
    sortSensorData,
} from '@lib/sensor'

interface IExportDataSourceDataPopupProps {
    dataSourceContext: ISensorContextData
}

export const ExportDataSourceDataPopup: FunctionComponent<IExportDataSourceDataPopupProps> = (props) => {
    const { dataSourceContext } = props
    const { closePopup } = usePopup()

    const [isExporting, setIsExporting] = useState(false)
    const [dataExports, setDataExports] = useState<Record<DataExportFileType, boolean>>({
        csv: true,
        xlsx: true,
    })

    const canExport = useMemo(() => Object.values(dataExports).some((value) => Boolean(value)), [dataExports])

    function toggleDataExport(type: DataExportFileType): void {
        setDataExports((prev) => ({ ...prev, [type]: !prev[type] }))
    }

    async function onExport(): Promise<void> {
        setIsExporting(true)

        let hadError = false
        for (const [fileType, isExporting] of Object.entries(dataExports)) {
            if (isExporting) {
                const [_, error] = await onExportHelper(fileType as DataExportFileType)
                if (error) {
                    hadError = true
                    ErrorHandler.handleApplicationError(error)
                }
            }
        }

        setIsExporting(false)

        if (!hadError) {
            closePopup()
        }
    }

    async function onExportHelper(type: DataExportFileType): Promise<Result<boolean>> {
        try {
            const sortedSensorData = sortSensorData(
                Object.entries(dataSourceContext.readings ?? []).map(([key, value]) => ({
                    id: key,
                    ...getSensorDataFromDashboardContext(
                        value,
                        dataSourceContext.equipment.units,
                        dataSourceContext.annotations ?? ''
                    ),
                }))
            )
            await exportDataToFile(type, [sortedSensorData, mapSensorDataToAnnotations(sortedSensorData)], {
                filename: dataSourceContext.id,
                sheetNames: ['Readings', 'Annotations'],
                useHeaderRow: true,
            })

            return [true, null]
        } catch (error) {
            return [null, new Error(`Unable to generate ${type.toUpperCase()} file`)]
        }
    }

    return (
        <Popup title='Export data' width='30vw' onClose={closePopup}>
            <div>
                <Text type='body1' color='text-secondary'>
                    You're exporting data from{' '}
                    <span className='text-text-brand-primary dark:text-text-brand-primary-dark'>
                        {dataSourceContext.equipment.name}
                    </span>
                    . Select your preferred format(s) and click "Export" to continue.
                </Text>
            </div>
            <div className='flex flex-col space-y-4'>
                <CheckboxInput
                    id='exportCsv'
                    label='Export CSV file'
                    checked={dataExports['csv']}
                    onClick={() => toggleDataExport('csv')}
                />
                <CheckboxInput
                    id='exportXlsx'
                    label='Export XLSX file'
                    checked={dataExports['xlsx']}
                    onClick={() => toggleDataExport('xlsx')}
                />
            </div>
            <div className='flex flex-row items-center justify-between space-x-4 pt-4'>
                <Button variant='text' width='1/2' size='medium' onClick={closePopup} disabled={isExporting}>
                    Cancel
                </Button>
                <Button
                    size='medium'
                    width='1/2'
                    onClick={onExport}
                    busy={isExporting}
                    busyText='Exporting…'
                    disabled={!canExport}
                >
                    Export
                </Button>
            </div>
        </Popup>
    )
}

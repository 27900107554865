import { ErrorHandler } from '@demia/core'
import { Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useContext, useEffect, useState } from 'react'
import { ProjectLayout, ProjectMenu } from '@components/app'
import { Skeleton } from '@components/base'
import type { IProjectDetails, IProjectNotification, IProjectStatistic } from '@lib/project'
import { ProjectApiService, ProjectContext } from '@lib/project'
import { ProjectDigitalTwinDashboard } from './ProjectDigitalTwinDashboard'
import { ProjectFullscreenMapDashboard } from './ProjectFullscreenMapDashboard'

const PROJECT_STATISTICS: IProjectStatistic[] = [
    {
        type: 'sustainability',
        amount: '',
        unit: '',
        text: 'Annual GHG emission reductions',
    },
    {
        type: 'sustainability',
        amount: '',
        unit: '',
        text: 'GHG emission reductions last 30 day',
    },
    {
        type: 'chart',
        amount: '',
        unit: '%',
        text: 'Data Confidence',
    },
]

export const ProjectDashboardPage = () => {
    const { details } = useContext(ProjectContext).data
    const hasTwin = details?.projectInfo?.projectId === 'BEM' || details?.projectInfo?.projectId === 'Copiulema'
    const [project, setProject] = useState<IProjectDetails | null>(null)
    const [notifications, setNotifications] = useState<IProjectNotification[] | null>(null)
    const [statistics, setStatistics] = useState<IProjectStatistic[]>([
        { ...PROJECT_STATISTICS[0] },
        { ...PROJECT_STATISTICS[1] },
        { ...PROJECT_STATISTICS[2] },
    ])

    async function loadNotifications(): Promise<void> {
        const [data, error] = await ProjectApiService.getNotifications()
        if (error) {
            ErrorHandler.handleApiError(error)
        } else {
            data.notifications.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
            setNotifications(data.notifications)
        }
    }

    useEffect(() => {
        if (details) {
            setProject(details)
        }

        void loadNotifications()
    }, [details])

    const parseValue = (value: string) => {
        const parsed = parseFloat(value)
        return parseFloat(parsed.toFixed(1)).toLocaleString()
    }

    useEffect(() => {
        if (project) {
            const ghgAnnual = {
                type: 'sustainability',
                amount: parseValue(project.ghgAnnual.value),
                unit: project.ghgAnnual.unit,
                text: project.ghgAnnual.label,
            }

            const ghg30Day = {
                type: 'sustainability',
                amount: parseValue(project.ghgLast30Days.value),
                unit: project.ghgLast30Days.unit,
                text: project.ghgLast30Days.label,
            }

            const dcf = {
                type: 'chart',
                amount: project.avgDcf ? project.avgDcf : '0',
                unit: '%',
                text: 'Data Confidence',
            }

            setStatistics([ghgAnnual, ghg30Day, dcf])
        }
    }, [project])

    const ProjectDashboardPageTitle: FunctionComponent = () => {
        return (
            <>
                <Text type='header2'>Dashboard</Text>
                <ProjectMenu site={project as IProjectDetails} navigateOnRemoveSite />
            </>
        )
    }

    return (
        <ProjectLayout TitleComponent={ProjectDashboardPageTitle} title={project ? project.projectName : 'Dashboard'}>
            {hasTwin ? (
                <ProjectDigitalTwinDashboard
                    details={project as IProjectDetails}
                    notifications={notifications as IProjectNotification[]}
                    statistics={statistics}
                />
            ) : project ? (
                <ProjectFullscreenMapDashboard
                    details={project}
                    notifications={notifications as IProjectNotification[]}
                />
            ) : (
                <Skeleton height={512} />
            )}
        </ProjectLayout>
    )
}

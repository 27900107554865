import type { IconName, Style } from '@demia/ui-kit'
import { Icon, StyleContext, Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useContext } from 'react'
import { Skeleton, StatisticLabel } from '@components/base'
import type { ProjectStatisticProps } from './project-statistic.ts'

const STATISTIC_TYPE_ICON_MAPPING: Record<string, IconName> = {
    chart: 'chart-bar',
    chartDark: 'chart-bar',
    sustainability: 'plant',
    sustainabilityDark: 'plant',
}

export const ProjectStatisticSmall: FunctionComponent<Omit<ProjectStatisticProps, 'size'>> = (props) => {
    const { statistic } = props
    const isDark = useContext(StyleContext).data.theme === 'dark'
    const { type, amount, unit, text, percentage } = statistic
    const style: Style = {
        fontFamily: 'var(--font)',
    }

    return (
        <div className='bg-[var(--surface-0)] dark:bg-[var(--surface-transparent-dark)] border-[1px] border-[var(--stroke-light)] dark:border-[var(--stroke-light-dark)] flex flex-row items-center space-x-4 px-6 py-4'>
            {type ? (
                <div className='bg-surface-1 dark:bg-surface-1-dark p-2 rounded-full'>
                    <Icon
                        name={STATISTIC_TYPE_ICON_MAPPING[`${type ?? 'sustainability'}${isDark ? 'Dark' : ''}`]}
                        color='text-brand-secondary'
                    />
                </div>
            ) : (
                <div className='h-[40px] w-[40px]'></div>
            )}
            <div className='flex flex-col items-start justify-between'>
                <div className='flex flex-row items-center space-x-2'>
                    {amount && unit ? (
                        <p
                            className='font-[600] text-[16px] leading-5 align-text-bottom text-[var(--text-primary)] dark:text-[var(--text-invert)]'
                            style={{ ...style }}
                        >
                            {amount}
                        </p>
                    ) : (
                        <Skeleton width={64} height={16} />
                    )}
                    {amount && unit && (
                        <p className='font-[500] text-[16px] leading-5 align-text-bottom text-[var(--grey)]'>{unit}</p>
                    )}
                    {percentage ? <StatisticLabel value={percentage} /> : <></>}
                </div>
                {text ? <Text type='body1'>{text}</Text> : <Skeleton width={72} height={18} />}
            </div>
        </div>
    )
}

import type { IdToken } from '@auth0/auth0-react'
import type { IAuthUser } from '@demia/platform'
import { Copyable, Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { ProfileAvatar, ProfileTabMenu } from '@components/app'
import { Link, LinkTarget, Pane, Skeleton } from '@components/base'
import type { IUserIdentity, IUserProfileData } from '@lib/user'
import { getInitialsOfUser } from '@lib/user'

interface IProfileTabProps {
    profile?: IUserIdentity
    auth?: IAuthUser
    token?: IdToken
}

export const ProfileTab: FunctionComponent<IProfileTabProps> = (props) => {
    const { profile, auth } = props
    const [profileData, setProfileData] = useState<IUserProfileData | undefined>(undefined)

    function updateProfileData(payload?: IUserProfileData) {
        const data = payload
            ? {
                  given_name: payload.given_name ?? '',
                  family_name: payload.family_name ?? '',
                  email: payload.email ?? '',
                  address: payload.address ?? '',
                  organization: payload.organization ?? '',
                  locale: payload.locale ?? '',
                  website: payload.website ?? '',
              }
            : undefined

        setProfileData(data)
    }

    useEffect(() => {
        updateProfileData(auth)
    }, [])

    return (
        <Pane>
            <div className='flex flex-col space-y-6'>
                <div className={'flex flex-row justify-between items-center'}>
                    <Text type='header3'>General</Text>
                    <ProfileTabMenu
                        profileData={profileData as IUserProfileData}
                        onProfileUpdated={updateProfileData}
                    />
                </div>
                <div className='flex flex-col space-y-4'>
                    <div className='flex flex-col space-y-4'>
                        <Text type='body1' color='text-secondary'>
                            Picture
                        </Text>
                        <div className='flex flex-col items-start space-y-4'>
                            {auth ? (
                                <ProfileAvatar size='large' initials={getInitialsOfUser(auth as IAuthUser)} />
                            ) : (
                                <Skeleton height={96} width={96} />
                            )}
                        </div>
                    </div>
                </div>
                <div className='flex flex-col space-y-4'>
                    <div>
                        <Text type='body1' color='text-secondary'>
                            Username
                        </Text>
                        <Text type='body1'>{profile ? profile?.user.username : 'Not Provided'}</Text>
                    </div>
                    <div>
                        <Text type='body1' color='text-secondary'>
                            User ID
                        </Text>
                        {profile?.user?.keycloak_id ? (
                            <Copyable value={profile?.user?.keycloak_id ?? ''} />
                        ) : (
                            <Skeleton />
                        )}
                    </div>
                    <div>
                        <Text type='body1' color='text-secondary'>
                            First Name
                        </Text>
                        <Text type='body1'>{profileData?.given_name ? profileData.given_name : 'Not Provided'}</Text>
                    </div>
                    <div>
                        <Text type='body1' color='text-secondary'>
                            Last Name
                        </Text>
                        <Text type='body1'>{profileData?.family_name ? profileData.family_name : 'Not Provided'}</Text>
                    </div>
                    <div>
                        <Text type='body1' color='text-secondary'>
                            Organization
                        </Text>
                        <Text type='body1'>
                            {profileData?.organization ? profileData.organization : 'Not Provided'}
                        </Text>
                    </div>
                    <div>
                        <Text type='body1' color='text-secondary'>
                            Website
                        </Text>
                        {profileData?.website ? (
                            <Link url={profileData.website} target={LinkTarget.Blank}>
                                {profileData.website}
                            </Link>
                        ) : (
                            <Text type='body1'>Not Provided</Text>
                        )}
                    </div>
                    <div>
                        <Text type='body1' color='text-secondary'>
                            Email
                        </Text>
                        <Text type='body1'>{profileData?.email ? profileData.email : 'Not Provided'}</Text>
                    </div>
                    <div>
                        <Text type='body1' color='text-secondary'>
                            Locale
                        </Text>
                        <Text type='body1'>{profileData?.locale ? profileData.locale : 'Not Provided'}</Text>
                    </div>
                </div>
            </div>
        </Pane>
    )
}

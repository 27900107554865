import { dateToReadable, EMPTY_DATE_STRING, sizeToReadable } from '@demia/core'
import { Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import type { ITableRowProps } from '@components/base/Table'
import { TableRow } from '@components/base/Table'
import type { IProjectDocument } from '@lib/project'

interface IProjectDocumentationTableRowProps {
    hideEntity?: boolean
}

export const ProjectDocumentationTableRow: FunctionComponent<
    IProjectDocumentationTableRowProps & ITableRowProps<IProjectDocument>
> = (props) => {
    const { id, hideEntity, data } = props ?? {}
    const { name, owner, metadata, lastModified } = data?.file ?? {}
    const entries = [
        name,
        ...(hideEntity ? [] : [owner]),
        metadata?.type ?? '',
        metadata?.size ? sizeToReadable(metadata.size) : '0',
        lastModified ? dateToReadable(lastModified) : EMPTY_DATE_STRING,
    ]

    return (
        <TableRow id={id}>
            {entries.map((entry) => (
                <Text type='body1'>{entry?.toString()}</Text>
            ))}
        </TableRow>
    )
}

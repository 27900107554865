import { ErrorHandler } from '@demia/core'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { ProjectActivityTable, ProjectLayout } from '@components/app'
import type { IProjectNotification } from '@lib/project'
import { ProjectApiService } from '@lib/project'

export const ProjectActivityPage: FunctionComponent = () => {
    const [activities, setActivities] = useState<IProjectNotification[]>([])
    const [loading, setLoading] = useState(true)

    async function loadActivities(): Promise<void> {
        const [data, error] = await ProjectApiService.getNotifications()
        if (error) {
            ErrorHandler.handleApiError(error)
        } else {
            const activities = data.notifications.map((item) => {
                return {
                    user: item.user,
                    timestamp: new Date(item.timestamp),
                    message: item.message,
                    notification_type: item.notification_type,
                }
            })
            setActivities(activities)
        }
        setLoading(false)
    }

    useEffect(() => {
        void loadActivities()
    }, [])

    return (
        <ProjectLayout title='Activity'>
            <ProjectActivityTable loading={loading} activities={activities} />
        </ProjectLayout>
    )
}

import { ErrorHandler } from '@demia/core'
import { NotificationSystem } from '@demia/platform'
import type { FunctionComponent, MouseEvent } from 'react'
import { useRef, useState } from 'react'
import { Button, Dropzone, Popup, usePopup } from '@components/base'
import { UserApiService, validateImageFile } from '@lib/user'

export const ProfilePictureUploadPopup: FunctionComponent = () => {
    const { closePopup } = usePopup()

    const openerRef = useRef<HTMLButtonElement>(null)

    const [hasSelected, setHasSelected] = useState(false)
    const [isUploading, setIsUploading] = useState(false)

    async function onSelectFile(file: File): Promise<void> {
        setHasSelected(true)
        setIsUploading(true)
        const [_, uploadError] = await UserApiService.uploadProfilePicture(file)
        setIsUploading(false)
        if (uploadError) {
            ErrorHandler.handleApiError(uploadError)
        } else {
            NotificationSystem.push({
                type: 'success',
                message: `Updated profile picture`,
            })
            closePopup()
        }
    }

    function onCancelClick(e?: MouseEvent<HTMLButtonElement>): void {
        e?.stopPropagation && e?.stopPropagation()
        closePopup()
    }

    return (
        <Popup title='Update profile picture' onClose={onCancelClick}>
            <Dropzone
                openerRef={openerRef}
                onSelect={(file) => onSelectFile(file as File)}
                onError={() => setHasSelected(false)}
                validate={(payload: File | File[]) => validateImageFile(payload as File)}
            />
            <div className='flex flex-row items-center justify-center space-x-4'>
                <Button variant='text' width='full' size='medium' onClick={onCancelClick} disabled={isUploading}>
                    Cancel
                </Button>
                <Button
                    buttonRef={openerRef}
                    variant='primary'
                    width='full'
                    size='medium'
                    onClick={() => {}}
                    busy={isUploading}
                    busyText='Uploading…'
                >
                    {hasSelected ? 'Upload' : 'Browse'}
                </Button>
            </div>
        </Popup>
    )
}

import { ErrorHandler } from '@demia/core'
import { NotificationSystem } from '@demia/platform'
import type { FunctionComponent, MouseEvent } from 'react'
import { useRef, useState } from 'react'
import { Dropzone, Popup, usePopup } from '@components/base'
import { Button } from '@components/base/Button'
import type { IProjectDocument } from '@lib/project'
import { ProjectApiService, ProjectDocumentType } from '@lib/project'

interface ModalProps {
    siteId: string
    uploadType: ProjectDocumentType
    fileName?: string
    onClose?: (newDocuments: IProjectDocument[]) => void
}

export const ProjectDocumentationPopup: FunctionComponent<ModalProps> = (props) => {
    const { siteId, onClose, uploadType, fileName } = props
    const { closePopup } = usePopup()

    const openerRef = useRef<HTMLButtonElement>(null)

    const [hasSelected, setHasSelected] = useState(false)
    const [isUploading, setIsUploading] = useState(false)

    async function onSelectFiles(files: File[]): Promise<void> {
        // If a fileName is provided, rename the file to that name.
        if (files.length === 1 && fileName) {
            files[0] = new File([files[0]], fileName, {
                type: files[0].type,
            })
        }

        setHasSelected(true)
        setIsUploading(true)
        const [data, uploadError] = await ProjectApiService.uploadFile(
            siteId,
            files,
            uploadType == ProjectDocumentType.Asset
        )
        setIsUploading(false)
        if (uploadError) {
            ErrorHandler.handleApiError(uploadError)
        } else {
            const documents: IProjectDocument[] = data.files.map((file) => {
                return {
                    file: {
                        ...file,
                        lastModified: new Date(file.lastModified),
                    },
                }
            })
            NotificationSystem.push({
                type: 'success',
                message: `Uploaded ${documents.length} ${documents.length === 1 ? 'file' : 'files'}`,
            })
            onClose && onClose(documents)
            closePopup()
        }
    }

    function onCancelClick(e?: MouseEvent<HTMLButtonElement>): void {
        e?.stopPropagation && e?.stopPropagation()
        onClose && onClose([])
        closePopup()
    }

    return (
        <Popup title='Upload documentation' onClose={onCancelClick}>
            <Dropzone
                isMultiFile
                openerRef={openerRef}
                onSelect={(files) => onSelectFiles(files as File[])}
                onError={() => setHasSelected(false)}
            />
            <div className='flex flex-row items-center justify-center space-x-4'>
                <Button variant='text' width='full' size='medium' onClick={onCancelClick} disabled={isUploading}>
                    Cancel
                </Button>
                <Button
                    buttonRef={openerRef}
                    variant='primary'
                    width='full'
                    size='medium'
                    onClick={() => {}}
                    busy={isUploading}
                    busyText='Uploading…'
                >
                    {hasSelected ? 'Upload' : 'Browse'}
                </Button>
            </div>
        </Popup>
    )
}

import type { Result } from '@demia/core'
import { truncateString } from '@demia/core'
import { Icon, StyleContext, Text } from '@demia/ui-kit'
import type { FunctionComponent, RefObject } from 'react'
import { useContext, useEffect, useState } from 'react'
import type { DropEvent, FileRejection } from 'react-dropzone'
import { useDropzone } from 'react-dropzone'

interface IDropzoneProps {
    isMultiFile?: boolean
    maxFiles?: number
    openerRef?: RefObject<HTMLElement>
    onSelect: (payload: File | File[]) => void | Promise<void>
    onError?: (error: Error) => void
    validate?: (payload: File | File[]) => Result<boolean>
}

const MAXIMUM_NUMBER_OF_FILES: number = 10

export const Dropzone: FunctionComponent<IDropzoneProps> = (props) => {
    const { isMultiFile, maxFiles = MAXIMUM_NUMBER_OF_FILES, openerRef, onSelect, onError, validate } = props

    const { isDark } = useContext(StyleContext).data

    const [payload, setPayload] = useState<(File | File[]) | null>(null)
    const [error, setError] = useState<string>('')

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop: async (files: File[]) => onPayloadDrop(isMultiFile ? files : files[0]),
        disabled: Boolean(payload),
        maxFiles: isMultiFile ? maxFiles : 1,
        onDropRejected: onRejection,
    })

    async function onPayloadDrop(payload: File | File[]): Promise<void> {
        if (validate) {
            const [_, error] = validate(payload)
            if (error) {
                setError(error.message)
                return
            }
        }
        setPayload(payload)
        onSelect(payload)
    }

    function onRejection(rejections: FileRejection[], _: DropEvent): void {
        const message = rejections[0].errors[0].message
        setError(message)
        onError && onError(new Error(message))
        setPayload(null)
    }

    useEffect(() => {
        openerRef?.current?.addEventListener('click', () => {
            setError('')
            open()
        })
        return () => openerRef?.current?.removeEventListener('click', open)
    }, [])

    return (
        <div
            {...getRootProps()}
            className='flex flex-col items-center justify-between space-y-4 border-[1px] border-dashed border-[var(--grey)] py-10'
        >
            <div className='mb-3'>
                <Icon name='upload' size='lg' color={isDark ? 'text-brand-primary-dark' : 'text-brand-primary'} />
            </div>
            <input {...getInputProps()} />
            <div className='flex flex-col items-center space-y-4'>
                <Text type='subtitle2' color={isDark ? 'text-secondary' : 'grey'}>
                    Drag and Drop Files Here
                </Text>
                {error && (
                    <Text type='subtitle2' color='text-danger'>
                        {error}
                    </Text>
                )}
            </div>
            {payload &&
                (isMultiFile ? (
                    <ul className='flex flex-col space-y-2'>
                        {(payload as File[]).map((file: File) => (
                            <li key={file.name}>
                                <Text type='body1' color={isDark ? 'text-secondary' : 'grey'}>
                                    {(payload as File[]).length > 1 ? '• ' : ''}
                                    {truncateString(file.name, 64)}
                                </Text>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <Text type='body1' color={isDark ? 'text-secondary' : 'grey'}>
                        {(payload as File).name}
                    </Text>
                ))}
        </div>
    )
}

import type { IErrorReporter } from '@demia/core'
import type { ErrorEvent } from '@sentry/react'
import * as Sentry from '@sentry/react'
import { config } from '@constants'

Sentry.init({
    dsn: config.SENTRY_DSN,
    /**
     * NOTE: BUILD_MODE is a constant defined in the Vite config file,
     * which is statically replaced at build time.
     */
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    environment: BUILD_MODE ?? 'development',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [config.API_BASE_URL],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    sendDefaultPii: false,

    /**
     * WARNING: We MUST sanitize personally identifiable information (PII)
     * from anything we send to Sentry, so that we can be GDPR-compliant.
     */
    beforeSend(event: ErrorEvent) {
        if (event.user) {
            delete event.user.email
            delete event.user.ip_address
            delete event.user.username
        }

        return event
    },
})

export const sentryReporter: IErrorReporter = {
    reportError(error: Error | string | unknown) {
        Sentry.captureException(error)
    },
}

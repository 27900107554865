import { ErrorHandler, isObjectEmpty } from '@demia/core'
import { Text } from '@demia/ui-kit'
import type { FunctionComponent, FormEvent } from 'react'
import { useEffect, useState } from 'react'
import { Button, Pane, PasswordInput, Skeleton, TextInput } from '@components/base'
import type { IHederaProfile } from '@lib/hedera'
import { HederaApiService } from '@lib/hedera'

interface IHederaTabProps {
    hederaProfile?: IHederaProfile
}

export const HederaTab: FunctionComponent<IHederaTabProps> = (props) => {
    const { hederaProfile } = props

    const [isLoading, setIsLoading] = useState(true)
    const [_, setHederaLink] = useState<IHederaProfile | null>(null)
    const [hederaDidLinking, setHederaDidLinking] = useState<boolean>(false)

    async function fetchHederaLink(event: FormEvent<HTMLFormElement>) {
        if (hederaDidLinking) {
            return
        } else {
            setHederaDidLinking(true)
            event.preventDefault()

            const form = event.target as typeof event.target & {
                username: { value: string }
                password: { value: string }
            }

            const [data, error] = await HederaApiService.getLink(form.username.value, form.password.value)
            if (error) {
                ErrorHandler.handleApiError(error)
            } else {
                setHederaLink(data)
            }
        }
    }

    useEffect(() => {
        if (hederaProfile) {
            setIsLoading(false)
        } else {
            setIsLoading(true)
        }
    }, [hederaProfile])

    return (
        <Pane>
            {isLoading ? (
                <Skeleton height={196} />
            ) : isObjectEmpty(hederaProfile) ? (
                <form onSubmit={fetchHederaLink} className='flex flex-col space-y-4'>
                    <TextInput id='username' label='Username' required autofocus />
                    <PasswordInput id='password' label='Password' required />
                    <div className='pt-4'>
                        <Button type='submit' width='full' onClick={() => {}}>
                            {hederaDidLinking ? 'Linking…' : 'Link DID'}
                        </Button>
                    </div>
                </form>
            ) : (
                <div className='flex flex-col space-y-6'>
                    <div className='flex flex-col space-y-2'>
                        <Text type='header3'>Hedera Information</Text>
                        <div className='flex flex-col space-y-4'>
                            <div>
                                <Text type='body1' color='text-secondary'>
                                    Username
                                </Text>
                                <p onClick={() => navigator.clipboard.writeText(hederaProfile!.username)}>
                                    {hederaProfile?.username || <Skeleton />}
                                </p>
                            </div>
                            <div>
                                <Text type='body1' color='text-secondary'>
                                    Account ID:
                                </Text>
                                <p onClick={() => navigator.clipboard.writeText(hederaProfile!.hederaAccountId)}>
                                    {hederaProfile?.hederaAccountId || <Skeleton />}
                                </p>
                            </div>
                            <div>
                                <Text type='body1' color='text-secondary'>
                                    DID:
                                </Text>
                                <p onClick={() => navigator.clipboard.writeText(hederaProfile!.did)}>
                                    {hederaProfile?.did || <Skeleton />}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Pane>
    )
}

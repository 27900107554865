import { useAuth0 } from '@auth0/auth0-react'
import { ErrorHandler } from '@demia/core'
import { AuthApiService } from '@demia/platform'
import type { FunctionComponent } from 'react'
import { useEffect, useMemo, useState } from 'react'
import type { INavigationMenuItemProps } from '@components/base'
import { NavigationMenu } from '@components/base'
import { ProjectApiCache } from '@lib/project'
import { AppRoute, buildRoute } from '@lib/routing'
import { getInitialsOfUser, logoutUser } from '@lib/user'
import { ProfileAvatar } from './ProfileAvatar.tsx'

export const ProfileMenu: FunctionComponent = () => {
    const { isAuthenticated, logout, user } = useAuth0()
    const [initials, setInitials] = useState<string>('')
    const [isSigningOut, setIsSigningOut] = useState(false)

    async function onSignOutClick(): Promise<void> {
        setIsSigningOut(true)
        const [_, error] = await AuthApiService.logout()
        if (error) {
            ErrorHandler.handleApiError(error)
        } else {
            try {
                await logoutUser(async () => await logout({ logoutParams: { returnTo: window.location.origin } }))
                ProjectApiCache.clearProjects()
            } catch (err) {
                setIsSigningOut(false)
                /**
                 * NOTE: The error is being thrown again so that it is handled within the
                 * NavigationMenu component.
                 */
                throw err
            }
        }
    }

    useEffect(() => {
        if (isAuthenticated && user && user.name) {
            setInitials(getInitialsOfUser(user))
        }
    })

    const menuItemGroups = useMemo<INavigationMenuItemProps[][]>(
        () => [
            [
                {
                    url: buildRoute(AppRoute.Profile),
                    text: 'Profile',
                    icon: 'user',
                    onClick: () => {},
                },
            ],
            [
                {
                    /**
                     * NOTE: We set the URL to a hashtag so that it is effectively ignored
                     * by the underlying navigation link component (and we can perform logic
                     * in onClick before doing the navigation ourselves).
                     */
                    url: '#',
                    text: 'Sign Out',
                    icon: 'sign-out',
                    isBusy: isSigningOut,
                    busyText: 'Signing out…',
                    onClick: () => void onSignOutClick(),
                },
            ],
        ],
        [isSigningOut]
    )

    return (
        <NavigationMenu
            AnchorComponent={() => ProfileAvatar({ initials })}
            itemGroups={menuItemGroups}
            margins={[-116, 40]}
        />
    )
}

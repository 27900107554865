import type { Result, IHttpClient, IFile } from '@demia/core'
import { HttpClient } from '@demia/core'
import type { IUserApiService, IUserIdentity, IUserMetadata, IUserProfileData } from './user.types'

class UserApiServiceImpl implements IUserApiService {
    private static instance: UserApiServiceImpl
    private readonly http: IHttpClient

    private constructor(http: IHttpClient) {
        this.http = http
    }

    static getInstance(http: IHttpClient): IUserApiService {
        if (!UserApiServiceImpl.instance) {
            UserApiServiceImpl.instance = new UserApiServiceImpl(http)
        }
        return UserApiServiceImpl.instance
    }

    getProfile() {
        return this.getIdentity()
    }

    updateProfile(data: IUserProfileData): Promise<Result<void>> {
        return this.http.put<void>('/api/user/profile', data)
    }

    getMetadata() {
        return this.http.get<IUserMetadata>('/api/user/metadata')
    }

    createIdentity() {
        return this.http.post<string>('/api/user/identity/create', {})
    }

    getIdentity() {
        return this.http.get<IUserIdentity>('/api/user/identity')
    }

    deleteIdentity() {
        return this.http.post<string>('/api/user/identity/delete', {})
    }

    uploadProfilePicture(file: File) {
        const formData = new FormData()
        formData.append('file', file)
        return this.http.post<IFile>('/api/user/profile_pic', formData)
    }
}

export const UserApiService = UserApiServiceImpl.getInstance(HttpClient)

import type { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import { SiteSidebarNavigationMenu } from '@components/app/Project/SiteSidebarNavigationMenu'
import { Sidebar } from '@components/base'
import { AppRoute, buildRoute } from '@lib/routing'
import { ProfileSidebarNavigation } from './ProfileSidebarNavigation.tsx'

export const ProfileSidebar: FunctionComponent = () => {
    const navigate = useNavigate()
    return (
        <Sidebar onLogoClick={() => navigate(buildRoute(AppRoute.Overview))} MenuComponent={SiteSidebarNavigationMenu}>
            <ProfileSidebarNavigation />
        </Sidebar>
    )
}

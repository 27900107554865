import type { Style, TextType } from '@demia/ui-kit'
import { Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useContext } from 'react'
import { UserContext } from '@lib/user'

interface IProfileAvatarProps {
    initials: string
    backgroundColor?: string
    textColor?: string
    size?: AvatarSize
    onClick?: () => void
}

const avatarSizes = ['large', 'medium', 'small'] as const
type AvatarSize = (typeof avatarSizes)[number]

const AVATAR_SIZE_DIMENSION_MAPPING: Record<AvatarSize, string> = {
    large: 'min-w-[72px] min-h-[72px] max-w-[72px] max-h-[72px]',
    medium: 'min-w-[40px] min-h-[40px] max-w-[40px] max-h-[40px]',
    small: 'min-w-[32px] min-h-[32px] max-w-[32px] max-h-[32px]',
}

const AVATAR_SIZE_FONT_MAPPING: Record<AvatarSize, TextType> = {
    large: 'header4',
    medium: 'subtitle2',
    small: 'subtitle2',
}

const PUBLIC_BUCKET: string = 'https://demia-public.s3.us-east-1.amazonaws.com'

export const ProfileAvatar: FunctionComponent<IProfileAvatarProps> = (props) => {
    const { initials, backgroundColor, textColor, size = 'medium', onClick } = props
    const backgroundColorClass = backgroundColor ? `bg-[var(--${backgroundColor})]` : 'bg-[var(--black)]'

    const picturePath = useContext(UserContext)?.data?.metadata?.profilePicture ?? ''
    const style: Style = {
        ...(picturePath && {
            /**
             * NOTE: The Date.now() query parameter is for making sure this component reads the most
             * updated image if it was updated.
             */
            backgroundImage: `url(${PUBLIC_BUCKET}/${picturePath}?${Date.now()})`,
            backgroundSize: 'contain',
        }),
    }

    return (
        <div
            className={`${AVATAR_SIZE_DIMENSION_MAPPING[size]} ${backgroundColorClass} rounded-full flex flex-col items-center justify-center ${onClick && 'cursor-pointer'}`}
            onClick={onClick ?? undefined}
            style={style}
        >
            {!picturePath && (
                <Text type={AVATAR_SIZE_FONT_MAPPING[size]} color={textColor ?? 'text-invert'} freezeColor>
                    {initials}
                </Text>
            )}
        </div>
    )
}

import type { IdToken } from '@auth0/auth0-react'
import { useAuth0 } from '@auth0/auth0-react'
import { ErrorHandler } from '@demia/core'
import type { IStripeSubscription } from '@demia/platform'
import { StripeApiService } from '@demia/platform'
import { useContext, useEffect, useState } from 'react'
import { ProfileLayout } from '@components/app'
import { Tabs } from '@components/base'
import { config } from '@constants'
import { FEATURES } from '@features'
import type { IHederaProfile } from '@lib/hedera'
import { HederaApiService } from '@lib/hedera'
import type { IProjectSites } from '@lib/project'
import type { IUserIdentity } from '@lib/user'
import { UserApiService, UserContext } from '@lib/user'
import { HederaTab, IdentityTab, ProfileTab, SubscriptionTab } from './components'
import classes from './ProfilePage.module.scss'

export const ProfilePage = () => {
    const { user, isAuthenticated, getIdTokenClaims, isLoading } = useAuth0()

    const userData = useContext(UserContext).data

    const [profile, setProfile] = useState<IUserIdentity>()
    const [subscription, setSubscription] = useState<Record<string, IStripeSubscription> | null>(null)

    const [hederaProfile, setHederaProfile] = useState<IHederaProfile | null>(null)

    const [authToken, setAuthToken] = useState<IdToken | null>(null)
    const [customerId, setCustomerId] = useState<string | null>(null)

    async function loadHederaProfile(): Promise<void> {
        const [data, error] = await HederaApiService.getProfile()
        if (error) {
            setHederaProfile({} as IHederaProfile)
            const isAccountNotLinkedError = error.message.includes('account not linked')
            ErrorHandler.handleApiError(error, { notifyUser: !isAccountNotLinkedError })
        } else {
            setHederaProfile(data)
        }
    }

    async function loadProfile(): Promise<void> {
        const [data, error] = await UserApiService.getProfile()
        if (error) {
            ErrorHandler.handleApiError(error)
        } else {
            setProfile(data)
            if (userData && user && isAuthenticated) {
                getIdTokenClaims().then((token) => {
                    if (token) {
                        setAuthToken(token)
                        setCustomerId(token.stripeCustomerId)
                        if (!config.DEMO_MODE) {
                            void loadSubscriptions(token.stripeCustomerId, { sites: userData.sites })
                        }
                    }
                })
            }
        }
    }

    async function loadSubscriptions(customerId: string, sites: IProjectSites): Promise<void> {
        const [data, error] = await StripeApiService.getSubscription(customerId)
        if (error) {
            setSubscription({})
            ErrorHandler.handleApiError(error)
        } else {
            if (data && data.length > 0) {
                data.map((sub: IStripeSubscription) => {
                    sites.sites.map((site) => {
                        const projectId = site.projectId
                        if (sub.metadata['project_id'] === projectId) {
                            const newSubscription = subscription ? { ...subscription } : {}
                            newSubscription[projectId] = sub
                            setSubscription(newSubscription)
                        }
                    })
                })
            } else {
                setSubscription({})
            }
        }
    }

    useEffect(() => {
        if (!isLoading) {
            if (FEATURES.project.guardian.enabled) {
                /**
                 * CAUTION: Do NOT await this asynchronous call.
                 */
                void loadHederaProfile()
            }

            void loadProfile()
        }
    }, [isLoading])

    const tabs = [
        'Profile',
        'Identity',
        ...(config.DEMO_MODE ? [] : ['Subscription']),
        ...(FEATURES.project.guardian.enabled ? ['Hedera'] : []),
    ]

    return (
        <ProfileLayout title='Profile'>
            <div className={classes.profile}>
                <div className='flex flex-col space-y-8'>
                    <Tabs tabHeaders={tabs}>
                        <ProfileTab profile={profile} auth={user} token={authToken as IdToken} />
                        <IdentityTab profile={profile} />
                        {!config.DEMO_MODE && (
                            <SubscriptionTab
                                auth={user}
                                sites={{ sites: userData.sites }}
                                customerId={customerId as string}
                                subscriptionMap={subscription as Record<string, IStripeSubscription>}
                            />
                        )}
                        {FEATURES.project.guardian.enabled && (
                            <HederaTab hederaProfile={hederaProfile as IHederaProfile} />
                        )}
                    </Tabs>
                </div>
            </div>
        </ProfileLayout>
    )
}

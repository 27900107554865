import type { IconName } from '@demia/ui-kit'
import { Icon, StyleContext, Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import classes from './SidebarTab.module.scss'

interface ISidebarNavigationLinkProps {
    url: string
    icon: IconName
    text: string
    isActive?: boolean
}

export const SidebarTab: FunctionComponent<ISidebarNavigationLinkProps> = (props) => {
    const { url, icon, text, isActive } = props
    const { theme, isDark } = useContext(StyleContext).data

    const [isHovering, setIsHovering] = useState(false)

    return (
        <NavLink
            to={url}
            end
            className={({ isActive: _isActive }) =>
                `${classes[theme + '-sidebar-tab']} ${isActive || _isActive ? classes['active'] : ''}`
            }
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <Icon
                name={icon}
                color={`${isActive || isHovering ? (isDark ? 'text-brand-primary-dark' : 'text-brand-primary') : 'text-secondary'}`}
            />
            <Text type='subtitle2'>{text}</Text>
        </NavLink>
    )
}

import type {
    ProjectAnalyticsRoute,
    ProjectDataSourcesRoute,
    ProjectGuardianRoute,
    ProjectRoute,
} from './routing.enums.ts'
import { AppRoute } from './routing.enums.ts'
import type { RouteHierarchy } from './routing.types.ts'

export function buildRoute(...routes: RouteHierarchy): string {
    return `/${(routes ?? []).join('/')}`
}

export function buildProjectRoute(
    projectRoute: ProjectRoute,
    subRoute?: ProjectAnalyticsRoute | ProjectDataSourcesRoute | ProjectGuardianRoute
): string {
    return subRoute ? buildRoute(AppRoute.Project, projectRoute, subRoute) : buildRoute(AppRoute.Project, projectRoute)
}

import { ErrorHandler } from '@demia/core'
import type { FormEvent } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ProjectLayout } from '@components/app'
import { Button, NumberInput, Pane, TextInput } from '@components/base'
import { ProjectApiService } from '@lib/project'
import type { IDataSourceEquipment } from '@lib/sensor'
import classes from './AddProjectDataSourcePage.module.scss'

export const AddProjectDataSourcePage = () => {
    const { slug } = useParams()

    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)

    async function addSensorHandler(event: FormEvent<HTMLFormElement>): Promise<void> {
        if (loading) {
            return
        }
        event.preventDefault()
        setLoading(true)

        const target = event.target as typeof event.target & {
            sensorId: { value: string }
            group: { value: string }
            units: { value: string }
            eqType: { value: string }
            name: { value: string }
            accuracy: { value: string }
            installed: { value: string }
            serialNo: { value: string }
            manufacturer: { value: string }
        }

        const newSite: IDataSourceEquipment = {
            id: target.sensorId.value,
            group: target.group.value,
            units: target.units.value,
            eqType: target.eqType.value,
            name: target.name.value,
            accuracy: parseFloat(target.accuracy.value),
            installed: parseFloat(target.installed.value),
            serialNo: target.serialNo.value,
            manufacturer: target.manufacturer.value,
        }

        const [_, error] = await ProjectApiService.addSensor(slug!, newSite)
        if (error) {
            ErrorHandler.handleApiError(error)
        } else {
            navigate(`/projects/${slug}/data-sources`)
        }
        setLoading(false)
    }

    return (
        <ProjectLayout title={`Add Sensor to ${slug}`}>
            <div className={classes.addSource}>
                <Pane>
                    <form onSubmit={(event) => void addSensorHandler(event)} className='flex flex-col space-y-4'>
                        <TextInput id='sensorId' label='Sensor ID' required autofocus />
                        <TextInput id='group' label='Group' required />
                        <TextInput id='units' label='Units' required />
                        <TextInput id='eqType' label='EQ Type' required />
                        <TextInput id='name' label='Name' required />
                        <NumberInput id='accuracy' label='Accuracy' required />
                        <NumberInput id='installed' label='Installed' required />
                        <TextInput id='serialNo' label='Serial No.' required />
                        <TextInput id='manufacturer' label='Manufacturer' required />
                        <div className='pt-4'>
                            <Button type='submit' width='full' onClick={() => {}}>
                                {loading ? 'Adding…' : 'Add sensor'}
                            </Button>
                        </div>
                    </form>
                </Pane>
            </div>
        </ProjectLayout>
    )
}

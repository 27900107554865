import type { FunctionComponent } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Sidebar } from '@components/base'
import { AppRoute, buildRoute } from '@lib/routing'
import { ProjectSidebarNavigation } from './ProjectSidebarNavigation'
import { SiteSidebarNavigationMenu } from './SiteSidebarNavigationMenu'

export const ProjectSidebar: FunctionComponent = () => {
    const { slug } = useParams()
    const navigate = useNavigate()
    return (
        <Sidebar onLogoClick={() => navigate(buildRoute(AppRoute.Overview))} MenuComponent={SiteSidebarNavigationMenu}>
            <ProjectSidebarNavigation projectSlug={slug ?? ''} />
        </Sidebar>
    )
}

import { chunkArray } from '@demia/core'
import { Icon, StyleContext, Text } from '@demia/ui-kit'
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ProjectLayout, ProjectStatistic } from '@components/app'
import { Button, DateSelector, IconButton, LineAreaChart, LineChart, Pane } from '@components/base'
import { StorageManager } from '@constants'
import type { IProjectDetails, IProjectStateValueSet, IProjectStateValueSets, IProjectStatistic } from '@lib/project'
import { getDatedTotal, ProjectContext } from '@lib/project'
import { buildProjectRoute, ProjectAnalyticsRoute, ProjectRoute } from '@lib/routing'
import type { DataExportFileType } from '@lib/sensor'
import type { IAnalyticsSettings, IUserSettings } from '@lib/user'
import { DEFAULT_NUMBER_OF_ANALYTICS_CHARTS_PER_ROW, getAnalyticsSettingsForUser, UserContext } from '@lib/user'
import classes from './ProjectAnalyticsPage.module.scss'

const statisticsData = [
    {
        type: 'sustainability',
        amount: '',
        unit: '',
        text: 'Annual GHG emission reductions',
    },
    {
        type: 'sustainability',
        amount: '',
        unit: '',
        text: 'GHG emission reductions last 30 day',
    },
    {
        type: 'chart',
        amount: '',
        unit: '%',
        text: 'Data Confidence',
    },
]

const MAXIMUM_NUMBER_OF_CHARTS_PER_ROW: number = 3

export const ProjectAnalyticsPage = () => {
    const { slug } = useParams()
    const url = buildProjectRoute(ProjectRoute.Analytics, ProjectAnalyticsRoute.Setup).replace(':slug', slug ?? '')
    const navigate = useNavigate()

    function onSettingsClick() {
        navigate(url)
    }

    const isDark = useContext(StyleContext).data.theme === 'dark'
    const { data: userData, setData: setUserData } = useContext(UserContext)
    const { data, setData } = useContext(ProjectContext)
    const { details, dateSelection } = data
    const { startDate, endDate } = dateSelection

    const [isVisible, setIsVisible] = useState(false)
    const [analytics, setAnalytics] = useState<IProjectStateValueSets | null>(null)
    const [firstChart, setFirstChart] = useState<IProjectStateValueSet | null>(null)

    const defaultFirstChartId = 'totalGhgEmissions'

    const [statistics, setStatistics] = useState<IProjectStatistic[]>([
        { ...statisticsData[0] },
        { ...statisticsData[1] },
        { ...statisticsData[2] },
    ])
    const [project, setProject] = useState<IProjectDetails | null>(null)

    const [analyticsSettings, setAnalyticsSettings] = useState<IAnalyticsSettings>({
        chartsPerRow: DEFAULT_NUMBER_OF_ANALYTICS_CHARTS_PER_ROW,
    })

    useEffect(() => {
        const _analyticsSettings = getAnalyticsSettingsForUser(userData?.user?.['id'], slug ?? '')
        if (_analyticsSettings) {
            setAnalyticsSettings(_analyticsSettings)
        } else {
            saveAnalyticsSettings(analyticsSettings)
        }
    }, [])

    function saveAnalyticsSettings(settings: IAnalyticsSettings): void {
        const userSettings = {
            ...(userData?.settings ?? {}),
            sites: {
                ...(userData?.settings?.sites ?? {}),
                [slug ?? '']: {
                    ...(userData?.settings?.sites?.[slug ?? ''] ?? {}),
                    analytics: settings,
                },
            },
        } as IUserSettings
        const persistedUserStore = StorageManager.load('userData') ?? {}
        StorageManager.save('userData', {
            ...persistedUserStore,
            [userData?.user?.['id'] ?? '']: {
                ...(persistedUserStore[userData?.user?.['id'] ?? ''] ?? {}),
                settings: userSettings,
            },
        })
        setUserData({
            ...userData,
            settings: userSettings,
        })
    }

    function onChartsPerRowClick(shouldIncrement: boolean): void {
        const settings: IAnalyticsSettings = {
            chartsPerRow: analyticsSettings.chartsPerRow + (shouldIncrement ? 1 : -1),
        }
        setAnalyticsSettings(settings)
        saveAnalyticsSettings(settings)
    }

    useEffect(() => {
        if (details && details.stateData) {
            if (!project || (project && details !== project)) {
                setProject(details)

                const enabledValueSets = Object.entries(details.stateData.valueSets).filter(
                    ([id]) => data.analyticsConfiguration?.calculations[id]
                )
                if (enabledValueSets.length > 0) {
                    const _firstChart =
                        enabledValueSets.find(([id]) => id === defaultFirstChartId) ?? enabledValueSets[0]
                    setFirstChart(_firstChart[1])

                    if (enabledValueSets.length > 1) {
                        setAnalytics(
                            Object.fromEntries(
                                enabledValueSets
                                    .filter(([id]) => id !== _firstChart[0])
                                    .sort((a, b) => (a[0] < b[0] ? -1 : 1))
                            )
                        )
                    }
                }
            }
        }
    }, [details, slug])

    const parseValue = (value: string) => {
        const parsed = parseFloat(value)
        return parseFloat(parsed.toFixed(0)).toLocaleString()
    }

    useMemo(() => {
        if (project) {
            const ghgAnnual = {
                type: 'sustainability',
                amount: parseValue(project.ghgAnnual.value),
                unit: project.ghgAnnual.unit,
                text: 'Annual GHG emissions reductions (est)',
            }

            const ghg30Day = {
                type: 'sustainability',
                amount: parseValue(project.ghgLast30Days.value),
                unit: project.ghgLast30Days.unit,
                text: 'GHG emissions reductions last 30 days',
            }

            const dcf = {
                type: 'chart',
                amount: project.avgDcf ? project.avgDcf : '0',
                unit: '%',
                text: 'Data Confidence',
            }

            setStatistics([ghgAnnual, ghg30Day, dcf])
        }
    }, [project])

    function onDateChange(startDate: Date, endDate: Date): void {
        setData({
            dateSelection: { startDate, endDate },
        })
    }

    const exportButtonHandler = () => {
        setIsVisible((prev) => !prev)
    }

    function onExportClick(fileType: DataExportFileType): void {
        console.info(`Exporting ${fileType} file`)
    }

    const TitleComponent = () => {
        return (
            <div className='w-full flex flex-row items-center justify-between relative'>
                <Text type='header2'>Analytics</Text>
                <div className='flex flex-row items-center space-x-4'>
                    <Button variant='outline' size='medium' icon='gear' onClick={onSettingsClick}>
                        Settings
                    </Button>
                    <DateSelector startDate={startDate} endDate={endDate} onDateChange={onDateChange} />
                    <Button variant='outline' size='medium' onClick={exportButtonHandler}>
                        <Icon name='list' color={isDark ? 'text-primary-dark' : 'text-primary'} />
                    </Button>
                </div>
                {isVisible && (
                    <div className={`${classes.export} ${isDark ? classes.exportDark : ''}`}>
                        <div className={`${classes.exportWrapper} ${isDark ? classes.exportWrapperDark : ''}`}>
                            <div className='mb-8'>
                                <Text type='header3'>Export</Text>
                            </div>
                            <div className='w-full flex flex-col items-end justify-between space-y-4'>
                                <Button
                                    variant='outline'
                                    width='3/4'
                                    icon='export'
                                    onClick={() => onExportClick('csv')}
                                >
                                    Export to CSV
                                </Button>
                                <Button
                                    variant='outline'
                                    width='3/4'
                                    icon='export'
                                    onClick={() => onExportClick('xlsx')}
                                >
                                    Export to XLSV
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <div className={classes.analytics}>
                <Pane>
                    <LineChart
                        title={{
                            title: firstChart?.title ?? '',
                            total: firstChart ? getDatedTotal(firstChart.data, startDate, endDate) : '0',
                            label: firstChart?.label ?? '',
                            url: firstChart ? defaultFirstChartId : undefined,
                        }}
                        data={
                            firstChart
                                ? firstChart.data.map(([timestamp, val]) => {
                                      return { name: timestamp, uv: val, pv: val, amt: val }
                                  })
                                : []
                        }
                        start={startDate}
                        end={endDate}
                        loading={firstChart === null}
                    />
                    <div className={classes.statisticWrapper}>
                        {statistics.map((data: any, index) => (
                            <Fragment key={index}>
                                <ProjectStatistic size='small' statistic={data} />
                            </Fragment>
                        ))}
                    </div>
                    {analytics && (
                        <div className='flex flex-row items-center justify-between my-6'>
                            <Text type='header4'>Calculations</Text>
                            <div className='flex flex-row items-center justify-around space-x-4'>
                                <IconButton
                                    icon='minus'
                                    disabled={analyticsSettings?.chartsPerRow === 1}
                                    onClick={() => onChartsPerRowClick(false)}
                                />
                                <Text type='header4'>
                                    {analyticsSettings?.chartsPerRow ?? DEFAULT_NUMBER_OF_ANALYTICS_CHARTS_PER_ROW}
                                </Text>
                                <IconButton
                                    icon='plus'
                                    disabled={analyticsSettings?.chartsPerRow === MAXIMUM_NUMBER_OF_CHARTS_PER_ROW}
                                    onClick={() => onChartsPerRowClick(true)}
                                />
                            </div>
                        </div>
                    )}
                    {analytics && (
                        <div className='flex flex-col items-center justify-between space-y-6'>
                            {chunkArray(
                                Object.entries(analytics).filter(([label]) => label !== defaultFirstChartId),
                                analyticsSettings?.chartsPerRow ?? DEFAULT_NUMBER_OF_ANALYTICS_CHARTS_PER_ROW
                            ).map((chunk) => (
                                <div className='w-full flex flex-row items-center justify-between space-x-6'>
                                    {chunk.map(([id, item], index) => (
                                        <div style={{ width: `${(1 / chunk.length) * 100.0}%` }} key={index}>
                                            <LineAreaChart
                                                title={{
                                                    title: item.title,
                                                    total: getDatedTotal(item.data, startDate, endDate),
                                                    label: item.label,
                                                    url: id,
                                                }}
                                                titleTruncation={32}
                                                data={Object.values(item.data).map(([timestamp, val]) => {
                                                    return { name: timestamp, uv: val, pv: val, amt: val }
                                                })}
                                                start={startDate}
                                                end={endDate}
                                                height={200}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    )}
                </Pane>
            </div>
        </ProjectLayout>
    )
}

import { Icon, Text } from '@demia/ui-kit'
import type { ITableHeaderProps } from '../types.ts'

export function TableHeader<T>(props: ITableHeaderProps<T>) {
    const { fields, dataCount, sortConfig, onSortClick, disableSorting, hasGhostCell } = props
    const hasNoData = dataCount === 0

    return (
        <thead className='w-full bg-transparent'>
            <tr className='table-row'>
                {fields.map(({ text, key }, index) => (
                    <th
                        key={index}
                        onClick={() => onSortClick(key as keyof T)}
                        className={`table-cell p-0 pb-6 text-left ${hasNoData ? 'cursor-default pointer-events-none' : disableSorting ? '' : 'cursor-pointer pointer-events-auto'}`}
                    >
                        <div className='flex flex-row items-center space-x-3'>
                            <Text type='subtitle2'>{text}</Text>
                            {!disableSorting ? (
                                sortConfig.key === null ? (
                                    <Icon name='caret-up-down' color='text-secondary' />
                                ) : sortConfig.key === key ? (
                                    sortConfig.direction === 'asc' ? (
                                        <Icon name='caret-up' color='grey' />
                                    ) : (
                                        <Icon name='caret-down' color='grey' />
                                    )
                                ) : (
                                    <Icon name='caret-up-down' color='grey' />
                                )
                            ) : (
                                <></>
                            )}
                        </div>
                    </th>
                ))}
                {hasGhostCell && <th className='table-cell p-0 pb-6 cursor-default pointer-events-none'></th>}
            </tr>
        </thead>
    )
}

import type { INotification } from '@demia/platform'
import type { FunctionComponent } from 'react'
import { Toast } from './Toast.tsx'

interface IToastContainerProps {
    notifications: INotification[]
    onRemove: (id: string) => void
}

export const ToastContainer: FunctionComponent<IToastContainerProps> = (props) => {
    const { notifications, onRemove } = props
    return (
        <div className='fixed top-4 left-1/2 transform -translate-x-1/2 flex flex-col items-center space-y-4 pointer-events-none z-[600]'>
            {notifications.map((notification) => (
                <Toast key={notification.id} {...notification} onClose={() => onRemove(notification.id)} />
            ))}
        </div>
    )
}

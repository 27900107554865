import type { IContext } from '@demia/platform'
import type { IStyleContext } from '@demia/ui-kit'
import { isDarkMode, checkHtmlRootForDarkMode, StyleContext, DEFAULT_STYLE_CONTEXT } from '@demia/ui-kit'
import type { FunctionComponent, ReactNode } from 'react'
import { useState } from 'react'
import { StorageManager } from '@constants'
import type { IAppSettings } from '@lib/app'

interface IAppProviderProps {
    children: ReactNode
}

export const AppProvider: FunctionComponent<IAppProviderProps> = (props) => {
    const { children } = props

    if (!StorageManager.exists('appSettings')) {
        StorageManager.save('appSettings', {
            style: DEFAULT_STYLE_CONTEXT,
        })
    }

    const persistedAppSettings = StorageManager.load('appSettings') as IAppSettings
    /**
     * CAUTION: We are ignoring this line so that we can check for the old "theme" property
     * if the user hasn't used the application since the persisted data was updated. In the future,
     * it is ideal to have a database versioning / migration system that transforms data between
     * versions so that the application doesn't break.
     */
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    const theme = persistedAppSettings['theme']
    if (theme) {
        const _style = { theme, isDark: isDarkMode(theme) }
        persistedAppSettings.style = _style
        StorageManager.save('appSettings', { style: _style })
    }
    const [style, setStyle] = useState<IStyleContext>(persistedAppSettings.style)
    const styleContext: IContext<IStyleContext> = {
        data: style,
        setData: onStyleUpdate,
    }

    checkHtmlRootForDarkMode(style.theme === 'dark')

    function onStyleUpdate(payload: Partial<IStyleContext>): void {
        const _style = {
            ...style,
            ...payload,
            isDark: payload.theme ? isDarkMode(payload.theme) : style.isDark,
        }
        setStyle(_style)
        StorageManager.save('appSettings', { style: _style })
    }

    return <StyleContext.Provider value={styleContext}>{children}</StyleContext.Provider>
}

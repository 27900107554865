import { Copyable, Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { IdentityTable } from '@components/app'
import { Pane, Skeleton } from '@components/base'
import type { IUserIdentity } from '@lib/user'

interface IIdentityTabProps {
    profile?: IUserIdentity
}

export const IdentityTab: FunctionComponent<IIdentityTabProps> = (props) => {
    const { profile } = props
    return (
        <Pane>
            <div className='flex flex-col space-y-6'>
                <div className='flex flex-col space-y-2'>
                    <Text type='header3'>Document Information</Text>
                    <div className='flex flex-col space-y-4'>
                        <div>
                            <Text type='body1' color='text-secondary'>
                                DID Document ID
                            </Text>
                            {profile?.doc_id ? <Copyable value={profile?.doc_id ?? ''} /> : <Skeleton />}
                        </div>
                    </div>
                </div>
                <div className='flex flex-col space-y-2'>
                    <Text type='header3'>Identity Methods</Text>
                    <div className='flex flex-col space-y-4'>
                        <div>
                            <IdentityTable
                                loading={profile === undefined}
                                methods={profile?.methods ?? []}
                                hiddenColumns={['id']}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Pane>
    )
}

import type { IconName } from '@demia/ui-kit'
import { Icon, StyleContext } from '@demia/ui-kit'
import type { FunctionComponent, MouseEvent, RefObject } from 'react'
import { useContext } from 'react'
import classes from './IconButton.module.scss'

interface IIconButtonProps {
    icon: IconName
    onClick: (event?: MouseEvent<HTMLButtonElement>) => void
    disabled?: boolean
    buttonRef?: RefObject<HTMLButtonElement>
}

export const IconButton: FunctionComponent<IIconButtonProps> = (props) => {
    const { icon, onClick, disabled, buttonRef } = props
    const { isDark } = useContext(StyleContext).data
    return (
        <button
            type='button'
            ref={buttonRef}
            onClick={onClick}
            disabled={disabled}
            className={`${classes.iconButton} ${isDark ? classes.iconButtonDark : ''} ${disabled ? classes.disabled : ''}`}
        >
            <Icon name={icon} />
        </button>
    )
}

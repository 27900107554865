import { Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { Button } from '../../Button'

interface ITableFooterPaginationProps {
    length: number
    limit: number
    currentPage: number
    onPreviousPageClick: () => void
    onNextPageClick: () => void
    onGoToPageClick: (pageNumber: number) => void
}

export const TableFooterPagination: FunctionComponent<ITableFooterPaginationProps> = (props) => {
    const { length, limit, currentPage, onPreviousPageClick, onNextPageClick, onGoToPageClick } = props
    const totalPages = Math.ceil(length / limit)
    const isPreviousDisabled = currentPage === 1
    const isNextDisabled = currentPage === totalPages

    const ButtonGenerator = () => {
        const getPaginationRange = () => {
            const range: (number | string)[] = []

            // If there are fewer than 7 pages, show them all
            if (totalPages <= 7) {
                for (let i = 1; i <= totalPages; i++) {
                    range.push(i)
                }
            } else {
                // Always show the first page
                range.push(1)

                // Determine the middle section
                if (currentPage <= 4) {
                    // Case: currentPage is in the first 4 pages
                    for (let i = 2; i <= Math.min(5, totalPages - 1); i++) {
                        range.push(i)
                    }
                    if (totalPages > 6) {
                        range.push('…')
                    }
                } else if (currentPage >= totalPages - 3) {
                    // Case: currentPage is in the last 4 pages
                    range.push('…')
                    for (let i = totalPages - 4; i < totalPages; i++) {
                        range.push(i)
                    }
                } else {
                    // Case: currentPage is somewhere in the middle
                    range.push('…')
                    range.push(currentPage - 1)
                    range.push(currentPage)
                    range.push(currentPage + 1)
                    range.push('…')
                }

                // Always show the last page
                range.push(totalPages)
            }

            return range
        }
        const paginationRange = getPaginationRange()

        return (
            <div className='flex flex-row items-center space-x-1'>
                {paginationRange.map((page, index) =>
                    typeof page === 'number' ? (
                        <Button
                            key={index}
                            variant={currentPage === page ? 'alt' : 'outline'}
                            type='button'
                            size='small'
                            onClick={() => onGoToPageClick(page)}
                            cursorDisabled={currentPage === page}
                        >
                            <Text type='body2'>{page}</Text>
                        </Button>
                    ) : (
                        <Button
                            variant='outline'
                            type='button'
                            size='small'
                            onClick={() => {}}
                            key={index}
                            cursorDisabled
                        >
                            <Text type='body2'>...</Text>
                        </Button>
                    )
                )}
            </div>
        )
    }

    return (
        <div className='flex flex-row items-center space-x-1'>
            <Button
                variant='outline'
                type='button'
                size='small'
                onClick={onPreviousPageClick}
                disabled={isPreviousDisabled}
            >
                <Text type='body2' color={isPreviousDisabled ? 'disabled' : 'black'}>
                    Prev
                </Text>
            </Button>
            {ButtonGenerator()}
            <Button variant='outline' type='button' size='small' onClick={onNextPageClick} disabled={isNextDisabled}>
                <Text type='body2' color={isNextDisabled ? 'disabled' : 'black'}>
                    Next
                </Text>
            </Button>
        </div>
    )
}

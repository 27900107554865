import type { IChartData } from './types.ts'

export function cleanChartData(data: IChartData[]): IChartData[] {
    const newData = data.map(({ name, pv, uv, amt }) => {
        // TODO: Manage this conversion at the server side in serialization
        return {
            name,
            pv: parseFloat(pv < 0.01 ? pv.toFixed(5) : pv.toFixed(3)),
            uv: parseFloat(uv < 0.01 ? uv.toFixed(5) : uv.toFixed(3)),
            amt: parseFloat(amt < 0.01 ? amt.toFixed(5) : amt.toFixed(3)),
        }
    })
    newData.sort((a, b) => new Date(a.name).getTime() - new Date(b.name).getTime())
    return newData
}

export function collapseChartData(data: IChartData[]): IChartData[] {
    const dailyData: Record<string, IChartData> = {}

    data.forEach((item) => {
        const { name, uv, pv, amt } = item
        const day = new Date(name).toISOString().split('T')[0]
        if (day in dailyData) {
            dailyData[day].pv = Math.max(dailyData[day].pv, pv)
            dailyData[day].uv = Math.min(dailyData[day].uv, uv)
        } else {
            dailyData[day] = {
                name,
                amt,
                pv,
                uv,
            }
        }
    })

    return Object.keys(dailyData).map((key) => dailyData[key])
}

export function filterChartDataByDate(data: IChartData[], start: Date, end: Date): IChartData[] {
    return data.filter((item) => {
        const date = new Date(item.name)
        return date >= start && date <= end
    })
}

export function getNewestChartData(data: IChartData[]): IChartData | undefined {
    if (data.length) {
        let newestIdx: number = 0
        let newestDate: Date = new Date(data[0].name)
        for (const [index, item] of data.entries()) {
            const contenderDate = new Date(item.name)
            if (newestDate.getTime() < contenderDate.getTime()) {
                newestIdx = index
                newestDate = contenderDate
            }
        }
        return data[newestIdx]
    } else {
        return undefined
    }
}

export function getOldestChartData(data: IChartData[]): IChartData | undefined {
    if (data.length) {
        let oldestIdx: number = 0
        let oldestDate: Date = new Date(data[0].name)
        for (const [index, item] of data.entries()) {
            const contenderDate = new Date(item.name)
            if (oldestDate.getTime() > contenderDate.getTime()) {
                oldestIdx = index
                oldestDate = contenderDate
            }
        }
        return data[oldestIdx]
    } else {
        return undefined
    }
}

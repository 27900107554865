import type { FunctionComponent } from 'react'
import type { ProjectStatisticProps } from './project-statistic.ts'
import { ProjectStatisticMedium } from './ProjectStatisticMedium.tsx'
import { ProjectStatisticSmall } from './ProjectStatisticSmall.tsx'

export const ProjectStatistic: FunctionComponent<ProjectStatisticProps> = (props) => {
    const { statistic, size } = props
    switch (size) {
        default:
        case 'medium':
            return <ProjectStatisticMedium statistic={statistic} />
        case 'small':
            return <ProjectStatisticSmall statistic={statistic} />
    }
}

import type { IconName, Style } from '@demia/ui-kit'
import { Icon, StyleContext, Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useContext } from 'react'
import { StatisticLabel, Skeleton } from '@components/base'
import type { ProjectStatisticProps } from './project-statistic.ts'

const STATISTIC_TYPE_ICON_MAPPING: Record<string, IconName> = {
    chart: 'chart-bar',
    chartDark: 'chart-bar',
    sustainability: 'plant',
    sustainabilityDark: 'plant',
}

export const ProjectStatisticMedium: FunctionComponent<Omit<ProjectStatisticProps, 'size'>> = (props) => {
    const { statistic } = props
    const isDark = useContext(StyleContext).data.theme === 'dark'
    const { type, amount, unit, text, percentage } = statistic
    const style: Style = {
        fontFamily: `var(--font)`,
    }

    return (
        <div className='bg-[var(--surface-transparent)] dark:bg-[var(--surface-transparent-dark)] border-[1px] border-[var(--stroke-light)] dark:border-[var(--stroke-light-dark)] px-6 py-4'>
            <div className='flex flex-row items-center justify-center space-x-4'>
                {type ? (
                    <div className='bg-surface-1 dark:bg-surface-1-dark p-2 rounded-full'>
                        <Icon
                            name={STATISTIC_TYPE_ICON_MAPPING[`${type ?? 'sustainability'}${isDark ? 'Dark' : ''}`]}
                            color='text-brand-secondary'
                        />
                    </div>
                ) : (
                    <div className='h-[40px]'></div>
                )}
                <div className='flex flex-row items-end space-x-2'>
                    {amount && unit ? (
                        <p
                            className='font-[600] text-[32px] leading-8 align-text-bottom text-[var(--text-primary)] dark:text-[var(--text-invert)]'
                            style={{ ...style }}
                        >
                            {amount}
                        </p>
                    ) : (
                        <Skeleton width={120} height={32} />
                    )}
                    {amount && unit ? (
                        <p className='font-[500] text-[24px] leading-6 align-text-bottom text-[var(--grey)]'>{unit}</p>
                    ) : (
                        <Skeleton width={60} height={24} />
                    )}
                </div>
                {percentage ? <StatisticLabel value={percentage} /> : <></>}
            </div>
            <div className='mt-3'>
                {text ? (
                    <Text
                        type='body1'
                        color={isDark ? 'text-primary-dark' : 'text-secondary'}
                        freezeColor
                        justification='center'
                    >
                        {text}
                    </Text>
                ) : (
                    <Skeleton width={180} height={12} />
                )}
            </div>
        </div>
    )
}

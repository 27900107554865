import type { FunctionComponent } from 'react'

export interface IBarProps {
    fill: string
    x: number
    y: number
    width: number
    height: number
    rem: number
    volume: number[]
    index: number
    min?: number
}

interface IPoint {
    x: number
    y: number
}

function moveTo(x: number, y: number, { ry = 0, rx = 0 }): string {
    return `M${x + rx}, ${y + ry}`
}
function lineTo(x: number, y: number, { ry = 0, rx = 0 }): string {
    return `L${x + rx}, ${y + ry}`
}
function quadraticCurveTo(x: number, y: number, { ry = 0, rx = 0 }): string {
    return `Q${x}, ${y} ${x + rx} ${y + ry}`
}

function drawRoundEdgesRectangle(points: IPoint[], radius: number, { radiusTop = true, radiusBottom = false }): string {
    return `
    ${moveTo(points[0].x, points[0].y, { rx: radiusTop ? radius : 0 })}
    ${quadraticCurveTo(points[0].x, points[0].y, { ry: radiusTop ? radius : 0 })}
    ${lineTo(points[1].x, points[1].y, { ry: radiusBottom ? -radius : 0 })}
    ${quadraticCurveTo(points[1].x, points[1].y, { rx: radiusBottom ? radius : 0 })}
    ${lineTo(points[2].x, points[2].y, { rx: radiusBottom ? -radius : 0 })}
    ${quadraticCurveTo(points[2].x, points[2].y, { ry: radiusBottom ? -radius : 0 })}
    ${lineTo(points[3].x, points[3].y, { ry: radiusTop ? radius : 0 })}
    ${quadraticCurveTo(points[3].x, points[3].y, { rx: radiusTop ? -radius : 0 })}
    Z`
}

export const RoundBar: FunctionComponent<IBarProps> = (props) => {
    const { fill, x, y, min = 0, width, height, rem } = props
    const color = rem ? 'url(#linearGradient-rem)' : fill
    const radius = 6
    const maxWidth = 10
    const isLessWidth = width < maxWidth
    let x1,
        x2 = 0
    if (isLessWidth) {
        x1 = x
        x2 = x1 + width
    } else {
        const remainingWidth = width - maxWidth
        x1 = x + remainingWidth / 2
        x2 = x1 + maxWidth
    }
    const y1 = y
    const y2 = Math.max(y1 + height - min, y1 + maxWidth)
    const points = [
        { x: x1, y: y1 },
        { x: x1, y: y2 },
        { x: x2, y: y2 },
        { x: x2, y: y1 },
    ]
    const d = drawRoundEdgesRectangle(points, radius, { radiusBottom: min !== 0, radiusTop: true })
    return <path d={d} stroke='none' fill={color} />
}

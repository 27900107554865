import type { Style } from '@demia/ui-kit'
import { Text } from '@demia/ui-kit'
import type { FunctionComponent, ReactNode } from 'react'
import { IconButton } from '@components/base/Button'

interface IPopupProps {
    title: string
    children: ReactNode
    onClose: () => void
    width?: number | string
}

export const Popup: FunctionComponent<IPopupProps> = (props) => {
    const { title, children, onClose, width } = props

    const style: Style = {
        ...(width && { width: typeof width === 'number' ? `${width}px` : String(width) }),
    }

    return (
        <div className={`min-w-[30vw] max-w-[40vw] max-h-[55vh] flex flex-col space-y-8 px-4 py-2`} style={style}>
            <div className='flex flex-row items-center justify-between space-x-4'>
                <Text type='header4'>{title}</Text>
                <IconButton icon='x' onClick={onClose} />
            </div>
            {children}
        </div>
    )
}

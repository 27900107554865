import { areEqualObjects } from '@demia/core'
import { InfoText, Text } from '@demia/ui-kit'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ProjectLayout } from '@components/app'
import { Button, CheckboxInput, Link, Pane } from '@components/base'
import { FEATURES } from '@features'
import type { AnalyticsConfigurationCalculations } from '@lib/analytics'
import { ProjectContext } from '@lib/project'
import { buildProjectRoute, ProjectAnalyticsRoute, ProjectRoute } from '@lib/routing'
import classes from './ProjectAnalyticsSetupPage.module.scss'

export const ProjectAnalyticsSetupPage = () => {
    const { slug } = useParams()
    const navigate = useNavigate()
    const url = buildProjectRoute(ProjectRoute.Analytics, ProjectAnalyticsRoute.ParameterCustomization).replace(
        ':slug',
        slug ?? ''
    )

    const { data, setData } = useContext(ProjectContext)
    if (!data.analyticsConfiguration) {
        data.analyticsConfiguration = { calculations: {} }
    }

    const [calculationSettings, setCalculationSettings] = useState<AnalyticsConfigurationCalculations>(
        data.analyticsConfiguration.calculations
    )

    let canActivate = false
    useMemo(() => {
        const atLeastOneEnabled = Object.values(calculationSettings).some((setting) => setting)
        const haveSettingsChanged = !areEqualObjects(calculationSettings, data.analyticsConfiguration?.calculations)

        canActivate = atLeastOneEnabled && haveSettingsChanged
    }, [calculationSettings])

    function toggleAnalyticsParameter(id: string): void {
        setCalculationSettings({
            ...calculationSettings,
            [id]: !calculationSettings[id],
        })
    }

    function onCancelClick(): void {
        navigate(`/projects/${slug}/analytics`)
    }

    function onActivateClick(): void {
        setData({ analyticsConfiguration: { calculations: calculationSettings } })
        navigate(`/projects/${slug}/analytics`)
    }

    const location = useLocation()
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [location])

    const TitleComponent = () => {
        return (
            <>
                <Text type='header2'>Analytics Settings</Text>
                {FEATURES.project.analytics.addCalculation.enabled && (
                    <Button size='medium' icon='plus' onClick={() => {}}>
                        Add New
                    </Button>
                )}
            </>
        )
    }

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <div className={classes.analyticsSetup}>
                <Pane padding='top'>
                    <div className='mb-3'>
                        <InfoText>Set up monitoring parameters to activate analytics</InfoText>
                    </div>
                    <div className={classes.dataContainer}>
                        {Object.entries(calculationSettings)
                            .map(([id]) => data.details?.profiles?.[0].calculations.find((calc) => calc.id === id))
                            .map((calc) => (
                                <div className={classes.singleItem} key={calc?.id ?? ''}>
                                    <CheckboxInput
                                        id={calc?.id ?? ''}
                                        label={calc?.text ?? ''}
                                        checked={calculationSettings[calc?.id ?? '']}
                                        onClick={() => toggleAnalyticsParameter(calc?.id ?? '')}
                                    />
                                    {FEATURES.project.analytics.customizeParameters.enabled && (
                                        <Link
                                            url={`${url.replace(':analyticsSetupSlug', calc?.id ?? '')}`}
                                            icon='caret-right'
                                            color='text-primary'
                                            disabled={!calculationSettings[calc?.id ?? '']}
                                        >
                                            {data.analyticsConfiguration?.calculations[calc?.id ?? '']
                                                ? 'Edit parameters'
                                                : 'Set up parameters'}
                                        </Link>
                                    )}
                                </div>
                            ))}
                    </div>
                    <div className='flex flex-row items-center space-x-4 justify-end mt-8'>
                        <Button variant='text' size='medium' onClick={onCancelClick}>
                            Cancel
                        </Button>
                        <Button size='medium' onClick={onActivateClick} disabled={!canActivate}>
                            Activate
                        </Button>
                    </div>
                </Pane>
            </div>
        </ProjectLayout>
    )
}

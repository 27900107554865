const dataExportFileTypes = ['csv', 'xlsx'] as const
export type DataExportFileType = (typeof dataExportFileTypes)[number]

export interface IDataExportConfiguration {
    filename: string
    sheetNames?: string[]
    useHeaderRow?: boolean
}

export interface IDataSourceEquipment {
    id: string
    group: string
    units: string
    eqType: string
    name: string
    accuracy: number
    installed: number
    serialNo: string
    manufacturer: string
    assetUrl?: string
}

export interface IReadingDashboardContext {
    id: string
    address: string
    timestamp: string
    value: NestedReadingValue
    sheetData?: any
    annotations: [string]
    score: number
    unit?: string
}

export type NestedReadingValue =
    | { type: 'Float'; value: number }
    | { type: 'String'; value: string }
    | { type: 'Int'; value: number }
    | { type: 'Bool'; value: boolean }
    | { type: 'Empty' }

export function getNestedReadingValue(value: NestedReadingValue): [string, number] {
    const type = Object.keys(value)[0]
    const newValue = typeof Object.values(value)[0] === 'number' ? Object.values(value)[0] : 0

    switch (type) {
        case 'Float':
            return ['Float', newValue]
        case 'Int':
            return ['Int', newValue]
        case 'String':
            return ['String', 0]
        case 'Bool':
            return ['Bool', newValue ? 1 : 0]
        default:
            return ['Empty', 0]
    }
}

export interface IDataAnnotation {
    id: string
    key: string
    hash: string
    host: string
    kind: string
    signature: string
    isSatisfied: boolean
    timestamp: string
}

export interface ISensorContextData {
    id: string
    total: number
    avgcf: number
    equipment: IDataSourceEquipment
    readings: { [key: string]: IReadingDashboardContext }
    annotations: { [key: string]: [IDataAnnotation] }
    lastUpdated: string
    state: ISensorStateData
    assetUrl?: string
}

export interface ISensorStateData {
    realTimeFlow: number
    totalFlow: number
    currentDayAvg: number
}

export interface ISensorData {
    timestamp: Date
    value: NestedReadingValue
    address: string
    score: number
    unit: string
    annotations: IDataAnnotation[]
}

export interface ISensorOverview {
    total: number
    online: number
    sensors: { [key: string]: ISensorContextData }
}

export interface ISensorSource {
    id: string
    source: string
    url: string
    inputType: string
    timestamp: string
    dataConfidence: number
    dataType: string
    dataEntry: string
    recordCount: number
}

export interface IUpdateEquipmentDto {
    name: string
    group: string
    units: string
    eqType: string
    serialNo: string
    manufacturer: string
}

import { useAuth0 } from '@auth0/auth0-react'
import { Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { OnboardingLayout, AuthContext } from '@components/app'
import { Button, Link } from '@components/base'
import { StorageManager } from '@constants'
import { AppRoute, buildRoute } from '@lib/routing'

export const SignInPage: FunctionComponent = () => {
    const { loginWithRedirect } = useAuth0()
    const { hasSession } = useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(() => {
        if (hasSession) {
            const routerState = StorageManager.load('routerState')
            const wasOnSignIn = routerState?.location?.pathname === buildRoute(AppRoute.SignIn)
            const fallbackRoute = buildRoute(AppRoute.Overview)
            const route = wasOnSignIn ? fallbackRoute : (routerState?.location?.pathname ?? fallbackRoute)
            navigate(route)
        }
    }, [hasSession])

    return (
        <OnboardingLayout>
            <Text type='header1' color='text-primary'>
                Sign in to access the dashboard
            </Text>
            <div className='mt-6'>
                <Button
                    size='large'
                    width='full'
                    onClick={() =>
                        loginWithRedirect({
                            authorizationParams: {
                                scope: 'email openid profile read:current_user update:current_user_metadata',
                            },
                        })
                    }
                >
                    Sign In
                </Button>
                <div className='mt-2'>
                    <Text type='body1' color='text-primary'>
                        Don't have an account?
                    </Text>
                    <Link url='/register'>Request access here</Link>
                </div>
            </div>
        </OnboardingLayout>
    )
}

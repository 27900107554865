import { useContext } from 'react'
import { PopupContext } from './contexts.ts'
import type { IPopupContext } from './types.ts'

export const usePopup = (): IPopupContext => {
    const context = useContext(PopupContext)
    if (context) {
        return context
    } else {
        throw new Error('usePopup must be used within a provider.')
    }
}

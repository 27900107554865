import { Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { OnboardingLayout } from '@components/app'
import { Button, TextInput } from '@components/base'
import { ProjectApiCache } from '@lib/project'

export const RegisterPage: FunctionComponent = () => {
    const [sentRequest, setSentRequest] = useState<boolean>(false)

    const navigate = useNavigate()
    ProjectApiCache.clearProjects()

    const registerUserHandler = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const userInfo = event.target as typeof event.target & {
            username: { value: string }
            email: { value: string }
            firstName: { value: string }
            lastName: { value: string }
            organization: { value: string }
        }

        const emailBody = generateEmailBody(userInfo)
        setSentRequest(true)
        window.location.href = `mailto:info@demia.net?subject=Request Demo Access&body=${encodeURI(emailBody)}`
        navigate('/')
    }

    const generateEmailBody = (userInfo: any) => {
        return `Username: ${userInfo.username.value}\nEmail: ${userInfo.email.value}\nFirst Name: ${userInfo.firstName.value}\nLast Name: ${userInfo.lastName.value}\nOrganization: ${userInfo.organization.value}`
    }

    function onCancelClick(): void {
        navigate('/')
    }

    return (
        <OnboardingLayout>
            <div className='mb-8'>
                <Text type='header2'>Create a new account</Text>
            </div>
            <form onSubmit={registerUserHandler} className='flex flex-col space-y-4'>
                <TextInput id='username' label='Username' required autofocus />
                <TextInput id='email' label='Email' required />
                <TextInput id='firstName' label='First Name' required />
                <TextInput id='lastName' label='Last Name' required />
                <TextInput id='organization' label='Organization' required />

                {!sentRequest && (
                    <div className='pt-4'>
                        <Button type='submit' width='full' onClick={() => {}}>
                            Register
                        </Button>
                    </div>
                )}
                {sentRequest && (
                    <div className='mt-8 mb-1/2'>
                        <Text type='body1' justification='center'>
                            Please complete opened form submission email. You will receive a response soon.
                        </Text>
                    </div>
                )}
            </form>
            <div className='mt-4'>
                <Button variant='text' type='button' width='full' onClick={onCancelClick}>
                    Cancel
                </Button>
            </div>
        </OnboardingLayout>
    )
}

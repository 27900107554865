import type { SortDirection } from '@demia/core'
import { sortPrimitives } from '@demia/core'
import type { FunctionComponent } from 'react'
import type { ITableField } from '@components/base/Table'
import { Table } from '@components/base/Table'
import type { ISensorSource } from '@lib/sensor'
import { SiteDataSourcesTableRow } from './SiteDataSourcesTableRow.tsx'

interface ISiteDataSourcesTableProps {
    loading: boolean
    sensors: ISensorSource[]
}

const SITE_DATA_SOURCES_TABLE_FIELDS: ITableField[] = [
    {
        text: 'Source',
        key: 'source',
        sortingFunction: (a: ISensorSource, b: ISensorSource, direction: SortDirection) =>
            sortPrimitives(a.source, b.source, direction),
        sortByDefault: true,
    },
    {
        text: 'Input Type',
        key: 'inputType',
        sortingFunction: (a: ISensorSource, b: ISensorSource, direction: SortDirection) =>
            sortPrimitives(a.inputType, b.inputType, direction),
    },
    {
        text: 'Data Confidence',
        key: 'dataConfidence',
        sortingFunction: (a: ISensorSource, b: ISensorSource, direction: SortDirection) =>
            sortPrimitives(a.dataConfidence, b.dataConfidence, direction),
    },
    {
        text: 'Data Type',
        key: 'dataType',
        sortingFunction: (a: ISensorSource, b: ISensorSource, direction: SortDirection) =>
            sortPrimitives(a.dataType, b.dataType, direction),
    },
    {
        text: 'Data Entry',
        key: 'dataEntry',
        sortingFunction: (a: ISensorSource, b: ISensorSource, direction: SortDirection) =>
            sortPrimitives(a.dataEntry, b.dataEntry, direction),
    },
    {
        text: 'Records',
        key: 'recordCount',
        sortingFunction: (a: ISensorSource, b: ISensorSource, direction: SortDirection) =>
            sortPrimitives(a.recordCount, b.recordCount, direction),
    },
    {
        text: 'Last Updated',
        key: 'lastUpdated',
        sortingFunction: (a: ISensorSource, b: ISensorSource, direction: SortDirection) =>
            sortPrimitives(a.timestamp, b.timestamp, direction),
    },
]

export const SiteDataSourcesTable: FunctionComponent<ISiteDataSourcesTableProps> = (props) => {
    const { loading, sensors } = props
    return (
        <Table
            fields={SITE_DATA_SOURCES_TABLE_FIELDS}
            data={sensors}
            RowTemplate={(p) => SiteDataSourcesTableRow({ ...p, id: sensors.length.toString() })}
            loading={loading}
            enableMenus
        />
    )
}

import { ErrorHandler } from '@demia/core'
import { NotificationSystem } from '@demia/platform'
import { Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { Button, IconButton } from '@components/base'
import type { IProjectDetails } from '@lib/project'
import { ProjectApiService } from '@lib/project'

interface IRemoveSitePopupProps {
    site: IProjectDetails
    onRemove: () => void
    onClose: () => void
}

export const RemoveSitePopup: FunctionComponent<IRemoveSitePopupProps> = (props) => {
    const { site, onRemove, onClose } = props

    const [isRemovingSite, setIsRemovingSite] = useState(false)

    async function onRemoveSite(): Promise<void> {
        setIsRemovingSite(true)
        const [_, error] = await ProjectApiService.removeSite(site.projectId)
        setIsRemovingSite(false)
        if (error) {
            ErrorHandler.handleApiError(error)
        } else {
            NotificationSystem.push({
                type: 'success',
                message: `Removed site ${site.projectName}`,
            })
            onRemove()
            onClose()
        }
    }

    return (
        <div className='flex flex-col space-y-8 px-4 py-2'>
            <div className='flex flex-row items-center justify-between space-x-4'>
                <Text type='header4'>Remove site</Text>
                <IconButton icon='x' onClick={onClose} />
            </div>
            <div className='flex flex-col space-y-4 mb-6'>
                <div className='flex flex-col space-y-2'>
                    <Text type='body1'>You're about to remove the following site:</Text>
                    <div className='flex flex-row items-center space-x-2'>
                        <Text type='body1'>ID:</Text>
                        <Text type='body1' color='text-secondary' freezeColor>
                            {site.projectId}
                        </Text>
                    </div>
                    <div className='flex flex-row items-center space-x-2'>
                        <Text type='body1'>Name:</Text>
                        <Text type='body1' color='text-secondary' freezeColor>
                            {site.projectName}
                        </Text>
                    </div>
                </div>
                <Text type='body1' color='text-brand-primary' freezeColor>
                    Please click "Remove" to continue.
                </Text>
            </div>
            <div className='flex flex-row items-center justify-between space-x-4'>
                <Button variant='text' width='1/2' size='medium' onClick={onClose} disabled={isRemovingSite}>
                    Cancel
                </Button>
                <Button
                    variant='primary'
                    width='1/2'
                    size='medium'
                    onClick={onRemoveSite}
                    busy={isRemovingSite}
                    busyText='Removing…'
                >
                    Remove
                </Button>
            </div>
        </div>
    )
}

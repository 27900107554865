import { ErrorHandler, validateString } from '@demia/core'
import { NotificationSystem } from '@demia/platform'
import type { FunctionComponent } from 'react'
import type { IFormField } from '@components/base'
import { Form, Popup } from '@components/base'
import { ProjectApiService } from '@lib/project'
import type { ISensorContextData, ISensorSource, IUpdateEquipmentDto } from '@lib/sensor'

interface IEditSiteDataSourcePopupProps {
    siteId: string
    dataSource: ISensorSource
    dataSourceContext: ISensorContextData
    onUpdate: (payload: IUpdateEquipmentDto) => void
    onClose: () => void
}

export const EditSiteDataSourcePopup: FunctionComponent<IEditSiteDataSourcePopupProps> = (props) => {
    const { siteId, dataSource, dataSourceContext, onUpdate, onClose } = props

    async function onUpdateDataSource(payload: IUpdateEquipmentDto): Promise<void> {
        const [__, error] = await ProjectApiService.updateSensor(siteId, dataSource.url, payload)
        if (error) {
            ErrorHandler.handleApiError(error)
        } else {
            NotificationSystem.push({
                type: 'success',
                message: `Updated data source ${payload.name}`,
            })
            onUpdate(payload)
            onClose()
        }
    }

    const editSiteDataSourceFormFields: IFormField[] = [
        {
            type: 'text',
            property: 'name',
            id: 'nameInput',
            label: 'Name',
            defaultValue: dataSourceContext?.equipment?.name,
            autofocus: true,
            required: true,
            validate: (value) => validateString(value as string, 'Name'),
        },
        {
            type: 'text',
            property: 'group',
            id: 'groupInput',
            label: 'Group',
            defaultValue: dataSourceContext?.equipment?.group,
            required: true,
            validate: (value) => validateString(value as string, 'Group'),
        },
        {
            type: 'text',
            property: 'units',
            id: 'unitsInput',
            label: 'Units',
            defaultValue: dataSourceContext?.equipment?.units,
            required: true,
            validate: (value) => validateString(value as string, 'Units'),
        },
        {
            type: 'text',
            property: 'eqType',
            id: 'eqTypeInput',
            label: 'EQ Type',
            defaultValue: dataSourceContext?.equipment?.eqType,
            required: true,
            validate: (value) => validateString(value as string, 'EQ Type'),
        },
        {
            type: 'text',
            property: 'serialNo',
            id: 'serialNoInput',
            label: 'Serial No.',
            defaultValue: dataSourceContext?.equipment?.serialNo,
            validate: (value) => validateString(value as string, 'Serial No.'),
        },
        {
            type: 'text',
            property: 'manufacturer',
            id: 'manufacturerInput',
            label: 'Manufacturer',
            defaultValue: dataSourceContext?.equipment?.manufacturer,
            validate: (value) => validateString(value as string, 'Manufacturer'),
        },
    ]

    return (
        <Popup title='Edit data source' onClose={onClose}>
            <Form<IUpdateEquipmentDto>
                willOverflow
                fields={editSiteDataSourceFormFields}
                onSubmit={onUpdateDataSource}
                primaryButtonText='Update'
                primaryButtonBusyText='Updating…'
                secondaryButtonText='Cancel'
                onSecondaryButtonClick={onClose}
            />
        </Popup>
    )
}

import { Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useMemo, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { SiteDataSourceMenu } from '@components/app'
import { Link } from '@components/base'
import type { ISensorContextData } from '@lib/sensor'
import { convertFromSensorContextToSource } from '@lib/sensor'
import { UserContext } from '@lib/user'

interface IProjectDataSourceTitleProps {
    title: string
    url: string
}

export const ProjectDataSourceTitle: FunctionComponent<IProjectDataSourceTitleProps> = (props) => {
    const { title, url } = props
    const { data } = useContext(UserContext)
    const { slug, sensorsSlug } = useParams()

    const dataSource = useMemo(() => {
        const site = (data?.sites ?? []).find((s) => s.projectId === slug)
        const dataSourceContext = site?.sensors?.sensors?.[sensorsSlug ?? ''] ?? ({} as ISensorContextData)
        return convertFromSensorContextToSource(dataSourceContext)
    }, [sensorsSlug])

    return (
        <div className='w-full text-nowrap flex flex-row items-center justify-between'>
            <Link url={url} icon='arrow-left' color='text-primary' reverse>
                <Text type='header2'>{title}</Text>
            </Link>
            <SiteDataSourceMenu dataSource={dataSource} />
        </div>
    )
}

import type { FunctionComponent } from 'react'
import type { IPopupState } from './types.ts'

interface IPopupWrapperProps {
    state: IPopupState
}

export const PopupWrapper: FunctionComponent<IPopupWrapperProps> = (props) => {
    const { state } = props
    return (
        <div
            key={state.id}
            className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-surface-0 dark:bg-surface-0-dark p-6 clipped shadow-lg z-[501]'
        >
            {state.content}
        </div>
    )
}

import { strToSentenceCase, truncateString } from '@demia/core'
import { Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Link, OutsideAlerter, Pane, Skeleton } from '@components/base'
import type { IProjectDetails, IPin } from '@lib/project'

interface IProjectDataSourcePopupProps {
    details: IProjectDetails
    onClose: () => void
}

export const ProjectDataSourcePopup: FunctionComponent<IProjectDataSourcePopupProps> = (props) => {
    const { details, onClose } = props
    const { slug } = useParams()
    const url = `/projects/${slug}/data-sources`

    const pins = useMemo<IPin[]>(
        () =>
            Object.values(details?.sensors?.sensors ?? {})
                .map((sensor) => ({
                    sensorId: strToSentenceCase(sensor.id),
                    sensorSlug: sensor.id,
                    class: '',
                    class2: '',
                    title: sensor.id,
                    model: sensor.equipment.id,
                    flow: String(sensor.total),
                    average: String(sensor.avgcf),
                }))
                .sort((a, b) => (a.sensorId > b.sensorId ? 1 : -1)),
        [details]
    )

    return (
        <OutsideAlerter onClick={onClose}>
            <div className='clipped bg-surface-0 dark:bg-surface-0-dark' style={{ transform: 'translate(+30%, 60%)' }}>
                <Pane padding='none'>
                    <div className='w-[500px] h-[60vh] overflow-auto px-8 pt-2 pb-0 bg-surface-0 dark:bg-surface-0-dark relative'>
                        <div className='flex flex-col items-center justify-center gap-4 py-2 border-b-[1px] border-stroke'>
                            <Text type='header3' justification='center'>
                                Data Sources
                            </Text>
                            {details.projectInfo.projectDev === 'LI-COR' && (
                                <img
                                    src={'https://demia-public.s3.us-east-1.amazonaws.com/testing/image+(9).png'}
                                    alt={'Data Sources'}
                                    height={108}
                                    width={108}
                                    style={{ margin: '0 auto' }}
                                />
                            )}
                        </div>
                        {pins.length === 0
                            ? Array.from({ length: 6 }).map((_) => (
                                  <div className='mt-4 mb-4'>
                                      <Skeleton height={72} />
                                  </div>
                              ))
                            : pins.map((pin, index) => (
                                  <div key={index} className='flex items-start space-x-4 mt-4 mb-8'>
                                      <div className='flex flex-1 flex-col space-y-2'>
                                          <Text type='header4'>Sensor ID</Text>
                                          <Text type='body1'>{truncateString(pin.sensorId, 30)}</Text>
                                      </div>
                                      <div className='flex flex-1 flex-col space-y-2'>
                                          <Text type='header4'>Model</Text>
                                          <Text type='body1'>{truncateString(pin.model ?? '', 30)}</Text>
                                      </div>
                                      <div className='w-1/5 self-center'>
                                          <Link url={`${url}/${pin.sensorSlug}`}>View sensor</Link>
                                      </div>
                                  </div>
                              ))}
                    </div>
                </Pane>
            </div>
        </OutsideAlerter>
    )
}

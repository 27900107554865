import { ErrorHandler, validateString } from '@demia/core'
import { NotificationSystem } from '@demia/platform'
import type { FunctionComponent } from 'react'
import type { IFormField } from '@components/base'
import { Form, Popup } from '@components/base'
import type { IProjectDetails, IUpdateSiteDto } from '@lib/project'
import { ProjectApiService } from '@lib/project'

interface IEditSiteDetailsPopupProps {
    site: IProjectDetails
    onUpdate: (payload: IUpdateSiteDto) => void
    onClose: () => void
}

export const EditSiteDetailsPopup: FunctionComponent<IEditSiteDetailsPopupProps> = (props) => {
    const { site, onUpdate, onClose } = props

    async function onUpdateSite(payload: IUpdateSiteDto): Promise<void> {
        const [_, error] = await ProjectApiService.updateSite(site.projectId, payload)
        if (error) {
            ErrorHandler.handleApiError(error)
        } else {
            NotificationSystem.push({
                type: 'success',
                message: `Updated site ${payload.name}`,
            })
            onUpdate(payload)
            onClose()
        }
    }

    const editSiteDetailsFormFields: IFormField[] = [
        {
            type: 'text',
            property: 'name',
            id: 'nameInput',
            label: 'Name',
            defaultValue: site.projectName,
            autofocus: true,
            required: true,
            validate: (value) => validateString(value as string, 'Name'),
        },
        {
            type: 'text',
            property: 'developer',
            id: 'developerInput',
            label: 'Developer',
            defaultValue: site.projectInfo.projectDev,
            required: true,
            validate: (value) => validateString(value as string, 'Developer'),
        },
        {
            type: 'text',
            property: 'type',
            id: 'typeInput',
            label: 'Type',
            defaultValue: site.projectInfo.type,
            required: true,
            validate: (value) => validateString(value as string, 'Type'),
        },
    ]

    return (
        <Popup title='Edit site details' onClose={onClose}>
            <Form<IUpdateSiteDto>
                fields={editSiteDetailsFormFields}
                onSubmit={onUpdateSite}
                primaryButtonText='Update'
                primaryButtonBusyText='Updating…'
                secondaryButtonText='Cancel'
                onSecondaryButtonClick={onClose}
            />
        </Popup>
    )
}

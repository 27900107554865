import { useKeyPress, StyleContext } from '@demia/ui-kit'
import type { FunctionComponent, MouseEvent, RefObject } from 'react'
import { Fragment, useContext, useRef, useState } from 'react'
import type { PopoverEvent } from '@components/base/Popover'
import { Popover } from '@components/base/Popover'
import { IconButton } from '../Button'
import type { IActionMenuItemProps } from './ActionMenuItem.tsx'
import { ActionMenuItem } from './ActionMenuItem.tsx'
import classes from './Menu.module.scss'

export interface IActionMenuProps {
    items?: IActionMenuItemProps[]
    event?: PopoverEvent
    disabled?: boolean
    AnchorComponent?: FunctionComponent<IActionMenuAnchorProps>
}

export interface IActionMenuAnchorProps {
    onMenuClick?: () => void
    anchorRef?: RefObject<HTMLButtonElement> | RefObject<HTMLDivElement>
    disabled?: boolean
}

export const ActionMenu: FunctionComponent<IActionMenuProps> = (props) => {
    const { items = [], event, disabled, AnchorComponent } = props
    const [showMenu, setShowMenu] = useState(false)
    const [hadOutsideClick, setHadOutsideClick] = useState(false)
    const anchorRef = useRef<HTMLButtonElement | null>(null)
    const isDark = useContext(StyleContext).data.theme === 'dark'

    function onMenuClick(event?: MouseEvent<HTMLButtonElement>): void {
        if (event?.target) {
            if (hadOutsideClick) {
                // Do nothing because outside click handler already hid the menu.
            } else {
                setShowMenu(!showMenu)
            }
        } else {
            /**
             * NOTE: If we are here, the function is being called from the outside click
             * handler of the popover component.
             */
            setShowMenu(false)
            setHadOutsideClick(true)

            /**
             * NOTE: We are setting a timeout here to mitigate the fact that this function gets called twice
             * when closing a menu by clicking the button (first from the outside click handler then from the icon
             * button on click handler).
             */
            setTimeout(() => {
                setHadOutsideClick(false)
            }, 200)
        }
    }

    useKeyPress('Escape', onMenuClick)

    return (
        <div
            className={`z-[150] w-full flex flex-row items-center justify-end ${classes.actionMenu} ${isDark ? classes.actionMenuDark : ''}`}
        >
            {AnchorComponent ? (
                <AnchorComponent onMenuClick={onMenuClick} anchorRef={anchorRef} disabled={disabled} />
            ) : (
                <IconButton icon='dots-three' onClick={onMenuClick} buttonRef={anchorRef} disabled={disabled} />
            )}
            {!disabled && showMenu && anchorRef && (
                <Popover
                    anchor={anchorRef.current as HTMLButtonElement}
                    event={event ?? 'click'}
                    onOutsideClick={onMenuClick}
                >
                    <div className='animate-fade-in divide-y-[1px] divide-stroke dark:divide-stroke-dark'>
                        {items.map((item, index) => (
                            <Fragment key={index}>
                                <ActionMenuItem {...item} />
                            </Fragment>
                        ))}
                    </div>
                </Popover>
            )}
        </div>
    )
}

import { ErrorHandler } from '@demia/core'
import { Text } from '@demia/ui-kit'
import { useEffect, useState } from 'react'
import { ProfileLayout } from '@components/app'
import { Button, Pane } from '@components/base'
import { UserApiService } from '@lib/user'

export const IdentityPage = () => {
    const [_, setLoading] = useState(true)
    const [identity, setIdentity] = useState<string | null>(null)
    const [message, setMessage] = useState<string>('')
    const [deleteDid, setDeleteDid] = useState(false)

    async function getIdentity(): Promise<void> {
        const [data, error] = await UserApiService.getIdentity()

        if (error) {
            if (error.message == 'User error: No identity stored in user instance') {
                // No identity, leave as is
            } else {
                // Other issue, we want to delete
                ErrorHandler.handleApiError(error)
                setMessage(error.message)
                setDeleteDid(true)
            }
        } else {
            setMessage('')
            setIdentity(data.doc_id)
        }

        setLoading(false)
    }

    useEffect(() => {
        void getIdentity()
    }, [])

    async function onCreateClick(): Promise<void> {
        setMessage('Processing Request... Please wait')
        const [message, error] = await UserApiService.createIdentity()
        if (error) {
            ErrorHandler.handleApiError(error)
            setMessage(error.message)
        } else {
            setMessage('')
            setIdentity(message)
        }
    }

    async function onDeleteClick(): Promise<void> {
        setMessage('Processing Request... Please wait')
        const [message, error] = await UserApiService.deleteIdentity()
        if (error) {
            ErrorHandler.handleApiError(error)
            setMessage(error.message)
        } else {
            setMessage(message)
            setDeleteDid(false)
            setIdentity(null)
        }
    }

    return (
        <ProfileLayout title='Identity'>
            <Pane>
                {identity && (
                    <div id='identityContainer' className='flex flex-col space-y-4'>
                        <Text type='body1'>Found identity:</Text>
                        <Text type='body1'>{identity.toString()}</Text>
                        <div>
                            <Button size='large' onClick={onDeleteClick}>
                                Delete
                            </Button>
                        </div>
                    </div>
                )}
                {!identity && (
                    <div id='createIdentityContainer' className='flex flex-col space-y-4'>
                        <Text type='header3'>Create an Identity</Text>
                        {!deleteDid && (
                            <Button size='large' onClick={onCreateClick}>
                                Create
                            </Button>
                        )}
                        {deleteDid && (
                            <Button size='large' onClick={onDeleteClick}>
                                Delete
                            </Button>
                        )}
                        <Text type='body2'>{message}</Text>
                    </div>
                )}
            </Pane>
        </ProfileLayout>
    )
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import { generateRandomId } from '@demia/core'
import type { FunctionComponent } from 'react'
import { useEffect } from 'react'
import { DEFAULT_MATH_JAX_CONFIG } from './constants'

interface IFormulaProps {
    formula: string
    config?: Record<string, unknown>
}

export const Formula: FunctionComponent<IFormulaProps> = (props) => {
    const { formula, config = DEFAULT_MATH_JAX_CONFIG } = props
    const id = generateRandomId()

    useEffect(() => {
        if (typeof window?.MathJax !== 'undefined' && typeof window.MathJax.Hub !== 'undefined') {
            window.MathJax.Hub.Config(config)
            window.MathJax.Hub.Typeset([id])
        }
    }, [formula])

    return (
        <div id={id} className='text-text-primary dark:text-text-primary-dark'>
            {formula}
        </div>
    )
}

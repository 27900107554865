import type { Result } from '@demia/core'
import type { IAuthUser } from '@demia/platform'
import { AuthApiService } from '@demia/platform'
import { StorageManager } from '@constants'
import { PROFILE_PICTURE_MAXIMUM_BYTES } from './user.constants.ts'
import { UserRole } from './user.enums.ts'
import type { IAnalyticsSettings, IDataSourceSettings } from './user.types.ts'

export function getUserRoleText(role: UserRole): string {
    switch (role) {
        case UserRole.Admin:
            return 'Admin'
        case UserRole.Viewer:
        default:
            return 'Viewer'
    }
}

export async function logoutUser(logoutFn?: () => Promise<void>): Promise<void> {
    StorageManager.delete('routerState')
    AuthApiService.removeBearerToken()
    logoutFn && (await logoutFn())
}

export function getInitialsOfUser(user: IAuthUser): string {
    if (user) {
        if (user.name) {
            const firstLetter = user.given_name ? user.given_name[0] : user?.name.toUpperCase()[0]
            const secondLetter = user?.family_name ? user?.family_name[0] : user?.name.toLowerCase()[1]
            return `${firstLetter}${secondLetter}`
        } else {
            return user.profile?.toUpperCase()?.slice(0, 2) ?? ''
        }
    } else {
        return ''
    }
}

export function getAnalyticsSettingsForUser(userId: string, siteId: string): IAnalyticsSettings | undefined {
    const userData = StorageManager.load('userData')?.[userId]
    return userData?.settings?.sites?.[siteId]?.analytics ?? undefined
}

export function getDataSourceSettingsForUser(
    userId: string,
    siteId: string,
    dataSourceId: string
): IDataSourceSettings | undefined {
    const userData = StorageManager.load('userData')?.[userId]
    return userData?.settings?.sites?.[siteId]?.dataSources?.[dataSourceId] ?? undefined
}

export function validateImageFile(file: File): Result<boolean> {
    if (!file.type.includes('image')) {
        return [null, new Error('File is an invalid image')]
    }

    if (file.size > PROFILE_PICTURE_MAXIMUM_BYTES) {
        return [null, new Error('File size is too large')]
    }

    return [true, null]
}

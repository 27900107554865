import type { Result, IHttpClient } from '@demia/core'
import { HttpClient } from '@demia/core'
import type { IVerifiableCredential } from '@lib/identity'
import type {
    IGuardianDataReport,
    IHederaApiService,
    IHederaContext,
    IHederaGuardianRegistration,
    IHederaProfile,
    IHederaReport,
} from './hedera.types'

class HederaApiServiceImpl implements IHederaApiService {
    private static instance: HederaApiServiceImpl
    private readonly http: IHttpClient

    private constructor(http: IHttpClient) {
        this.http = http
    }

    static getInstance(http: IHttpClient): IHederaApiService {
        if (!HederaApiServiceImpl.instance) {
            HederaApiServiceImpl.instance = new HederaApiServiceImpl(http)
        }
        return HederaApiServiceImpl.instance
    }

    registerGuardian(siteId: string, registration: IHederaGuardianRegistration): Promise<Result<void>> {
        return this.http.post<void>(`/api/guardian/${siteId}/hedera/register`, registration)
    }

    disconnectGuardian(siteId: string): Promise<Result<void>> {
        return this.http.post<void>(`/api/guardian/${siteId}/hedera/disconnect`, {})
    }

    getContext(siteId: string): Promise<Result<IHederaContext>> {
        return this.http.get<IHederaContext>(`/api/guardian/${siteId}/hedera`)
    }

    getProfile(): Promise<Result<IHederaProfile>> {
        return this.http.get<IHederaProfile>('/api/guardian/hedera/profile')
    }

    getLink(username: string, password: string): Promise<Result<IHederaProfile>> {
        return this.http.post<IHederaProfile>('/api/guardian/hedera/link', { username, password })
    }

    submitReport(siteId: string, report: IHederaReport): Promise<Result<void>> {
        return this.http.post<void>(`/api/guardian/${siteId}/hedera/report`, report)
    }

    getReportForVerifiableCredential(
        verifiableCredential: IVerifiableCredential
    ): Promise<Result<IGuardianDataReport[]>> {
        return this.http.post<IGuardianDataReport[]>('/api/guardian/data', verifiableCredential)
    }
}

export const HederaApiService = HederaApiServiceImpl.getInstance(HttpClient)

import { Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { Link, Skeleton } from '@components/base'
import type { IProjectNotification } from '@lib/project'
import { ProjectUserActivity } from './ProjectUserActivity.tsx'

interface IProjectRecentActivityProps {
    activity: IProjectNotification[]
    loading?: boolean
}

export const ProjectRecentActivity: FunctionComponent<IProjectRecentActivityProps> = (props) => {
    const loaderSlug = useParams().slug
    const { activity = [], loading } = props
    return (
        <section className='bg-[var(--surface-transparent)] dark:bg-[var(--surface-transparent-dark)] max-h-[300px] text-nowrap p-4 border-[1px] border-[var(--stroke-light)] dark:border-[var(--stroke-light-dark)] overflow-auto'>
            <div className='flex items-center justify-between space-x-8 mb-3 pb-3 border-b-[1px] border-[var(--stroke)] dark:border-[var(--stroke-dark)]'>
                <Text type='subtitle2'>Recent Activity</Text>
                <Link url={`/projects/${loaderSlug}/activity`}>View all</Link>
            </div>
            <div className='w-full h-auto flex flex-col space-y-4'>
                {loading ? (
                    <div className='flex flex-col justify-between space-y-4'>
                        <Skeleton height={36} />
                        <Skeleton height={36} />
                        <Skeleton height={36} />
                        <Skeleton height={36} />
                    </div>
                ) : activity.length > 0 ? (
                    activity.map((i: IProjectNotification, index: number) => <ProjectUserActivity key={index} {...i} />)
                ) : (
                    <div className='w-full h-[192px] flex flex-row items-center justify-center'>
                        <Text type='body1' color='text-secondary'>
                            No Activity
                        </Text>
                    </div>
                )}
            </div>
        </section>
    )
}

import { Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { ProfileAvatar } from '@components/app/Profile'
import type { ITableRowProps } from '@components/base/Table'
import { TableRow } from '@components/base/Table'
import type { IProjectNotification } from '@lib/project'

export const ProjectActivityTableRow: FunctionComponent<ITableRowProps<IProjectNotification>> = (props) => {
    const { user = '', message = '', timestamp = new Date() } = props?.data ?? {}
    const textEntries = [message, timestamp.toLocaleString()]
    const cells = [
        <div className='flex flex-row items-center space-x-2'>
            <ProfileAvatar initials={user.substring(0, 2)} />
            <Text type='body1'>{user}</Text>
        </div>,
        ...textEntries.map((entry) => <Text type='body1'>{entry}</Text>),
    ]

    return <TableRow id={props.id}>{cells.map((cell) => cell)}</TableRow>
}

import { StyleContext } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useContext } from 'react'
import { SidebarTab } from '@components/base'
import classes from '@components/base/Sidebar/Sidebar.module.scss'
import { config } from '@constants'
import { FEATURES } from '@features'
import { ProjectContext } from '@lib/project'
import type { ProjectAnalyticsRoute } from '@lib/routing'
import { ProjectDataSourcesRoute, ProjectRoute } from '@lib/routing'

interface IProjectSidebarNavigationProps {
    projectSlug: string
}

function buildProjectRoute(
    slug: string,
    route?: ProjectRoute,
    subRoute?: ProjectAnalyticsRoute | ProjectDataSourcesRoute
): string {
    return route ? `/projects/${slug}/${route}${subRoute ? '/' + subRoute : ''}` : `/projects/${slug}`
}

export const ProjectSidebarNavigation: FunctionComponent<IProjectSidebarNavigationProps> = (props) => {
    const { projectSlug } = props
    const { data } = useContext(ProjectContext)
    const { details } = data
    const isDark = useContext(StyleContext).data.theme === 'dark'
    const pathname = window?.location?.pathname ?? ''

    const optionalPages = [FEATURES.project.guardian]
    const isOneOptionalPageEnabled = optionalPages.some((page) => page.enabled)
    const analyticsConfiguration = useContext(ProjectContext)?.data?.analyticsConfiguration
    const hasAnalyticsProfile = Boolean(
        analyticsConfiguration && details?.profiles?.length && details?.profiles?.length > 0
    )

    return (
        <nav className={`${classes['sidebar']} ${isDark && classes['sidebar-dark']}`}>
            {FEATURES.project.dashboard.enabled && (
                <SidebarTab
                    url={buildProjectRoute(projectSlug, ProjectRoute.Dashboard)}
                    icon='speedometer'
                    text='Dashboard'
                    isActive={pathname?.endsWith(projectSlug)}
                />
            )}
            {FEATURES.project.analytics.enabled && hasAnalyticsProfile && (
                <SidebarTab
                    url={buildProjectRoute(projectSlug, ProjectRoute.Analytics)}
                    icon='chart-bar'
                    text='Analytics'
                    isActive={pathname?.includes('analytics')}
                />
            )}
            {FEATURES.project.dataSources.enabled && (
                <SidebarTab
                    url={buildProjectRoute(projectSlug, ProjectRoute.DataSources)}
                    icon='cpu'
                    text='Data Sources'
                    isActive={pathname?.includes('data-sources')}
                />
            )}
            {FEATURES.project.documentation.enabled && (
                <SidebarTab
                    url={buildProjectRoute(projectSlug, ProjectRoute.Documentation)}
                    icon='file-text'
                    text='Documentation'
                    isActive={pathname?.includes('documentation')}
                />
            )}
            {FEATURES.project.activity.enabled && (
                <SidebarTab
                    url={buildProjectRoute(projectSlug, ProjectRoute.Activity)}
                    icon='pulse'
                    text='Activity'
                    isActive={pathname?.includes('activity')}
                />
            )}
            {config.DEMO_MODE ? (
                <></>
            ) : (
                <>
                    {FEATURES.project.users.enabled && (
                        <SidebarTab
                            url={buildProjectRoute(projectSlug, ProjectRoute.Users)}
                            icon='users'
                            text='Users'
                            isActive={pathname?.includes('users')}
                        />
                    )}
                    {FEATURES.project.dataSources.addSource.enabled && (
                        <SidebarTab
                            url={buildProjectRoute(
                                projectSlug,
                                ProjectRoute.DataSources,
                                ProjectDataSourcesRoute.AddSensor
                            )}
                            icon='cpu'
                            text='Add Sensor'
                            isActive={pathname?.includes('add-sensor')}
                        />
                    )}
                </>
            )}
            {isOneOptionalPageEnabled && (
                <>
                    <div className='border-b-[1px] border-[var(--stroke)] dark:border-[var(--stroke-dark)]'></div>
                    {FEATURES.project.guardian.enabled && (
                        <SidebarTab
                            url={buildProjectRoute(projectSlug, ProjectRoute.Guardian)}
                            icon='guardian'
                            text='Guardian'
                            isActive={pathname?.includes('guardian')}
                        />
                    )}
                </>
            )}
        </nav>
    )
}

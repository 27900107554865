import type { SortDirection } from '@demia/core'
import { ErrorHandler, sortPrimitives } from '@demia/core'
import { Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import DatePicker from 'react-datepicker'
import { useNavigate, useParams } from 'react-router-dom'
import { IdentityTable, ProjectLayout } from '@components/app'
import type { ITableField, ITableRowProps } from '@components/base'
import { Button, Link, LinkTarget, Pane, Skeleton, Table, TableRow } from '@components/base'
import { config } from '@constants'
import type { IGuardianDataReport, IHederaContext } from '@lib/hedera'
import { HederaApiService } from '@lib/hedera'
import type { IVerifiableCredential } from '@lib/identity'
import classes from './ProjectGuardianPage.module.scss'

export const ProjectGuardianPage: FunctionComponent = () => {
    const { slug } = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(true)
    const [hederaContext, setHederaContext] = useState<IHederaContext | null>(null)

    const sevenDaysAgo = new Date()
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7)

    const [startDate, setStartDate] = useState(sevenDaysAgo)
    const [endDate, setEndDate] = useState(new Date())
    const [sendingReport, setSendingReport] = useState<boolean>(false)
    const [disconnecting, setDisconnecting] = useState<boolean>(false)
    const [showData, setShowData] = useState<{ [key: string]: boolean }>({})
    const [loadingData, setLoadingData] = useState<{ [key: string]: boolean }>({})
    const [vcData, setVcData] = useState<{ [key: string]: IGuardianDataReport[] }>({})

    async function loadHederaContext(): Promise<void> {
        const [data, error] = await HederaApiService.getContext(slug ?? '')
        if (error) {
            ErrorHandler.handleApiError(error)
        } else {
            if (data) {
                setHederaContext(data)
                const showDataObj: { [key: string]: boolean } = {}
                data.vcs.map((vc) => {
                    const parsed = typeof vc.vc === 'string' ? JSON.parse(vc.vc) : vc.vc
                    showDataObj[parsed.id] = false
                })
                setLoading(false)
            } else {
                navigate(`/projects/${slug}/guardian/register`)
            }
        }

        setLoading(false)
    }

    useEffect(() => {
        void loadHederaContext()
    }, [])

    async function onSendReportClick(): Promise<void> {
        if (slug) {
            setSendingReport(true)
            const [_, error] = await HederaApiService.submitReport(slug, { start: startDate, end: endDate })
            if (error) {
                ErrorHandler.handleApiError(error)
            }
            setSendingReport(false)
        }
    }

    async function getVcData(vc: IVerifiableCredential): Promise<void> {
        if (!showData[vc.id]) {
            if (!vcData[vc.id] || vcData[vc.id].length === 0) {
                setLoadingData({ ...loadingData, [vc.id]: true })
                const [data, error] = await HederaApiService.getReportForVerifiableCredential(vc)
                if (error) {
                    ErrorHandler.handleApiError(error)
                } else {
                    setVcData({ ...vcData, [vc.id]: data })
                }
                setLoadingData({ ...loadingData, [vc.id]: false })
            }
            setShowData({ ...showData, [vc.id]: true })
        } else {
            setShowData({ ...showData, [vc.id]: false })
        }
    }

    async function onDisconnect(): Promise<void> {
        if (slug) {
            setDisconnecting(true)
            const [_, error] = await HederaApiService.disconnectGuardian(slug)
            if (error) {
                ErrorHandler.handleApiError(error)
            } else {
                navigate(`/projects/${slug}/dashboard`)
            }
            setDisconnecting(false)
        }
    }

    const TitleComponent: FunctionComponent = () => {
        return (
            <>
                <div></div>
                <div></div>
                <div className='flex flex-col space-y-4'>
                    <Text type='header4'>Submit a Hedera Guardian report</Text>
                    <ReportCalendar />
                </div>
            </>
        )
    }

    const ReportCalendar: FunctionComponent = () => {
        return (
            <div className={classes.guardian}>
                <div className={classes.datePickerContainer}>
                    <div className={classes.datepicker}>
                        {/* eslint-disable @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <DatePicker
                            calendarClassName='calendarStyle'
                            popperClassName='pooperStyle'
                            selected={startDate}
                            onChange={(date) => date && setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat='MMMM d, yyyy'
                        />
                        <span>-</span>
                        {/* eslint-disable @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <DatePicker
                            calendarClassName='calendarStyle'
                            popperClassName='pooperStyle'
                            selected={endDate}
                            onChange={(date) => date && setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            dateFormat='MMMM d, yyyy'
                        />
                    </div>
                    <span className={classes.divider}></span>
                    <Button disabled={sendingReport} size='medium' onClick={onSendReportClick}>
                        {sendingReport ? 'Sending…' : 'Send Report'}
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <div className='mb-4'>
                <Text type='header3'>Methods</Text>
            </div>
            <Pane>
                <Text type='header3'>DID Document ID</Text>
                {loading ? (
                    <Skeleton width={512} height={18} />
                ) : (
                    <Text type='body1'>{hederaContext?.docId ?? 'Not found'}</Text>
                )}
                <br />
                <Text type='header4'>Identity Methods</Text>
                <IdentityTable loading={loading} methods={hederaContext?.methods ?? []} />
            </Pane>
            <div className='mt-8 mb-4' style={{ maxHeight: '700px', overflow: 'auto' }}>
                <Text type='header2'>Verifiable Credentials</Text>
                {!loading ? (
                    hederaContext?.vcs === undefined || hederaContext?.vcs?.length === 0 ? (
                        <Pane>
                            <Text type='body1'>No verifiable credentials found</Text>
                        </Pane>
                    ) : (
                        hederaContext?.vcs.map((vc, i) => {
                            const vcObj = typeof vc.vc === 'string' ? JSON.parse(vc.vc) : vc.vc
                            const vcKey = `vc-${i}`
                            return (
                                <>
                                    <Pane key={vcKey}>
                                        <div className='flex flex-col space-y-6'>
                                            <div className='flex flex-row space-x-4 align-text-bottom'>
                                                <Text type='header3'>VC Id: </Text>
                                                <Text type='header4'>{vcObj.id}</Text>
                                            </div>
                                            <div className='content' style={{ overflow: 'auto', maxHeight: '200px' }}>
                                                <pre>{JSON.stringify(vcObj, null, 2)}</pre>
                                            </div>
                                            {showData[vcObj.id] && <DataTable data={vcData[vcObj.id]} />}
                                            <Button onClick={() => getVcData(vcObj)}>
                                                {!showData[vcObj.id]
                                                    ? !loadingData[vcObj.id]
                                                        ? 'Get Data'
                                                        : 'Loading…'
                                                    : 'Hide Data'}
                                            </Button>
                                        </div>
                                    </Pane>
                                    <br />
                                </>
                            )
                        })
                    )
                ) : (
                    <Skeleton width={192} height={18} />
                )}
            </div>
            <Pane>
                <div className='mt-8 mb-4'>
                    <Text type='header2'>Disconnect Guardian Instance</Text>
                    <Text type='body1'>Disconnect this Guardian instance from the project site</Text>
                    <br />
                    <Button size='medium' onClick={onDisconnect}>
                        {disconnecting ? 'Disconnecting…' : 'Disconnect'}
                    </Button>
                </div>
            </Pane>
        </ProjectLayout>
    )
}

const DATA_TABLE_FIELDS: ITableField[] = [
    {
        text: 'Address',
        key: 'address',
        sortingFunction: (a: IGuardianDataReport, b: IGuardianDataReport, direction: SortDirection) =>
            sortPrimitives(a.address, b.address, direction),
        sortByDefault: true,
    },
    {
        text: 'Identifier',
        key: 'identifier',
        sortingFunction: (a: IGuardianDataReport, b: IGuardianDataReport, direction: SortDirection) =>
            sortPrimitives(a.identifier, b.identifier, direction),
    },
    {
        text: 'Content',
        key: 'content',
        sortingFunction: (a: IGuardianDataReport, b: IGuardianDataReport, direction: SortDirection) =>
            sortPrimitives(a.content, b.content, direction),
    },
]

const DataTable: FunctionComponent<{ data: IGuardianDataReport[] }> = (props) => {
    return (
        <Table
            fields={DATA_TABLE_FIELDS}
            data={props.data}
            RowTemplate={(_props) => DataTableRow({ ..._props, id: props.data.length.toString() })}
        />
    )
}

const DataTableRow: FunctionComponent<ITableRowProps<IGuardianDataReport>> = (props) => {
    const { address, identifier, content } = props?.data ?? ({} as IGuardianDataReport)

    const cells = [
        <div className='max-w-[15vw] overflow-x-hidden p-4'>
            <Link url={`${config.EXPLORER_URL}/search/${address}`} target={LinkTarget.Blank}>
                {address.slice(0, 5)}...{address.slice(-24)}
            </Link>
        </div>,
        <div className='max-w-[15vw] overflow-x-hidden p-4'>
            <Text type='body1'>{identifier}</Text>
        </div>,
        <div className='max-w-[30vw] overflow-auto p-4'>
            <pre>{JSON.stringify(content)}</pre>
        </div>,
    ]

    return <TableRow id={props.id}>{cells.map((cell) => cell)}</TableRow>
}

//png
import car from './images/car.png'
import statusConnected from './images/connected.png'
import copiulemu from './images/copiulemu.png'
import statusDisconnected from './images/disconnected.png'
import F1Back from './images/F1_back.png'
import F1Front from './images/F1_front.png'
import flowmeter from './images/flowmeter.png'
import formula from './images/formula.png'
import grapeHarvest from './images/grapeHarvestWaste.jpg'
import greenWaste from './images/green-waste.jpg'
import iota from './images/iota.png'
import liquidWaste from './images/liquidWaste.jpg'
import M1Back from './images/M1_back.png'
import M1Front from './images/M1_front.png'

// Molina assets TEMP
// 0 Degree
import Molina0Overlay1 from './images/Molina/3d/0/V1_1_Overlay.jpg'
import Molina0Overlay2 from './images/Molina/3d/0/V1_2_Overlay.jpg'
import Molina0Overlay3 from './images/Molina/3d/0/V1_3_Overlay.jpg'
import Molina0Default from './images/Molina/3d/0/V1_Default.jpg'
import Molina0Zoom1 from './images/Molina/3d/0/zoom/Z1_1_Overlay.jpg'
import Molina0Zoom2 from './images/Molina/3d/0/zoom/Z1_2_Overlay.jpg'
import Molina0Zoom3 from './images/Molina/3d/0/zoom/Z1_3_Overlay.jpg'

// 90 Degree

// 180 Degree
import Molina180Overlay1 from './images/Molina/3d/180/V3_1_Overlay.jpg'
import Molina180Overlay2 from './images/Molina/3d/180/V3_2_Overlay.jpg'
import Molina180Overlay3 from './images/Molina/3d/180/V3_3_Overlay.jpg'
import Molina180Overlay4 from './images/Molina/3d/180/V3_4_Overlay.jpg'
import Molina180Default from './images/Molina/3d/180/V3_Default.jpg'
import Molina90Overlay1 from './images/Molina/3d/90/V2_1_Overlay.jpg'
import Molina90Overlay2 from './images/Molina/3d/90/V2_2_Overlay.jpg'
import Molina90Overlay3 from './images/Molina/3d/90/V2_3_Overlay.jpg'
import Molina90Overlay4 from './images/Molina/3d/90/V2_4_Overlay.jpg'
import Molina90Default from './images/Molina/3d/90/V2_Default.jpg'
import Molina90Zoom1 from './images/Molina/3d/90/zoom/Z2_1_Overlay.jpg'
import Molina90Zoom2 from './images/Molina/3d/90/zoom/Z2_2_Overlay.jpg'
import N1Back from './images/N1_back.png'
import N1Front from './images/N1_front.png'
import projectImage from './images/project-image.png'
import sheet from './images/sheet.png'
import signInDark from './images/sign-in-dark.png'
import signIn from './images/sign-in.png'
import V1 from './images/V1_Default_0deg.png'
import V2 from './images/V2_Default_90deg.png'
import V3 from './images/V3_Default_180deg.png'
import V4 from './images/V4_Default_270deg.png'
import Y1Back from './images/Y1_back.png'
import Y1Front from './images/Y1_front.png'

export const molina = {
    // 0 Degree
    0: {
        default: Molina0Default,
        overlays: [Molina0Overlay1, Molina0Overlay2, Molina0Overlay3],
        zooms: [Molina0Zoom1, Molina0Zoom2, Molina0Zoom3],
    },
    // 90 Degree
    90: {
        default: Molina90Default,
        overlays: [Molina90Overlay1, Molina90Overlay2, Molina90Overlay3, Molina90Overlay4],
        zooms: [Molina90Zoom1, Molina90Zoom2],
    },
    // 180 Degree
    180: {
        default: Molina180Default,
        overlays: [Molina180Overlay1, Molina180Overlay2, Molina180Overlay3, Molina180Overlay4],
    },
}

export {
    signIn,
    signInDark,
    iota,
    copiulemu,
    projectImage,
    car,
    flowmeter,
    statusConnected,
    statusDisconnected,
    formula,
    V1,
    V2,
    V3,
    V4,
    M1Back,
    M1Front,
    N1Back,
    N1Front,
    F1Back,
    F1Front,
    Y1Back,
    Y1Front,
    greenWaste,
    grapeHarvest,
    liquidWaste,
    sheet,
}

import { formatDate, chunkArray, strToSentenceCase } from '@demia/core'
import { BrandedIcon, EMPTY_FIELD_TEXT, type IconName, StyleContext, Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { Fragment, useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { sheet, statusConnected, statusDisconnected } from '@assets'
import type { EquipmentPath } from '@components/app'
import {
    DEFAULT_EQUIPMENT_PATH,
    ProjectDataSourceImage,
    ProjectLayout,
    ProjectSensorDataTable,
    PUBLIC_PROJECT_ASSET_PATH,
    sortNestedReadings,
} from '@components/app'
import { BarRangeChart, IconButton, LineAreaChart, Pane, Skeleton, Tabs } from '@components/base'
import type { IChartData } from '@components/base/Charts/types.ts'
import { StorageManager } from '@constants'
import type { ISensorContextData, ISensorData } from '@lib/sensor'
import { getSensorDataFromDashboardContext, transformReadings } from '@lib/sensor'
import type { IDataSourceSettings, IUserSettings } from '@lib/user'
import { DEFAULT_NUMBER_OF_DATA_SOURCE_CHARTS_PER_ROW, getDataSourceSettingsForUser, UserContext } from '@lib/user'
import { ProjectDataSourceTitle } from '../ProjectDataSourceTitle'
import classes from './Flowmeter.module.scss'

const nestedDataEmpty = {
    image: sheet,
    active: true,
    infoData: [
        {
            title: 'Sensor ID',
            value: '',
        },
        {
            title: 'Accuracy',
            value: '',
        },
        {
            title: 'Manufacturer',
            value: '',
        },
        {
            title: 'Installed on',
            value: '',
        },
        {
            title: 'Model',
            value: '',
        },
        {
            title: 'Last Calibration',
            value: '',
        },
        {
            title: 'Serial Number',
            value: '',
        },
        {
            title: 'Next Calibration',
            value: '',
        },
    ],
}

const flowmeterDataEmpty: Array<{
    icon: IconName
    text: string
    value: number | string
    valueUnit: string
}> = [
    {
        icon: 'cargo',
        text: 'Latest reading',
        value: '',
        valueUnit: '',
    },
    {
        icon: 'clock-clockwise',
        text: 'Daily readings',
        value: 0,
        valueUnit: '',
    },
    {
        icon: 'chart-bar',
        text: 'Data confidence',
        value: 0,
        valueUnit: '%',
    },
]

const MAXIMUM_NUMBER_OF_CHARTS_PER_ROW: number = 3

export const NestedDataSource: FunctionComponent<{ sensorContext?: ISensorContextData }> = (props) => {
    const { sensorContext } = props
    const { slug } = useParams()
    const url = `/projects/${slug}/data-sources`
    const isDark = useContext(StyleContext).data.theme === 'dark'
    const { data, setData } = useContext(UserContext)

    const [dataSourceSettings, setDataSourceSettings] = useState<IDataSourceSettings>({
        chartsPerRow: DEFAULT_NUMBER_OF_DATA_SOURCE_CHARTS_PER_ROW,
    })

    useEffect(() => {
        const _dataSourceSettings = getDataSourceSettingsForUser(
            data?.user?.['id'],
            slug ?? '',
            sensorContext?.id ?? ''
        )
        if (_dataSourceSettings) {
            setDataSourceSettings(_dataSourceSettings)
        } else {
            saveDataSourceSettings(dataSourceSettings)
        }
    }, [])

    function saveDataSourceSettings(settings: IDataSourceSettings): void {
        const userSettings = {
            ...(data?.settings ?? {}),
            sites: {
                ...(data?.settings?.sites ?? {}),
                [slug ?? '']: {
                    ...(data?.settings?.sites?.[slug ?? ''] ?? {}),
                    dataSources: {
                        ...(data?.settings?.sites?.[slug ?? '']?.dataSources ?? {}),
                        [sensorContext?.id ?? '']: settings,
                    },
                },
            },
        } as IUserSettings
        const persistedUserStore = StorageManager.load('userData') ?? {}
        StorageManager.save('userData', {
            ...persistedUserStore,
            [data?.user?.['id'] ?? '']: {
                ...(persistedUserStore[data?.user?.['id'] ?? ''] ?? {}),
                settings: userSettings,
            },
        })
        setData({
            ...data,
            settings: userSettings,
        })
    }

    function onChartsPerRowClick(shouldIncrement: boolean): void {
        const settings: IDataSourceSettings = {
            chartsPerRow: dataSourceSettings.chartsPerRow + (shouldIncrement ? 1 : -1),
        }
        setDataSourceSettings(settings)
        saveDataSourceSettings(settings)
    }

    const backupUri: EquipmentPath = `${DEFAULT_EQUIPMENT_PATH}${sensorContext?.equipment.id}`
    const imageUri = `${PUBLIC_PROJECT_ASSET_PATH}${sensorContext?.assetUrl || backupUri}`

    const location = useLocation()
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [location])

    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)
    const oneYearAgo = new Date()
    oneYearAgo.setDate(oneYearAgo.getDate() - 365)

    const [nestedData, setNestedData] = useState<
        Array<{
            icon: IconName
            text: string
            value: number | string
            valueUnit: string
        }>
    >(
        flowmeterDataEmpty.map((a) => {
            return { ...a }
        })
    )

    const [nestedInfo, setNestedInfo] = useState(
        nestedDataEmpty.infoData.map((a) => {
            return { ...a }
        })
    )

    const [sensorDataList, setSensorDataList] = useState<{ [key: string]: ISensorData[] }>({})
    const [graphData, setGraphData] = useState<Array<{ equipment: string; unit?: string; data: IChartData[] }>>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (sensorContext) {
            const sorted = transformReadings(sensorContext.readings)
            const newGraphData = sortNestedReadings(sorted, sensorContext.equipment.name).sort((a, b) =>
                a.equipment > b.equipment ? 1 : -1
            )
            setGraphData(newGraphData)

            const readings: { [key: string]: ISensorData[] } = {}
            Object.entries(sorted).forEach((v) => {
                const [key, context] = v
                readings[key] = Object.values(context)
                    .map((v) => {
                        const readings = Array.from(Object.values(v)).map((v) => {
                            const unit = v.unit ? v.unit : sensorContext.equipment.units
                            return getSensorDataFromDashboardContext(v, unit, sensorContext.annotations)
                        })

                        readings.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())

                        return readings
                    })
                    .flat()
            })

            setSensorDataList(readings)
            setLoading(false)
        }
    }, [sensorContext])

    useEffect(() => {
        if (sensorContext) {
            const numbers = [...nestedData]
            numbers[0].value = formatDate(sensorContext.lastUpdated)
            numbers[1].value = Object.entries(sensorContext.readings).filter((v) => {
                const [_, data] = v
                new Date().getTime() - new Date(data.timestamp).getTime() < 1000 * 60 * 60 * 24 /* 24 hours */
            }).length

            numbers[2].value = Math.round(sensorContext.avgcf * 10000) / 10000
            setNestedData(numbers)

            const newData = [...nestedInfo]
            newData[0].value = sensorContext.equipment.id
            newData[1].value = sensorContext.equipment.accuracy + ''
            newData[2].value = sensorContext.equipment.manufacturer
            newData[3].value = sensorContext.equipment.installed + ''
            newData[4].value = sensorContext.equipment.eqType
            // Last calibration
            newData[5].value = 'Never'
            newData[6].value = sensorContext.equipment.serialNo
            // Next calibration
            newData[7].value = 'Soon'
            setNestedInfo(newData)
        }
    }, [sensorContext])

    const TitleComponent = () => {
        return <ProjectDataSourceTitle title={sensorContext?.equipment.name ?? 'Category'} url={url} />
    }

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <Tabs tabHeaders={['Overview', 'Logs']}>
                <article className={classes.flowmeterWrapper}>
                    <div className={`${classes.flowmeterInfo} ${isDark && classes.flowmeterInfoDark}`}>
                        <figure className={classes.image}>
                            <ProjectDataSourceImage
                                uri={imageUri}
                                alt='Nested Image'
                                defaultImage={nestedDataEmpty.image}
                            />
                        </figure>
                        <div className={`${classes.rightSide} ${isDark && classes.rightSideDark}`}>
                            <img
                                src={nestedDataEmpty.active ? statusConnected : statusDisconnected}
                                className={classes.status}
                                alt='Status'
                            />
                            <div className='grid grid-cols-2 gap-x-6 gap-y-4'>
                                {nestedInfo.every((data) => data.value !== undefined)
                                    ? nestedInfo.map((data, index) => (
                                          <div className='' key={index}>
                                              <Text type='body1' color='text-secondary'>
                                                  {data.title}
                                              </Text>
                                              <Text type='body1'>{data.value || EMPTY_FIELD_TEXT}</Text>
                                          </div>
                                      ))
                                    : nestedInfo.map((data, index) => (
                                          <div className='' key={index}>
                                              <Text type='body1' color='text-secondary'>
                                                  {data.title}
                                              </Text>
                                              <Skeleton width={96} />
                                          </div>
                                      ))}
                            </div>
                        </div>
                        <div className='flex flex-row space-x-4'>
                            {nestedData.map((data, index) => (
                                <div
                                    className='w-1/3 bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] border-[1px] border-[var(--stroke-light)] dark:border-[var(--stroke-light-dark)] flex flex-col items-center justify-around p-6 space-y-4'
                                    key={index}
                                >
                                    <BrandedIcon name={data.icon} />
                                    <Text type='body1' color='text-secondary'>
                                        {data.text}
                                    </Text>
                                    <div className='h-full flex flex-col justify-around'>
                                        <Text type='header2' justification='center'>
                                            {data.value}
                                            {typeof data.value === 'number' && (
                                                <>
                                                    {' '}
                                                    <span className='text-text-secondary dark:text-text-secondary-dark'>
                                                        {data.valueUnit}
                                                    </span>
                                                </>
                                            )}
                                        </Text>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Pane>
                        {graphData.length === 1 ? (
                            <div className='p-2'>
                                <BarRangeChart
                                    title={{ title: graphData[0].equipment }}
                                    data={graphData[0].data}
                                    loading={!nestedInfo}
                                />
                            </div>
                        ) : (
                            <Fragment>
                                <div className='flex flex-row items-center justify-between mb-6'>
                                    <Text type='header4'>Readings ({graphData.length})</Text>
                                    <div className='flex flex-row items-center justify-around space-x-4'>
                                        <IconButton
                                            icon='minus'
                                            disabled={dataSourceSettings?.chartsPerRow === 1}
                                            onClick={() => onChartsPerRowClick(false)}
                                        />
                                        <Text type='header4'>
                                            {dataSourceSettings?.chartsPerRow ??
                                                DEFAULT_NUMBER_OF_DATA_SOURCE_CHARTS_PER_ROW}
                                        </Text>
                                        <IconButton
                                            icon='plus'
                                            disabled={
                                                dataSourceSettings?.chartsPerRow === MAXIMUM_NUMBER_OF_CHARTS_PER_ROW
                                            }
                                            onClick={() => onChartsPerRowClick(true)}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col items-center justify-between space-y-6'>
                                    {chunkArray(
                                        Object.entries(graphData),
                                        dataSourceSettings?.chartsPerRow ?? DEFAULT_NUMBER_OF_DATA_SOURCE_CHARTS_PER_ROW
                                    ).map((chunk) => (
                                        <div className='w-full flex flex-row items-center justify-between space-x-6'>
                                            {chunk.map((v, index) => {
                                                const [_, nested] = v
                                                return (
                                                    <div
                                                        style={{ width: `${(1 / chunk.length) * 100.0}%` }}
                                                        key={index}
                                                    >
                                                        {nested.data.length > 100 ? (
                                                            <BarRangeChart
                                                                data={nested.data}
                                                                title={{ title: strToSentenceCase(nested.equipment) }}
                                                                loading={!nestedInfo}
                                                            />
                                                        ) : (
                                                            <LineAreaChart
                                                                title={{ title: strToSentenceCase(nested.equipment) }}
                                                                data={nested.data}
                                                                loading={!nestedInfo}
                                                                unit={nested.unit}
                                                                start={oneYearAgo}
                                                                end={new Date()}
                                                            />
                                                        )}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    ))}
                                </div>
                            </Fragment>
                        )}
                    </Pane>
                </article>
                {Object.entries(sensorDataList)
                    .sort(([k1, _v1], [k2, _v2]) => (k1 > k2 ? 1 : -1))
                    .map((v, index) => {
                        const [key, value] = v
                        return (
                            <Fragment key={index}>
                                <ProjectSensorDataTable
                                    title={strToSentenceCase(key)}
                                    loading={loading}
                                    sensorDataList={value}
                                />
                            </Fragment>
                        )
                    })}
            </Tabs>
        </ProjectLayout>
    )
}

import { ErrorHandler } from '@demia/core'
import type { IHardcodedStripeProduct } from '@demia/platform'
import { STRIPE_PRODUCTS, StripeApiService } from '@demia/platform'
import { Icon } from '@demia/ui-kit'
import { useStripe } from '@stripe/react-stripe-js'
import type { StripeEmbeddedCheckout } from '@stripe/stripe-js'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import type { IProjectDetails } from '@lib/project'
import classes from './Stripe.module.scss'

export const StripePricingTable: FunctionComponent<{ customerId: string; projectId: string }> = (customerProps) => {
    const stripe = useStripe()

    const [loading] = useState<boolean>(stripe === null)
    const [error, setError] = useState<string | null>(null)
    const [buttonLoading, setButtonLoading] = useState<boolean[]>(STRIPE_PRODUCTS.map(() => false))
    const [checkoutComponent, setCheckoutComponent] = useState<StripeEmbeddedCheckout | null>(null)
    const updateButtonLoading = (index: number, value: boolean) => {
        const buttonLoadingCopy = [...buttonLoading]
        buttonLoadingCopy[index] = value
        setButtonLoading(buttonLoadingCopy)
    }

    const handleCheckout = async (product: IHardcodedStripeProduct, index: number) => {
        updateButtonLoading(index, true)
        setError(null)

        checkoutComponent?.unmount()
        setCheckoutComponent(null)

        const [data, error] = await StripeApiService.createPaymentIntent(
            customerProps.customerId,
            product.productId,
            product.feeId,
            window.location.href,
            customerProps.projectId
        )
        if (error) {
            ErrorHandler.handleApiError(error, { notifyUser: false })
            setError('Checkout not created. ' + error.message)
        } else {
            if (data?.sessionId) {
                stripe
                    ?.initEmbeddedCheckout({ clientSecret: data.sessionId })
                    .then((checkout) => {
                        checkout.mount('#checkout')
                        setCheckoutComponent(checkout)
                    })
                    .catch((err) => {
                        setError('Checkout not created. ' + err.message)
                    })
            } else {
                setError('Session ID not created for checkout')
            }
        }

        updateButtonLoading(index, false)
    }

    return (
        <div>
            <div className={classes.checkoutMount} id={'checkout'}></div>
            {checkoutComponent ? (
                <button
                    className={classes.paymentButton}
                    onClick={() => {
                        checkoutComponent?.unmount()
                        setCheckoutComponent(null)
                    }}
                >
                    Back
                </button>
            ) : (
                <div>
                    <div className={classes.productTable}>
                        {!loading &&
                            STRIPE_PRODUCTS.map((product, i) => {
                                return (
                                    <div key={product.productId} className={classes.product}>
                                        <Icon name='brand-logo' />
                                        <h2>{product.name}</h2>
                                        <h3>Integration Fee</h3>
                                        <p>${product.feePrice / 100.0} One Time</p>
                                        <h3>Subscription Price</h3>
                                        <p>${product.price / 100.0} / Month</p>
                                        <button
                                            className={classes.paymentButton}
                                            disabled={buttonLoading[i]}
                                            onClick={() => handleCheckout(product, i)}
                                        >
                                            Subscribe
                                        </button>
                                    </div>
                                )
                            })}
                        {loading && <p>We're having trouble loading the product list. Please try refreshing...</p>}
                    </div>
                    <div className={classes.error}>{error && <p>Error: {error}</p>}</div>
                </div>
            )}
        </div>
    )
}

export const CheckoutForm: FunctionComponent<{ customerId: string; site: IProjectDetails }> = (customerProps) => {
    const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false)

    return (
        <div className={classes.checkoutForm}>
            <h2 className={classes.title}>{customerProps.site.projectName}</h2>
            {showPaymentForm && (
                <div>
                    <StripePricingTable
                        customerId={customerProps.customerId}
                        projectId={customerProps.site.projectId}
                    />
                </div>
            )}
            {!showPaymentForm && (
                <div className={classes.product}>
                    <h2>Upgrade to a production tier (testing)</h2>
                    <button className={classes.paymentButton} onClick={() => setShowPaymentForm(true)}>
                        Subscribe
                    </button>
                </div>
            )}
        </div>
    )
}

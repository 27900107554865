import { dateToReadable, truncateString } from '@demia/core'
import { Copyable, Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import type { ITableRowProps } from '@components/base'
import { TableRow } from '@components/base'
import type { IDataAnnotation } from '@lib/sensor'

export const ProjectSensorAnnotationTableRow: FunctionComponent<ITableRowProps<IDataAnnotation>> = (annotation) => {
    const { id, key, host, kind, signature, isSatisfied, timestamp } = annotation?.data ?? ({} as IDataAnnotation)
    const cells = [
        <div className='max-w-[15vw]'>
            <Copyable value={id}>
                <Text type='body1'>{truncateString(id, 16)}</Text>
            </Copyable>
        </div>,
        <div className='max-w-[20vw]'>
            <Copyable value={key}>
                <Text type='body1'>{truncateString(key, 16)}</Text>
            </Copyable>
        </div>,
        <div className='max-w-[15vw]'>
            <Text type='body1'>{host}</Text>
        </div>,
        <div className='max-w-[10vw]'>
            <Text type='body1'>{kind}</Text>
        </div>,
        <div className='max-w-[15vw]'>
            <Copyable value={signature}>
                <Text type='body1'>{truncateString(signature, 16)}</Text>
            </Copyable>
        </div>,
        <div className='max-w-[10vw]'>
            <Text type='body1'>{isSatisfied ? 'True' : 'False'}</Text>
        </div>,
        <div className='max-w-[15vw]'>
            <Text type='body1'>{dateToReadable(new Date(timestamp))}</Text>
        </div>,
    ]

    return <TableRow id={id}>{cells}</TableRow>
}

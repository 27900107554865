import { StyleContext } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useContext } from 'react'
import { MolinaSensorMap3D, ProjectInfo, ProjectRecentActivity, ProjectStatistic, SitePlan } from '@components/app'
import { Skeleton } from '@components/base'
import type { IProjectDetails, IProjectNotification, IProjectStatistic } from '@lib/project'
import classes from './ProjectDashboardPage.module.scss'

interface IProjectDigitalTwinDashboardProps {
    details: IProjectDetails
    notifications: IProjectNotification[]
    statistics: IProjectStatistic[]
}

export const ProjectDigitalTwinDashboard: FunctionComponent<IProjectDigitalTwinDashboardProps> = (props) => {
    const { details, notifications, statistics } = props
    const isDark = useContext(StyleContext).data.theme === 'dark'

    function mapDataToProjectInfo() {
        if (details && details.projectInfo && details.projectInfo) {
            const { projectId, type, methodology, projectDev } = details.projectInfo
            return {
                projectInfo: {
                    projectId,
                    type,
                    methodology,
                    projectDev,
                },
                location: details.location,
            }
        } else {
            return {
                projectInfo: {
                    projectId: '',
                    type: '',
                    methodology: '',
                    projectDev: '',
                },
                location: {
                    address: '',
                    lat: 0.0,
                    lon: 0.0,
                },
            }
        }
    }

    const ProjectDigitalTwin = () => {
        return details ? (
            details.projectInfo.projectId === 'BEM' ? (
                <MolinaSensorMap3D />
            ) : (
                details.projectInfo.projectId === 'Copiulema' && <SitePlan />
            )
        ) : (
            <Skeleton height={512} />
        )
    }

    return (
        <div className='flex flex-col space-y-8'>
            <div className={classes.project}>
                <div>
                    <div className={classes.content}>
                        <div className={`${classes.innerContent} ${isDark && classes.innerContentDark}`}>
                            <div className={classes.topContent}>
                                <ProjectInfo {...mapDataToProjectInfo()} />
                                <ProjectRecentActivity
                                    loading={!notifications}
                                    activity={notifications as IProjectNotification[]}
                                />
                            </div>
                            <div className={classes.statisticWrapper}>
                                {statistics.map((i: any, index: any) => (
                                    <ProjectStatistic key={index} statistic={i} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ProjectDigitalTwin />
        </div>
    )
}

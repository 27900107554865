import { StyleContext } from '@demia/ui-kit'
import _ from 'lodash'
import type { ChangeEvent, ChangeEventHandler, FunctionComponent } from 'react'
import { useContext, useRef, useState } from 'react'
import { IconButton } from '../Button'
import classes from './SearchInput.module.scss'

export const SearchInput: FunctionComponent<{ onChange: ChangeEventHandler<HTMLInputElement> }> = ({ onChange }) => {
    const isDark = useContext(StyleContext).data.theme === 'dark'
    const [isActive, setIsActive] = useState<boolean>(false)
    const inputRef = useRef<HTMLInputElement>(null)

    const setActive = () => {
        setIsActive(true)
        inputRef.current?.focus()
    }

    const setNotActive = () => {
        setIsActive(false)
        inputRef?.current?.blur()
    }

    const toggleActive = () => {
        !isActive ? setActive() : setNotActive()
    }

    const thisOnChange = _.debounce((event: ChangeEvent<HTMLInputElement>) => {
        onChange(event)
    }, 500)

    return (
        <div
            className={`${classes.searchInputContainer} ${isDark && classes.searchInputContainerDark} ${isActive ? classes.active : ''}`}
            onMouseLeave={setNotActive}
        >
            <IconButton icon='magnifying-glass' onClick={toggleActive} disabled={isActive} />
            <input
                ref={inputRef}
                placeholder='Search'
                type='text'
                onChange={thisOnChange}
                onFocus={setActive}
                onBlur={setNotActive}
            />
        </div>
    )
}

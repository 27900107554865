import type { Style } from '@demia/ui-kit'
import { Icon, StyleContext, Text } from '@demia/ui-kit'
import type { MouseEvent, FunctionComponent } from 'react'
import { useContext, useState, useRef } from 'react'
import { ProjectDataSourcePopup, ProjectRecentActivity } from '@components/app'
import { GoogleMaps } from '@components/app/Project/GoogleMaps.tsx'
import sitePlanClasses from '@components/app/SitePlan/SitePlan.module.scss'
import { Button, Popover } from '@components/base'
import type { IProjectDetails, IProjectNotification } from '@lib/project'

interface IProjectMapDashboardProps {
    details: IProjectDetails
    notifications: IProjectNotification[]
}

export const ProjectFullscreenMapDashboard: FunctionComponent<IProjectMapDashboardProps> = (props) => {
    const { details, notifications } = props
    const { projectInfo } = details

    const [showNotifications, setShowNotifications] = useState(false)
    const [hadOutsideClick, setHadOutsideClick] = useState(false)
    const notificationsRef = useRef<HTMLDivElement | null>(null)

    function onNotificationsClick(event?: MouseEvent<HTMLButtonElement>): void {
        if (event?.target) {
            if (hadOutsideClick) {
                // Do nothing.
            } else {
                setShowNotifications(!showNotifications)
            }
        } else {
            setShowNotifications(false)
            setHadOutsideClick(true)
            setTimeout(() => {
                setHadOutsideClick(false)
            }, 200)
        }
    }

    const isDark = useContext(StyleContext).data.theme === 'dark'
    const style: Style = {
        fontFamily: `var(--font)`,
    }

    const [showDataSources, setShowDataSources] = useState(false)
    const dcf = {
        type: 'chart',
        amount: details.avgDcf ?? '0',
        unit: '%',
        text: 'Data Confidence',
    }

    function toggleDataSourcePopup(): void {
        setShowDataSources(!showDataSources)
    }

    return (
        <div className={sitePlanClasses.sitePlan}>
            <div className='w-full h-[128px] absolute z-50 flex flex-row items-center justify-between space-x-4 bg-transparent backdrop-blur border-b-[1px] border-stroke'>
                <div className='flex flex-col items-start justify-between space-y-4 pl-6'>
                    <div className='flex flex-row items-end space-x-2'>
                        <Text type='header3' color='text-invert' freezeColor>
                            PROJECT TYPE:
                        </Text>
                        <Text type='header4' color='text-invert' freezeColor>
                            {projectInfo.type}
                        </Text>
                    </div>
                    <div className='flex flex-row items-end space-x-2'>
                        <Text type='header3' color='text-invert' freezeColor>
                            PROJECT DEVELOPER:
                        </Text>
                        <Text type='header4' color='text-invert' freezeColor>
                            {projectInfo.projectDev}
                        </Text>
                    </div>
                </div>
                <div className='flex flex-col items-start justify-between space-y-4'>
                    <div className='flex flex-row items-end space-x-2'>
                        <Text type='header3' color='text-invert' freezeColor>
                            METHODOLOGY:
                        </Text>
                        <Text type='header4' color='text-invert' freezeColor>
                            {projectInfo.methodology}
                        </Text>
                    </div>
                    <div className='flex flex-row items-end space-x-2'>
                        <Text type='header3' color='text-invert' freezeColor>
                            PROJECT ID:
                        </Text>
                        <Text type='header4' color='text-invert' freezeColor>
                            {projectInfo.projectId}
                        </Text>
                    </div>
                </div>
                <div className='w-1/4 h-full flex flex-col items-end justify-center space-y-6'>
                    <div className='w-full py-2 bg-surface-0'>
                        <Text type='header3' color='text-primary' freezeColor justification='center'>
                            DATA CONFIDENCE
                        </Text>
                    </div>
                    <div className='w-full pr-6 pb-6 flex flex-row items-center justify-center space-x-4'>
                        <div className='bg-surface-0 p-2 rounded-full'>
                            <Icon name='chart-bar' color='text-brand-secondary' />
                        </div>
                        <p className='font-[600] text-[32px] leading-8 text-text-invert/[.9]' style={{ ...style }}>
                            {dcf.amount}%
                        </p>
                    </div>
                </div>
            </div>
            <div className='w-full h-[64px] top-[128px] right-2 flex flex-row items-center justify-end absolute z-50 bg-transparent'>
                <div ref={notificationsRef} className='w-auto'>
                    <Button variant='outline' size='medium' onClick={onNotificationsClick}>
                        <Icon name='list' color={isDark ? 'text-primary-dark' : 'text-primary'} />
                    </Button>
                    {showNotifications && notificationsRef && (
                        <Popover
                            anchor={notificationsRef.current as HTMLDivElement}
                            event='click'
                            onOutsideClick={onNotificationsClick}
                        >
                            <ProjectRecentActivity loading={!notifications} activity={notifications} />
                        </Popover>
                    )}
                </div>
            </div>
            <GoogleMaps {...{ location: details.location, style: { height: '80vh', width: '100%' } }} />
            <div
                className='absolute transform -translate-x-1/2 -translate-y-full top-1/2 left-1/2 min-w-12 min-h-12 z-[100]'
                onClick={() => toggleDataSourcePopup()}
            >
                <div className='absolute cursor-pointer w-12 h-12' onClick={() => toggleDataSourcePopup()} />
                {showDataSources && (
                    <ProjectDataSourcePopup details={details} onClose={() => setShowDataSources(false)} />
                )}
            </div>
        </div>
    )
}

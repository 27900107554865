import type { SortDirection } from '@demia/core'
import { dateToReadable, sortPrimitives } from '@demia/core'
import { Text } from '@demia/ui-kit'
import type { FunctionComponent, RefObject } from 'react'
import { Fragment, useEffect, useRef, useState } from 'react'
import type { ITableRowProps, ITableField } from '@components/base'
import { Table, TableRow, Link, LinkTarget, OutsideAlerter, TableTitle } from '@components/base'
import { config } from '@constants'
import type { IDataAnnotation, ISensorData } from '@lib/sensor'
import { getNestedReadingValue } from '@lib/sensor'
import { ProjectSensorAnnotationTableRow } from './ProjectSensorAnnotationTableRow'

const PROJECT_SENSOR_ANNOTATION_TABLE_FIELDS: ITableField[] = [
    {
        text: 'Id',
        key: 'id',
        sortingFunction: (a: IDataAnnotation, b: IDataAnnotation, direction: SortDirection) =>
            sortPrimitives(a.id, b.id, direction),
        sortByDefault: true,
    },
    {
        text: 'Key',
        key: 'key',
        sortingFunction: (a: IDataAnnotation, b: IDataAnnotation, direction: SortDirection) =>
            sortPrimitives(a.key, b.key, direction),
    },
    {
        text: 'Host',
        key: 'host',
        sortingFunction: (a: IDataAnnotation, b: IDataAnnotation, direction: SortDirection) =>
            sortPrimitives(a.host, b.host, direction),
    },
    {
        text: 'Kind',
        key: 'kind',
        sortingFunction: (a: IDataAnnotation, b: IDataAnnotation, direction: SortDirection) =>
            sortPrimitives(a.kind, b.kind, direction),
    },
    {
        text: 'Signature',
        key: 'signature',
        sortingFunction: (a: IDataAnnotation, b: IDataAnnotation, direction: SortDirection) =>
            sortPrimitives(a.signature, b.signature, direction),
    },
    {
        text: 'Is Satisfied',
        key: 'isSatisfied',
        sortingFunction: (a: IDataAnnotation, b: IDataAnnotation, direction: SortDirection) =>
            sortPrimitives(a.isSatisfied, b.isSatisfied, direction),
    },
    {
        text: 'Timestamp',
        key: 'timestamp',
        sortingFunction: (a: IDataAnnotation, b: IDataAnnotation, direction: SortDirection) =>
            sortPrimitives(a.timestamp, b.timestamp, direction),
    },
]

export const ProjectSensorDataTableRow: FunctionComponent<ITableRowProps<ISensorData>> = (props) => {
    const { data, displayAbove = false, id } = props
    const { timestamp, value, unit, score, address, annotations } = data ?? ({} as ISensorData)
    const [showAnnotations, setShowAnnotations] = useState<boolean>(false)
    const annotationTableRef = useRef<HTMLDivElement>(null)

    let rowHeight = 0

    function onRef(ref: RefObject<HTMLTableRowElement>): void {
        rowHeight = ref?.current?.getBoundingClientRect()?.height ?? 0
    }

    useEffect(() => {
        if (displayAbove) {
            const tableHeight = annotationTableRef?.current?.getBoundingClientRect()?.height ?? 0
            /**
             * NOTE: We add 1px here to compensate for the border between rows.
             */
            annotationTableRef?.current?.style.setProperty('transform', `translateY(${-(rowHeight + tableHeight)}px)`)
        }
    }, [showAnnotations, displayAbove])

    const cells = [
        <div className='cursor-pointer' onClick={() => setShowAnnotations(!showAnnotations)}>
            <Text type='body1'>{dateToReadable(timestamp)}</Text>
        </div>,
        <div className='cursor-pointer' onClick={() => setShowAnnotations(!showAnnotations)}>
            <Text type='body1'>
                {getNestedReadingValue(value)[1]} {unit.replace('[', '').replace(']', '')}
            </Text>
        </div>,
        <div className='cursor-pointer' onClick={() => setShowAnnotations(!showAnnotations)}>
            <Text type='body1'>{score}</Text>
        </div>,
        <Link url={`${config.EXPLORER_URL}/search/${address}`} target={LinkTarget.Blank}>
            {address.slice(0, 5)}...{address.slice(-24)}
        </Link>,
    ]

    return showAnnotations ? (
        <Fragment key={id}>
            <TableRow id={id} onRef={onRef} hideBorders>
                {cells}
            </TableRow>
            <OutsideAlerter onClick={() => setShowAnnotations(false)}>
                <div
                    ref={annotationTableRef}
                    className='absolute shadow-md z-50 w-[70vw] bg-surface-1 dark:bg-surface-1-dark clipped-right animate-fade-in'
                >
                    <Table
                        fields={PROJECT_SENSOR_ANNOTATION_TABLE_FIELDS}
                        data={annotations}
                        RowTemplate={(_props) =>
                            ProjectSensorAnnotationTableRow({ ..._props, id: annotations.length.toString() })
                        }
                        TitleTemplate={() => TableTitle({ title: 'Annotations' })}
                        unClipped
                        disableFooter
                    />
                </div>
            </OutsideAlerter>
        </Fragment>
    ) : (
        <TableRow id={id}>{cells}</TableRow>
    )
}

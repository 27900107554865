import { StyleContext } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useContext } from 'react'
import { SidebarTab } from '@components/base'
import classes from '@components/base/Sidebar/Sidebar.module.scss'
import { AppRoute, buildRoute } from '@lib/routing'

interface IProfileSidebarNavigationProps {}

export const ProfileSidebarNavigation: FunctionComponent<IProfileSidebarNavigationProps> = (_) => {
    const isDark = useContext(StyleContext).data.theme === 'dark'
    const pathname = window?.location?.pathname ?? ''

    return (
        <nav className={`${classes['sidebar']} ${isDark && classes['sidebar-dark']}`}>
            <SidebarTab
                url={buildRoute(AppRoute.Overview)}
                icon='speedometer'
                text='Overview'
                isActive={pathname.includes('overview')}
            />
            <SidebarTab
                url={buildRoute(AppRoute.AddStream)}
                icon='map-pin'
                text='Add Project'
                isActive={pathname.includes('add-stream')}
            />
            <SidebarTab
                url={buildRoute(AppRoute.Profile)}
                icon='user'
                text='Profile'
                isActive={pathname.includes('profile')}
            />
            <SidebarTab
                url={buildRoute(AppRoute.Identity)}
                icon='identity'
                text='Identity'
                isActive={pathname.includes('identity')}
            />
        </nav>
    )
}

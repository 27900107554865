import type { IconName } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useContext } from 'react'
import { usePopup } from '@components/base'
import type { IActionMenuItemProps } from '@components/base/Menu'
import { ActionMenu } from '@components/base/Menu'
import { FEATURES } from '@features'
import { ProjectContext, ProjectDocumentType } from '@lib/project'
import type { ISensorContextData, ISensorSource, IUpdateEquipmentDto } from '@lib/sensor'
import { UserContext } from '@lib/user'
import type { EquipmentPath } from './constants'
import { DEFAULT_EQUIPMENT_PATH, PUBLIC_PROJECT_ASSET_PATH } from './constants'
import { EditSiteDataSourcePopup, ExportDataSourceDataPopup, ProjectDocumentationPopup } from './popups'

interface ISiteDataSourceMenuProps {
    dataSource: ISensorSource
}

export const SiteDataSourceMenu: FunctionComponent<ISiteDataSourceMenuProps> = (props) => {
    const { dataSource } = props
    const { openPopup, closePopup } = usePopup()
    const projectId = useContext(ProjectContext)?.data?.details?.projectId
    const { data, setData } = useContext(UserContext)
    const site = (data?.sites ?? []).find((s) => s.projectId === projectId)
    const dataSourceContext = site?.sensors?.sensors?.[dataSource.url] ?? ({} as ISensorContextData)

    function onDataSourceUpdated(payload: IUpdateEquipmentDto): void {
        const updateData = { ...data }
        if (updateData && site && dataSourceContext) {
            site.sensors.sensors[dataSource.url].equipment.name = payload.name
            site.sensors.sensors[dataSource.url].equipment.eqType = payload.eqType
            site.sensors.sensors[dataSource.url].lastUpdated = new Date().toISOString()
            updateData.sites = updateData.sites.map((s) => (s.projectId === projectId ? site : s))
            setData(updateData)
        }
    }

    const backupUri: EquipmentPath = `${DEFAULT_EQUIPMENT_PATH}${dataSourceContext?.equipment.id}`
    const imageUri = `${PUBLIC_PROJECT_ASSET_PATH}${dataSourceContext?.assetUrl || backupUri}`

    const siteDataSourcesTableMenuItems: IActionMenuItemProps[] = [
        {
            title: 'Change picture',
            icon: 'image',
            onClick: () =>
                openPopup({
                    content: (
                        <ProjectDocumentationPopup
                            siteId={projectId ?? ''}
                            onClose={closePopup}
                            uploadType={ProjectDocumentType.Asset}
                            fileName={imageUri.split('/').pop()}
                        />
                    ),
                }),
        },
        {
            title: 'Edit source',
            icon: 'pencil',
            onClick: () =>
                openPopup({
                    content: (
                        <EditSiteDataSourcePopup
                            siteId={projectId ?? ''}
                            dataSource={dataSource}
                            dataSourceContext={dataSourceContext}
                            onUpdate={onDataSourceUpdated}
                            onClose={closePopup}
                        />
                    ),
                }),
        },
        ...(FEATURES.project.dataSources.exportData.enabled
            ? [
                  {
                      title: 'Export data',
                      icon: 'export' as IconName,
                      onClick: () =>
                          openPopup({
                              content: <ExportDataSourceDataPopup dataSourceContext={dataSourceContext} />,
                          }),
                  },
              ]
            : []),
        /**
         * CAUTION: For now we are NOT allowing data sources to be removed.
         */
        // {
        //     title: 'Remove source',
        //     onClick: () =>
        //         openPopup({
        //             content: <div></div>,
        //         }),
        //     variant: 'danger',
        // },
    ]

    return <ActionMenu items={siteDataSourcesTableMenuItems} />
}

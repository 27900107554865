import { Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { TableRow } from './TableRow.tsx'
import type { ITableRowProps } from './types.ts'

export const TextTableRow: FunctionComponent<ITableRowProps<string[]>> = (props) => {
    const entries = props?.data ?? []
    return (
        <TableRow id={props?.id}>
            {entries.map((entry, index) => (
                <Text key={`entry-${index}`} type='body1'>
                    {entry}
                </Text>
            ))}
        </TableRow>
    )
}

import type { SortDirection } from '@demia/core'
import { sortPrimitives } from '@demia/core'
import type { FunctionComponent } from 'react'
import type { ITableField } from '@components/base/Table'
import { TableTitle, Table } from '@components/base/Table'
import type { ISensorData } from '@lib/sensor'
import { ProjectSensorDataTableRow } from './ProjectSensorDataTableRow.tsx'

interface IProjectSensorDataTableProps {
    loading: boolean
    title?: string
    sensorDataList: ISensorData[]
}

const PROJECT_SENSOR_DATA_TABLE_FIELDS: ITableField[] = [
    {
        text: 'Timestamp',
        key: 'timestamp',
        sortingFunction: (a: ISensorData, b: ISensorData, direction: SortDirection) =>
            sortPrimitives(a.timestamp, b.timestamp, direction),
        sortByDefault: true,
    },
    {
        text: 'Value',
        key: 'value',
        sortingFunction: (a: ISensorData, b: ISensorData, direction: SortDirection) =>
            sortPrimitives(a.value, b.value, direction),
    },
    {
        text: 'Score',
        key: 'score',
        sortingFunction: (a: ISensorData, b: ISensorData, direction: SortDirection) =>
            sortPrimitives(a.score, b.score, direction),
    },
    {
        text: 'Address',
        key: 'address',
        sortingFunction: (a: ISensorData, b: ISensorData, direction: SortDirection) =>
            sortPrimitives(a.address, b.address, direction),
    },
]

export const ProjectSensorDataTable: FunctionComponent<IProjectSensorDataTableProps> = (props) => {
    const { loading, sensorDataList, title } = props

    function getTableTitle(): string {
        return title ? title : `${sensorDataList.length} ${sensorDataList.length === 1 ? 'Record' : 'Records'}`
    }

    return (
        <Table
            fields={PROJECT_SENSOR_DATA_TABLE_FIELDS}
            data={sensorDataList}
            RowTemplate={(p) => ProjectSensorDataTableRow({ ...p, id: sensorDataList.length.toString() })}
            TitleTemplate={() => TableTitle({ title: getTableTitle(), loading })}
            loading={loading}
            unClipped={true}
        />
    )
}

import { ErrorHandler } from '@demia/core'
import type { FormEvent, FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useNavigate, useParams } from 'react-router-dom'
import { ProjectLayout } from '@components/app'
import { Button, Pane, PasswordInput, TextInput } from '@components/base'
import type { IHederaProfile } from '@lib/hedera'
import { HederaApiService } from '@lib/hedera'

export const RegisterProjectGuardianPage: FunctionComponent = () => {
    const { slug } = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [hederaProfile, setHederaProfile] = useState<IHederaProfile | null>(null)

    async function loadHederaProfile(): Promise<void> {
        setLoading(true)
        const [data, error] = await HederaApiService.getProfile()
        if (error) {
            ErrorHandler.handleApiError(error)
        } else {
            setHederaProfile(data)
        }
        setLoading(false)
    }

    useEffect(() => {
        void loadHederaProfile()
    }, [])

    async function onLinkGuardianUser(event: FormEvent<HTMLFormElement>) {
        if (loading) {
            return
        } else {
            setLoading(true)
            event.preventDefault()

            const form = event.target as typeof event.target & {
                username: { value: string }
                password: { value: string }
            }

            const [data, error] = await HederaApiService.getLink(form.username.value, form.password.value)
            if (error) {
                ErrorHandler.handleApiError(error)
            } else {
                setHederaProfile(data)
            }
        }
    }

    async function onAddProjectGuardianSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
        if (loading) {
            return
        } else {
            setLoading(true)
            event.preventDefault()

            const form = event.target as typeof event.target & {
                operatorId: { value: string }
                operatorKey: { value: string }
                policy: { value: string }
            }

            const [_, error] = await HederaApiService.registerGuardian(slug ?? '', {
                operatorId: form.operatorId.value,
                operatorKey: form.operatorKey.value,
                policy: form.policy.value,
            })
            if (error) {
                ErrorHandler.handleApiError(error)
            } else {
                navigate(`/projects/${slug}/guardian`)
            }
        }
    }

    return (
        <ProjectLayout title='Register Guardian'>
            <Pane>
                {!hederaProfile ? (
                    loading ? (
                        <Skeleton width={192} height={18} />
                    ) : (
                        <div className='flex flex-col space-y-4'>
                            <h3>Link your Hedera Guardian account</h3>
                            <form
                                onSubmit={onLinkGuardianUser}
                                title={'Link your Hedera Guardian Account'}
                                className='flex flex-col space-y-4'
                            >
                                <TextInput id='username' label='Username' required autofocus />
                                <PasswordInput id='password' label='Password' required />
                                <div className='pt-4'>
                                    <Button type='submit' width='1/2' onClick={() => {}}>
                                        {loading ? 'Linking…' : 'Link Guardian'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )
                ) : (
                    <form
                        onSubmit={(event) => void onAddProjectGuardianSubmit(event)}
                        className='flex flex-col space-y-4'
                    >
                        <TextInput id='operatorId' label='Operator ID' required />
                        <PasswordInput id='operatorKey' label='Operator Key' required />
                        <TextInput id='policy' label='Policy' required />
                        <div className='pt-4'>
                            <Button type='submit' width='1/2' onClick={() => {}}>
                                {loading ? 'Registering…' : 'Register Guardian'}
                            </Button>
                        </div>
                    </form>
                )}
            </Pane>
        </ProjectLayout>
    )
}

import type { IFile, IListFilesContext, Result } from '@demia/core'
import type { AnalyticsParameter, IAnalyticsConfiguration, IAnalyticsProfile } from '@lib/analytics'
import type { IDataSourceEquipment, ISensorOverview, IUpdateEquipmentDto, NestedReadingValue } from '@lib/sensor'

const projectNotificationTypes = [
    'Login',
    'SubRequest',
    'NewSite',
    'UpdatedProfileData',
    'SiteRecovered',
    'SiteFailedToCreate',
    'SiteRemoved',
] as const
export type ProjectNotificationType = (typeof projectNotificationTypes)[number]

export interface IGHGInfo {
    value: string
    data: string[]
    unit: string
    label: string
}

export interface INewProjectRequest {
    address: string
    author: string
    subIdentifier: string
    project: INewSite
}

export interface INewSite {
    id: string
    name: string
    location: ISiteLocation
    sensors: Array<[string, IDataSourceEquipment]>
    project: IProjectInfoData
    announcement: string
}

export interface IProject {
    name: string
    slug: string
    type: string
    methodology: string
    developer: string
    id: string
    activity: IProjectActivity[]
    emissions: string
    confidence: string
    statistics: IProjectStatistic[]
}

export interface IProjectActivity {
    initials: string
    name: string
    action: string
    timestamp: string
}

export interface IProjectApiCache {
    setProjects(projects: IProjectSlugAccordion[]): void
    getProjects(): IProjectSlugAccordion[]
    clearProjects(): void
}

export interface IProjectSlugAccordion {
    projectName: string
    slug: string
    projectId: string
}

export interface IProjectApiService {
    getProject(siteId: string): Promise<Result<IProjectDetails>>
    getProjects(): Promise<Result<IProjectSites>>
    getNotifications(): Promise<Result<{ notifications: IProjectNotification[] }>>

    addSite(project: INewProjectRequest): Promise<Result<void>>
    updateSite(siteId: string, sitePayload: IUpdateSiteDto): Promise<Result<unknown>>
    removeSite(siteId: string): Promise<Result<void>>

    addSensor(siteId: string, sensor: IDataSourceEquipment): Promise<Result<void>>
    updateSensor(siteId: string, sensorId: string, sensor: IUpdateEquipmentDto): Promise<Result<void>>

    uploadFile(siteId: string, files: File[], isInheritableAsset?: boolean): Promise<Result<IListFilesContext>>
    downloadFile(siteId: string, filename: string): Promise<Result<Blob>>
    deleteFile(siteId: string, filename: string): Promise<Result<void>>
    listFiles(siteId: string): Promise<Result<IListFilesContext>>
    listAssets(siteId: string): Promise<Result<IListAssetsContext>>
    getFilesMetadata(siteId: string): Promise<Result<IListFilesContext>>
}

export interface IProjectContextData {
    details?: IProjectDetails
    dateSelection: IProjectDateSelection
    analyticsConfiguration?: IAnalyticsConfiguration
}

export interface IProjectDateSelection {
    startDate: Date
    endDate: Date
}

export interface IProjectDetails {
    projectImage: string
    projectId: string
    projectAnnouncement: string
    projectName: string
    projectInfo: IProjectInfoData
    location: ISiteLocation
    ghgLast30Days: IGHGInfo
    ghgAnnual: IGHGInfo
    records: { [key: string]: IProjectRecord }
    sensors: ISensorOverview
    notifications: IProjectNotification[]
    link: string
    avgDcf?: string
    stateData: IProjectStateData
    sensorsOnline: number
    loading: boolean
    profiles?: IAnalyticsProfile[]
    // List of all assets linked to this project, as seen in the documents page assets tab
    assets?: IListAssetsContext
    // Specific project overview page asset url (Unused atm, future project header picture)
    assetUrl?: string
}

export interface IProjectDocument {
    file: IFile
}

export interface IProjectInfoData {
    projectId: string
    type: string
    projectDev: string
    methodology: string
}

export interface IProjectNotification {
    user: string
    notification_type: ProjectNotificationType
    message: string
    timestamp: Date
}

export interface IProjectSites {
    sites: IProjectDetails[]
}

export interface IProjectStateData {
    valueSets: IProjectStateValueSets
    calcData: any[]
}

export interface IProjectStateValueSets {
    [key: string]: IProjectStateValueSet
}

export interface IProjectStateValueSet {
    title: string
    label: string
    total: number
    avg: number
    data: [Date, number][]
    params: AnalyticsParameter[]
    inputs: {
        [key: string]: [Date, number][]
    }
}

interface IProjectRecord {
    id: string
    sensorId: string
    dataTimestamp: string
    sum: NestedReadingValue
    company: string
    simulated: boolean
    avgVal: number
    totalCount: number
    residue: string
}

export interface IProjectStatistic {
    type: string
    amount: string
    unit: string
    text: string
    percentage?: string
}

export interface ISiteLocation {
    address: string
    lat: number
    lon: number
}

export interface IPin {
    sensorId: string
    sensorSlug: string
    class: string
    class2: string
    title: string
    model?: string
    flow?: string
    average?: string
}

export interface IUpdateSiteDto {
    name: string
    developer: string
    type: string
}

export enum AssetType {
    Profile = 'Profile',
    Site = 'Site',
    Sensor = 'Sensor',
    Equipment = 'Equipment',
    Custom = 'Custom',
    Link = 'Link',
}

export interface IAsset {
    asset: {
        type: AssetType
        name: string
    }
    file: IFile
}

export interface IListAssetsContext {
    files: IAsset[]
}

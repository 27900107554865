import type { FunctionComponent } from 'react'
import { useEffect } from 'react'
import { PopupWrapper } from './PopupWrapper'
import type { IPopupState } from './types.ts'

interface IPopupOverlayProps {
    popup: IPopupState
    closePopup: (id: string) => void
}

export const PopupOverlay: FunctionComponent<IPopupOverlayProps> = (props) => {
    const { popup, closePopup } = props

    function enableScroll(): void {
        document.body.classList.remove('disable-scroll')
    }

    function disableScroll(): void {
        if (document.documentElement.offsetHeight > window.innerHeight) {
            const scrollTop = window.scrollY ?? document.body.scrollTop
            document.body.classList.add('disable-scroll')
            document.body.style.setProperty('top', -scrollTop + 'px')
        }
    }

    useEffect(() => {
        if (popup) {
            disableScroll()
        } else {
            enableScroll()
        }
    }, [popup])

    return popup ? (
        <>
            <div
                className='fixed top-0 left-0 w-full h-full bg-[#000000]/[.50] z-[500]'
                onClick={() => (popup.dismissible ?? true) && closePopup(popup.id)}
            ></div>
            <PopupWrapper state={popup} />
        </>
    ) : (
        <></>
    )
}

import { truncateString } from '@demia/core'
import { Icon, StyleContext, Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Pane, Skeleton } from '@components/base'
import type { IProjectDetails } from '@lib/project'
import { getNumberOfSensorsOnline } from '@lib/sensor'
import { GoogleMaps } from './GoogleMaps.tsx'
import classes from './ProjectDetails.module.scss'
import { ProjectMenu } from './ProjectMenu'

export const ProjectDetailSkeleton = () => {
    return (
        <Pane>
            <article className='flex flex-row items-center gap-[80px] p-4'>
                <div className='h-full w-[40vw]'>
                    <Skeleton height={280} />
                </div>
                <section>
                    <h3 className='pt-4'>
                        <Skeleton width={256} height={32} />
                    </h3>
                    <div className='flex flex-col pt-6'>
                        <Skeleton width={128} height={24} />
                    </div>
                    <div className='flex flex-col space-y-4 mt-6 mb-12'>
                        <div className='flex flex-row items-center space-x-4'>
                            <Skeleton width={192} height={18} />
                        </div>
                        <div className='flex flex-row items-center space-x-4'>
                            <Skeleton width={192} height={18} />
                        </div>
                        <div className='flex flex-row items-center space-x-4'>
                            <Skeleton width={192} height={18} />
                        </div>
                    </div>
                </section>
            </article>
        </Pane>
    )
}

export const ProjectDetails: FunctionComponent<IProjectDetails> = (projectDetails) => {
    const navigate = useNavigate()
    const isDark = useContext(StyleContext).data.theme === 'dark'
    const sensorsOnline = getNumberOfSensorsOnline(projectDetails)

    function onViewDashboardClick() {
        const url = `/projects/${projectDetails.projectId}`
        navigate(url)
    }

    return (
        <Pane>
            <div className='flex flex-row items-center gap-[80px] p-4'>
                <div style={{ height: '280px', width: '80vw' }}>
                    <GoogleMaps {...{ location: projectDetails.location }} />
                </div>
                <section className='w-full'>
                    <div className='w-full flex flex-row items-center justify-between'>
                        <div className='w-full'>
                            <Text type='header3'>{projectDetails.projectName}</Text>
                        </div>
                        <div className='justify-self-end'>
                            <ProjectMenu site={projectDetails} />
                        </div>
                    </div>
                    <div className='flex flex-col space-y-2 pt-6'>
                        <Text type='header4'>Stream Address</Text>
                        <Text type='body1'>{truncateString(projectDetails.projectAnnouncement, 32)}</Text>
                    </div>
                    <div className='flex flex-col space-y-4 pt-6 pb-12'>
                        <div className='flex flex-row items-center space-x-4'>
                            <div
                                className={`${isDark ? classes.iconFixStrokeDark : ''} w-4 flex flex-col items-center justify-center`}
                            >
                                <Icon name='map-pin' />
                            </div>
                            <Text type='body1'>{projectDetails.location.address}</Text>
                        </div>
                        <div className='flex flex-row items-center space-x-4'>
                            <div
                                className={`${isDark ? classes.iconFixStrokeDark : ''} w-4 flex flex-col items-center justify-center`}
                            >
                                <Icon name='cpu' />
                            </div>
                            <Text type='body1'>{sensorsOnline} Sensors Online</Text>
                        </div>
                        <div className='flex flex-row items-center space-x-4'>
                            <div
                                className={`${isDark ? classes.iconFixFillDark : ''} w-4 flex flex-col items-center justify-center`}
                            >
                                <Icon name='bell' />
                            </div>
                            <Text type='body1'>{projectDetails.notifications.length} Notifications</Text>
                        </div>
                    </div>
                    <Button size='large' onClick={onViewDashboardClick}>
                        View Dashboard
                    </Button>
                </section>
            </div>
        </Pane>
    )
}

import { Icon, StyleContext, Text } from '@demia/ui-kit'
import type { FunctionComponent, MouseEvent } from 'react'
import { useContext, useEffect, useRef, useState } from 'react'
import type { IInputProps } from '@components/base'
import { Popover } from '@components/base/Popover'
import classes from './DropdownInput.module.scss'

interface IDropdownInputProps extends IInputProps {
    items?: IDropdownInputItem[]
    disabled?: boolean
    updateValue?: (value: string, index: number) => void
}

export interface IDropdownInputItem {
    text: string
    active: boolean
    id?: string
    onClick: () => void
}

export const DropdownInput: FunctionComponent<IDropdownInputProps> = (props) => {
    const { items, disabled = false, label, id, required, updateValue } = props
    const isDark = useContext(StyleContext).data.theme === 'dark'
    const [showDropdown, setShowDropdown] = useState(false)
    const [hadOutsideClick, setHadOutsideClick] = useState(false)
    const [activeItemIndex, setActiveItemIndex] = useState(0)

    const inputRef = useRef<HTMLDivElement | null>(null)

    function onDropdownClick(event?: MouseEvent<HTMLDivElement>): void {
        if (event?.target) {
            if (hadOutsideClick) {
                // Do nothing because outside click handler already hid the menu.
            } else {
                setShowDropdown(!showDropdown)
            }
        } else {
            /**
             * NOTE: If we are here, the function is being called from the outside click
             * handler of the popover component.
             */
            setShowDropdown(false)
            setHadOutsideClick(true)

            /**
             * NOTE: We are setting a timeout here to mitigate the fact that this function gets called twice
             * when closing a menu by clicking the div (first from the outside click handler then from the
             * div on click handler).
             */
            setTimeout(() => {
                setHadOutsideClick(false)
            }, 200)
        }
    }

    useEffect(() => {
        items && setActiveItemIndex(items.findIndex((item) => item.active))
    }, [])

    function onDropdownItemClick(index: number): void {
        setActiveItemIndex(index)
        if (items && items[index]) {
            items[index].onClick()
            updateValue && updateValue(items[index].text, index)
        }
    }

    return items && items.length > 0 ? (
        <div className='flex flex-col space-y-2'>
            {label && (
                <label className={`text-text-primary dark:text-text-primary-dark ${classes.label}`} htmlFor={id}>
                    {label}
                    <span className='text-text-danger'>{required ? '*' : ''}</span>
                </label>
            )}
            <div
                ref={inputRef}
                onClick={onDropdownClick}
                className={`${classes.dropdown} ${isDark && classes.dropdownDark} ${disabled ? 'disabled' : ''} flex flex-row items-center justify-between space-x-2 px-3 py-2 bg-surface-0 dark:bg-surface-0-dark hover:bg-surface-1 dark:hover:bg-surface-1-dark/50 border-[1px] border-stroke dark:border-stroke-dark ${disabled ? 'cursor-default' : 'cursor-pointer'}`}
            >
                <Text id={id} type='subtitle1' color='text-primary'>
                    {items[activeItemIndex]?.text ?? ''}
                </Text>
                {!disabled && showDropdown && inputRef ? (
                    <Popover anchor={inputRef.current as HTMLDivElement} event='click' onOutsideClick={onDropdownClick}>
                        {items.map((item, idx) => (
                            <div
                                className='w-full bg-surface-0 dark:bg-surface-0-dark hover:bg-surface-1 dark:hover:bg-surface-1-dark/50 px-4 py-2 text-left whitespace-nowrap cursor-pointer'
                                onClick={() => onDropdownItemClick(idx)}
                            >
                                <Text type='subtitle2' justification='right'>
                                    {item.text}
                                </Text>
                            </div>
                        ))}
                    </Popover>
                ) : (
                    <div></div>
                )}
                <div className={`${showDropdown ? 'rotate-180' : 'rotate-0'} transition-transform duration-300`}>
                    <Icon name='caret-up' />
                </div>
            </div>
        </div>
    ) : (
        <></>
    )
}

import type { IFile } from '@demia/core'
import { ErrorHandler } from '@demia/core'
import { Text } from '@demia/ui-kit'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ProjectDocumentationPopup, ProjectDocumentationTable, ProjectLayout } from '@components/app'
import { Button, Tabs, usePopup } from '@components/base'
import type { IProjectDocument } from '@lib/project'
import { AssetType, ProjectApiService, ProjectDocumentType } from '@lib/project'

const DEFAULT_PROJECT_FILES: Record<ProjectDocumentType, IProjectDocument[]> = {
    [ProjectDocumentType.Project]: [],
    [ProjectDocumentType.Monitoring]: [],
    [ProjectDocumentType.Report]: [],
    [ProjectDocumentType.Certification]: [],
    [ProjectDocumentType.Asset]: [],
}

export const ProjectDocumentationPage = () => {
    const { slug } = useParams()

    const [selectedProjectDocumentType, setSelectedProjectDocumentType] = useState<ProjectDocumentType>(
        ProjectDocumentType.Project
    )
    const [projectDocuments, setProjectDocuments] =
        useState<Record<ProjectDocumentType, IProjectDocument[]>>(DEFAULT_PROJECT_FILES)
    const [loading, setLoading] = useState(true)

    async function loadFiles(): Promise<void> {
        const [fileList, fileListError] = await ProjectApiService.listFiles(slug!)
        if (fileListError) {
            ErrorHandler.handleApiError(fileListError)
        } else {
            createDocumentsFromFiles(fileList.files)
        }

        const [assetList, assetListError] = await ProjectApiService.listAssets(slug!)
        if (assetListError) {
            ErrorHandler.handleApiError(assetListError)
        } else {
            projectDocuments[ProjectDocumentType.Asset] = assetList.files.map((asset) => {
                const file = asset.file
                if (asset.asset.type == AssetType.Link) {
                    file.name = 'Link to ' + asset.asset.name
                }
                file.metadata = {
                    ...file.metadata!,
                    type: asset.asset.type,
                }
                return {
                    file: file,
                }
            })
            setProjectDocuments(projectDocuments)
        }

        setLoading(false)
    }

    function createDocumentsFromFiles(files: IFile[]): void {
        projectDocuments[selectedProjectDocumentType] = files.map((file) => {
            const document = projectDocuments[selectedProjectDocumentType].find(
                (document) => document.file.name === file.name
            )
            if (document) {
                return {
                    ...document,
                    file: {
                        ...file,
                        lastModified: new Date(file.lastModified),
                    },
                }
            } else {
                return {
                    file: {
                        ...file,
                    },
                }
            }
        })
        setProjectDocuments(projectDocuments)
    }

    useEffect(() => {
        void loadFiles()
    }, [])

    async function downloadDocument(document: IProjectDocument): Promise<void> {
        try {
            const { file } = document
            const [data, error] = await ProjectApiService.downloadFile(slug!, file.name)
            if (error) {
                ErrorHandler.handleApiError(error)
            } else {
                const link = window.document.createElement('a')
                link.download = `${file.name}`
                const blob = new Blob([data], { type: file.metadata?.type || 'text/plain' })
                link.href = URL.createObjectURL(blob)
                link.click()
                URL.revokeObjectURL(link.href)
            }
        } catch (err) {
            ErrorHandler.handleApplicationError('Unable to download file')
        }
    }

    async function deleteDocument(document: IProjectDocument) {
        const [_, error] = await ProjectApiService.deleteFile(slug!, document.file.name)
        if (error) {
            ErrorHandler.handleApiError(error)
        } else {
            projectDocuments[selectedProjectDocumentType] = projectDocuments[selectedProjectDocumentType].filter(
                (_document) => _document.file.name !== document.file.name
            )
            setProjectDocuments(projectDocuments)
        }
    }

    const { openPopup } = usePopup()

    function onClosePopup(newDocuments: IProjectDocument[]): void {
        if (newDocuments.length > 0) {
            projectDocuments[selectedProjectDocumentType] = newDocuments.concat(
                projectDocuments[selectedProjectDocumentType]
            )
            setProjectDocuments(projectDocuments)
        }
    }

    function onTabClick(index: number): void {
        setSelectedProjectDocumentType((Object.keys(DEFAULT_PROJECT_FILES) as ProjectDocumentType[])[index])
    }

    const TitleComponent = () => {
        return (
            <>
                <Text type='header2'>Documentation</Text>
                <Button
                    size='medium'
                    onClick={() =>
                        openPopup({
                            content: (
                                <ProjectDocumentationPopup
                                    siteId={slug ?? ''}
                                    onClose={onClosePopup}
                                    uploadType={selectedProjectDocumentType}
                                />
                            ),
                        })
                    }
                    icon='plus'
                >
                    Add New
                </Button>
            </>
        )
    }

    return (
        <>
            <ProjectLayout TitleComponent={TitleComponent}>
                <Tabs
                    tabHeaders={['Project Documents', 'Monitoring Plans', 'Reports', 'Certifications', 'Assets']}
                    onTabClick={onTabClick}
                >
                    <ProjectDocumentationTable
                        loading={loading}
                        documents={projectDocuments[ProjectDocumentType.Project]}
                        downloadFn={downloadDocument}
                        deleteFn={deleteDocument}
                        hideEntity
                    />
                    <ProjectDocumentationTable
                        loading={loading}
                        documents={projectDocuments[ProjectDocumentType.Monitoring]}
                        downloadFn={downloadDocument}
                        deleteFn={deleteDocument}
                    />
                    <ProjectDocumentationTable
                        loading={loading}
                        documents={projectDocuments[ProjectDocumentType.Report]}
                        downloadFn={downloadDocument}
                        deleteFn={deleteDocument}
                        hideEntity
                    />
                    <ProjectDocumentationTable
                        loading={loading}
                        documents={projectDocuments[ProjectDocumentType.Certification]}
                        downloadFn={downloadDocument}
                        deleteFn={deleteDocument}
                        hideEntity
                    />
                    <ProjectDocumentationTable
                        loading={loading}
                        documents={projectDocuments[ProjectDocumentType.Asset]}
                        downloadFn={downloadDocument}
                        deleteFn={deleteDocument}
                        hideEntity
                    />
                </Tabs>
            </ProjectLayout>
        </>
    )
}

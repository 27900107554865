import { Icon, Text } from '@demia/ui-kit'
import type { FunctionComponent } from 'react'

interface IEmptyTableRowProps {
    colSpan?: number
}

export const EmptyTableRow: FunctionComponent<IEmptyTableRowProps> = (props) => {
    const { colSpan } = props
    return (
        <tr className='table-row' key={0}>
            <td key={0} colSpan={colSpan ?? 1} className='table-cell p-0'>
                <div key={1} className='w-full h-[240px] flex flex-col items-center justify-center'>
                    <Icon name='folder-open' color='text-secondary' />
                    <Text type='subtitle2' color='text-secondary'>
                        No Data
                    </Text>
                </div>
            </td>
        </tr>
    )
}
